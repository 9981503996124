import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../_high-five/helpers'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast'

interface Customer {
  id: number
  name: string
  phone: string
  bookings: {
    cancelled: number
    coming: number
    past: number
  }
}

interface ApiResponse {
  success: boolean
  message: string
  data: {
    items: Customer[]
    paginate: {
      total: number
      count: number
      per_page: number
      next_page_url: string
      prev_page_url: string
      current_page: number
      total_pages: number
    }
  }
}

const CustomersWalk: FC = () => {
  const intl = useIntl()
  const [customers, setCustomers] = useState<Customer[]>([])
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(null)
  const [name, setName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const apiUrl = process.env.REACT_APP_API_URL

  const fetchCustomers = async (searchTerm: string = '') => {
    try {
      const response = await axios.get<ApiResponse>(`${apiUrl}/customers/manual`, {
        params: searchTerm ? { search: searchTerm } : {} // Include search param only if searchTerm is not empty
      })
      if (response.data.success) {
        setCustomers(response.data.data.items)
      } else {
        toast.error('Failed to fetch customer data.')
      }
    } catch (error) {
      console.error('Error fetching customer data:', error)
      toast.error('Failed to fetch customer data.')
    }
  }

  useEffect(() => {
    fetchCustomers()
  }, [apiUrl])

  useEffect(() => {
    if (selectedCustomerId !== null) {
      const selectedCustomer = customers.find(customer => customer.id === selectedCustomerId)
      if (selectedCustomer) {
        setName(selectedCustomer.name)
        setPhone(selectedCustomer.phone)
      }
    }
  }, [selectedCustomerId, customers])

  const updateCustomer = async () => {
    if (selectedCustomerId) {
      try {
        const response = await axios.post(`${apiUrl}/customers/manual/${selectedCustomerId}`, {
          name,
          phone,
          phone_country: 'EG',
        })
        if (response.data.success) {
          toast.success('Customer updated successfully!')
          await fetchCustomers(searchTerm) // Refetch data with the current search term
        } else {
          toast.error('Failed to update customer.')
        }
      } catch (error) {
        console.error('Error updating customer:', error)
        toast.error('Failed to update customer.')
      }
    }
  }

  const handleSearch = () => {
    fetchCustomers(searchTerm.trim()) // Trim whitespace and pass searchTerm
  }

  return (
    <>
      <div className='pt-5'>
        <h1>WALK-IN CUSTOMERS</h1>
        <Toaster />
        <Breadcrumb secondItem=' Page' />
        <div className='col-lg-6'>
          <div className='input-group border rounded p-2 bg-white mb-5'>
            <input
              type='search'
              className='form-control bg-transparent border-0'
              placeholder='Search By Name Or Phone...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className='input-group-prepend'>
              <span className='input-group-text bg-transparent border-0 p-0' id='basic-addon1'>
                <button className='btn btn-dark' onClick={handleSearch}>Search</button>
              </span>
            </div>
          </div>
        </div>

        <div className=''>
          <div className='card'>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted bg-dark'>
                      <th className='rounded-start'>#</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>
                        Booking
                        <div className='d-flex justify-content-center gap-5'>
                          <span className='text-white fw-bold d-block mb-1 fs-6'>Coming</span>
                          <span className='text-white fw-bold d-block mb-1 fs-6'>Past</span>
                          <span className='text-white fw-bold d-block mb-1 fs-6'>Cancelled</span>
                        </div>
                      </th>
                      <th className='rounded-end'>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {customers.map((customer) => (
                      <tr key={customer.id}>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {customer.id}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {customer.name}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {customer.phone}
                          </span>
                        </td>
                        <td>
                          <div className='d-flex justify-content-center gap-5'>
                            <span className='fw-bold orang_circle fs-6'>{customer.bookings.coming}</span>
                            <span className='fw-bold orang_circle fs-6'>{customer.bookings.past}</span>
                            <span className='fw-bold orang_circle fs-6'>{customer.bookings.cancelled}</span>
                          </div>
                        </td>
                        <td className='d-flex gap-4 justify-content-center'>
                          <button
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_2'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={() => setSelectedCustomerId(customer.id)}
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>Update customer info</h5>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      x
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='mb-10'>
                      <label className='form-label'>Name:</label>
                      <input
                        type='text'
                        className='form-control'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder='Enter your Name:'
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label'>Phone:</label>
                      <input
                        type='text'
                        className='form-control'
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder='0512345678'
                      />
                    </div>
                  </div>
                  <div className='modal-footer justify-content-between'>
                    <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      Close
                    </button>
                    <button type='button' className='btn btn-dark' onClick={updateCustomer}      data-bs-dismiss='modal'>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { CustomersWalk }
