import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forget-password`
export const VERIFY_ATTRIBUTE = `${API_URL}/auth/verify-attr`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  phone: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    phone: phone,
    password,
    password_confirmation,
  })
}

export function registrationMSchema(password: string, password_confirmation: string) {
  return axios.post(REGISTER_URL, {
    password,
    password_confirmation,
  })
}
// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function requestPasswordByPhone(phone: string, phone_country: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    phone,
    phone_country,
  })
}

export function getUserByToken() {
  let data = localStorage.getItem('user')
  if (data) {
    return JSON.parse(data)
  }
}
