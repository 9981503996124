import {useIntl} from 'react-intl'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import {useParams, useLocation} from 'react-router-dom'
import React, {FC, useState, useEffect} from 'react'
import axios from 'axios'
import toast, {Toaster} from 'react-hot-toast'

import 'react-tooltip/dist/react-tooltip.css'
import {KTIcon, toAbsoluteUrl} from '../../../../_high-five/helpers'

const AssigendEmployee: FC = () => {
  const intl = useIntl()
  const [employees, setEmployees] = useState<any[]>([])
  const [unassignedEmployees, setUnassignedEmployees] = useState<any[]>([])
  const [selectedEmployees, setSelectedEmployees] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [employeeToDelete, setEmployeeToDelete] = useState<number | null>(null)
  const apiUrl = process.env.REACT_APP_API_URL
  const {unit_id} = useParams<{unit_id: string}>()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const unitQueryParam = queryParams.get('unit_id')

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${apiUrl}/units/${unitQueryParam}/employees`)
        setEmployees(response.data.data)
      } catch (error) {
        console.error('Error fetching employees:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchEmployees()
  }, [unit_id])

  const fetchUnassignedEmployees = async () => {
    try {
      const response = await axios.get(`${apiUrl}/units/${unitQueryParam}/unassigned-employees`)
      setUnassignedEmployees(response.data.data)
    } catch (error) {
      console.error('Error fetching unassigned employees:', error)
      toast.error('Failed to fetch unassigned employees.')
    }
  }

  // const handleToggleChange = async (id: number, role: string) => {
  //   setEmployees((prevEmployees) => {
  //     const updatedEmployees = prevEmployees.map((employee) =>
  //       employee.id === id ? {...employee, contact: employee.contact ? 0 : 1} : employee
  //     )

  //     const activeCount = updatedEmployees.filter((employee) => employee.contact === 1).length
  //     if (activeCount === 0) {
  //       toast.error('You must keep at least one user active.')

  //       setTimeout(() => {
  //         setEmployees((prevEmployees) =>
  //           prevEmployees.map((employee) =>
  //             employee.id === id ? {...employee, contact: 1} : employee
  //           )
  //         )
  //       }, 500)
  //     }

  //     // Post the updated contact status to the backend
  //     const updatedEmployee = updatedEmployees.find((employee) => employee.id === id)
  //     if (updatedEmployee) {
  //       axios
  //         .post(`${apiUrl}/units/${unitQueryParam}/update-employee-contact`, {
  //           id: updatedEmployee.id,
  //           role: updatedEmployee.role,
  //           status: updatedEmployee.contact,
  //         })
  //         .then(() => {
  //           toast.success('Employee contact status updated successfully!')
  //         })
  //         .catch((error) => {
  //           console.error('Error updating employee contact status:', error)
  //           toast.error('Failed to update employee contact status.')
  //         })
  //     }

  //     return updatedEmployees
  //   })
  // }
  const handleToggleChange = async (id: number, role: string) => {
    setEmployees((prevEmployees) => {
      const updatedEmployees = prevEmployees.map((employee) =>
        employee.id === id ? {...employee, contact: employee.contact ? 0 : 1} : employee
      );
  
      const activeCount = updatedEmployees.filter((employee) => employee.contact === 1).length;
      
      // If no active users, show a toast and revert the change
      if (activeCount === 0) {
        toast.error('You must keep at least one user active.');
  
        setTimeout(() => {
          setEmployees((prevEmployees) =>
            prevEmployees.map((employee) =>
              employee.id === id ? {...employee, contact: 1} : employee
            )
          );
        }, 500);
  
        return prevEmployees; // Return the original state without updating
      }
  
      // Post the updated contact status to the backend
      const updatedEmployee = updatedEmployees.find((employee) => employee.id === id);
      if (updatedEmployee) {
        axios
          .post(`${apiUrl}/units/${unitQueryParam}/update-employee-contact`, {
            id: updatedEmployee.id,
            role: updatedEmployee.role,
            status: updatedEmployee.contact,
          })
          .then(() => {
            toast.success('Employee contact status updated successfully!');
          })
          .catch((error) => {
            console.error('Error updating employee contact status:', error);
            toast.error('Failed to update employee contact status.');
          });
      }
  
      return updatedEmployees;
    });
  }
  
  const handleSelectEmployee = (id: number) => {
    setSelectedEmployees((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((employeeId) => employeeId !== id)
        : [...prevSelected, id]
    )
  }

  const handleAssignEmployees = async () => {
    if (selectedEmployees.length === 0) {
      toast.error('Please select at least one employee to assign.')
      return
    }

    try {
      await axios.post(`${apiUrl}/units/${unitQueryParam}/assign-employees`, {
        employees: selectedEmployees,
      })

      toast.success('Employees assigned successfully!')

      const response = await axios.get(`${apiUrl}/units/${unitQueryParam}/employees`)
      setEmployees(response.data.data)
      setSelectedEmployees([])
      const modal = document.getElementById('kt_modal_1') as any
      if (modal) {
        modal.classList.remove('show')
        modal.setAttribute('aria-hidden', 'true')
        modal.setAttribute('style', 'display: none')
        document.body.classList.remove('modal-open')
        const modalBackdrop = document.querySelector('.modal-backdrop')
        if (modalBackdrop) {
          modalBackdrop.remove()
        }
      }
    } catch (error) {
      console.error('Error assigning employees:', error)
      toast.error('Failed to assign employees.')
    }
  }

  // const handleDeleteEmployee = async () => {
  //   if (employeeToDelete === null) return

  //   try {
  //     await axios.post(`${apiUrl}/units/${unitQueryParam}/assign-employee`, {
  //       employee_id: employeeToDelete,
  //     })

  //     toast.success('Employee deleted successfully!')

  //     const response = await axios.get(`${apiUrl}/units/${unitQueryParam}/employees`)
  //     setEmployees(response.data.data)
  //     fetchUnassignedEmployees()
  //     const modal = document.getElementById('kt_modal_2') as any
  //     if (modal) {
  //       modal.classList.remove('show')
  //       modal.setAttribute('aria-hidden', 'true')
  //       modal.setAttribute('style', 'display: none')
  //       document.body.classList.remove('modal-open')
  //       const modalBackdrop = document.querySelector('.modal-backdrop')
  //       if (modalBackdrop) {
  //         modalBackdrop.remove()
  //       }
  //     }
  //     setEmployeeToDelete(null)
  //   } catch (error) {
  //     console.error('Error deleting employee:', error)
  //     toast.error('Failed to delete employee.')
  //   }
  // }
  const handleDeleteEmployee = async () => {
    if (employeeToDelete === null) return
  
    try {
      await axios.post(`${apiUrl}/units/${unitQueryParam}/assign-employee`, {
        employee_id: employeeToDelete,
      })
  
      toast.success('Employee deleted successfully!')
  
      // Fetch the updated list of employees
      const response = await axios.get(`${apiUrl}/units/${unitQueryParam}/employees`)
      const employeesData = response.data.data
      
      setEmployees(employeesData)
      fetchUnassignedEmployees()
  
      // Check if all remaining employees have contact status 0
      const allInactive = employeesData.every((employee) => employee.contact === 0)
      
      // If all other employees are inactive, find the owner and set their status to 1
      if (allInactive) {
        const owner = employeesData.find((employee) => employee.role === 'owner')
        if (owner) {
          await axios.post(`${apiUrl}/units/${unitQueryParam}/update-employee-contact`, {
            id: owner.id,
            role: owner.role,
            status: 1,
          })
          toast.success('Owner contact status updated to active.')
  
          // Update the local state to reflect the change
          setEmployees((prevEmployees) =>
            prevEmployees.map((employee) =>
              employee.id === owner.id ? {...employee, contact: 1} : employee
            )
          )
        }
      }
  
      // Hide the delete modal
      const modal = document.getElementById('kt_modal_2') as any
      if (modal) {
        modal.classList.remove('show')
        modal.setAttribute('aria-hidden', 'true')
        modal.setAttribute('style', 'display: none')
        document.body.classList.remove('modal-open')
        const modalBackdrop = document.querySelector('.modal-backdrop')
        if (modalBackdrop) {
          modalBackdrop.remove()
        }
      }
      setEmployeeToDelete(null)
    } catch (error) {
      console.error('Error deleting employee:', error)
      toast.error('Failed to delete employee.')
    }
  }
  
  
  const openDeleteModal = (id: number) => {
    setEmployeeToDelete(id)
    const modal = document.getElementById('kt_modal_2') as any
    if (modal) {
      modal.classList.add('show')
      modal.setAttribute('aria-hidden', 'false')
      modal.setAttribute('style', 'display: block')
      document.body.classList.add('modal-open')
    }
  }

  return (
    <>
      <div className='pt-5'>
        <h1>ASSIGNED EMPLOYEES</h1>
        <Breadcrumb secondItem='Page' />
        <Toaster />
        <div className='card'>
          <div className='card-body py-3'>
            <button
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
              className='btn btn-dark mb-3'
              onClick={fetchUnassignedEmployees}
            >
              Add +
            </button>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted bg-dark'>
                    <th className='rounded-start'>#</th>
                    <th className=''>ID</th>
                    <th className='text-start'>User</th>
                    <th className=''>Coordinator's Contact</th>
                    <th className='rounded-end'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((employee, index) => (
                    <tr key={employee.id}>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {index + 1}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {employee.id}
                        </span>
                      </td>
                      <td className='text-start'>
                        <div className='d-flex gap-3 align-items-center'>
                          <div className=''>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {employee.name} ({employee.role})
                            </span>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                              @{employee.name.toLowerCase()}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className=''>
                        <div className='form-check form-switch form-check-custom form-check-solid justify-content-center'>
                          <input
                            className='form-check-input px-9'
                            type='checkbox'
                            checked={employee.contact === 1}
                            onChange={() => handleToggleChange(employee.id, employee.role)}
                            id={`flexSwitchChecked${employee.id}`}
                          />
                          <label
                            className='form-check-label'
                            htmlFor={`flexSwitchChecked${employee.id}`}
                          >
                            {/* Block */}
                          </label>
                        </div>
                      </td>
                      <td className=''>
                        {employee.role !== 'owner' && (
                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={() => openDeleteModal(employee.id)}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Message</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => setEmployeeToDelete(null)}
                  >
                    x
                  </div>
                </div>
                <div className='modal-body'>
                <KTIcon iconName='trash' className='fs-3' />
                  <p>                  Please confirm that you want to remove this user from the field.
                  </p>
                </div>
                <div className='modal-footer justify-content-between'>
             
                  <button type='button' className='btn btn-dark w-100' onClick={handleDeleteEmployee}>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Assign Employee</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    x
                  </div>
                </div>
                <div className='modal-body'>
                  {unassignedEmployees.length > 0 ? (
                    <div className='list-group'>
                      {unassignedEmployees.map((employee) => (
                        <div
                          key={employee.id}
                          className='border rounded p-3 d-flex justify-content-between align-items-center mb-3'
                        >
                          <div className='d-flex align-items-center gap-3'>
                            <img
                              alt='Logo'
                              src={toAbsoluteUrl('/media/logos/logo.png')}
                              className='h-50px w-50px rounded-circle border'
                            />
                            <h4>{employee.name}</h4>
                          </div>
                          <div className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={selectedEmployees.includes(employee.id)}
                              onChange={() => handleSelectEmployee(employee.id)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No unassigned employees available.</p>
                  )}
                </div>
                <div className='modal-footer justify-content-between'>
                  <button
                    type='button'
                    className='btn btn-dark w-100'
                    onClick={handleAssignEmployees}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {AssigendEmployee}
