import React, { FC, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios'
import {KTIcon} from '../../../../../_high-five/helpers'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../_high-five/helpers'
import toast, { Toaster } from 'react-hot-toast';

interface Policy {
  id: number
  name: string
  units: number
}

const Policies: FC = () => {
  const apiUrl = process.env.REACT_APP_API_URL
  const [policies, setPolicies] = useState<Policy[]>([])  
  const [loading, setLoading] = useState<boolean>(true)
  const [policyIdToDelete, setPolicyIdToDelete] = useState<number | null>(null)


  // Fetch policies from API
  useEffect(() => {
    const fetchPolicies = async () => {
      try {
        const response = await axios.get(`${apiUrl}/policy`)
        const {data} = response.data  

        if (Array.isArray(data)) {
          setPolicies(data)
        } else {
          console.error("Unexpected data format", data)
        }
      } catch (error: unknown) {
        let errorMessage = 'There was an error deleting the event. Please try again.'

        // Check if the error is of the type we expect
        if (error instanceof Error) {
          // If the error is an instance of the generic Error class, we can log its message
          console.error('Error deleting event:', error.message)
        }

        // Use type assertion to narrow down the type of error
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        // Log the error and show an error toast notification
        console.error('Error deleting event:', errorMessage)
        toast.error(errorMessage)
      } finally {
        setLoading(false)
      }
    }

    fetchPolicies()
  }, [apiUrl ])

  // Handle delete request
  const handleDelete = async () => {
    if (policyIdToDelete !== null) {
      try {
        await axios.delete(`${apiUrl}/policy/${policyIdToDelete}`)
        setPolicies(policies.filter(policy => policy.id !== policyIdToDelete)) // Update policies list after deletion
      } catch (error: unknown) {
        let errorMessage = 'There was an error deleting the event. Please try again.'

        // Check if the error is of the type we expect
        if (error instanceof Error) {
          // If the error is an instance of the generic Error class, we can log its message
          console.error('Error deleting event:', error.message)
        }

        // Use type assertion to narrow down the type of error
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        // Log the error and show an error toast notification
        console.error('Error deleting event:', errorMessage)
        toast.error(errorMessage)
      }
    }
  }

  return (
    <>
      <div className='pt-5'>
        <Toaster/>
        <div className='breadcrumb_card mb-10'>
          <h1>Units POLICY</h1>
          <ol className='breadcrumb breadcrumb-dot text-muted fs-6 fw-bold'>
            <li className='breadcrumb-item pe-3'>
              <Link to='#' className='pe-3 d-flex align-items-center'>
                <KTSVG
                  path='media/icons/duotune/general/gen001.svg'
                  className='svg-icon-muted me-2'
                />
                Dashboard
              </Link>
            </li>
            <li className='breadcrumb-item px-3 text-muted'>Policies</li>
          </ol>
        </div>

        <div className='card'>
          <div className='card-header border-0 pt-5 justify-content-end'>
            <Link
              to='/dashboard/management/policies/PoiciesDetails'
              className='btn btn-sm btn-dark h-40px d-flex align-items-center'
            >
              <KTIcon iconName='plus' className='fs-2' />
              New
            </Link>
          </div>

          <div className='card-body py-3'>
            <div className='table-responsive'>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <table className='table align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted bg-dark'>
                      <th className='rounded-start'>ID</th>
                      <th>Name</th>
                      <th>Number of linked units</th>
                      <th className='rounded-end'>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {Array.isArray(policies) && policies.length > 0 ? (
                      policies.map((policy) => (
                        <tr key={policy.id}>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {policy.id}
                            </span>
                          </td>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {policy.name}
                            </span>
                          </td>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {policy.units}
                            </span>
                          </td>
                          <td className='d-flex gap-4 justify-content-center'>
                            <Link
                              to={`/dashboard/management/policies/PoiciesDetailsView?id=${policy.id}`}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <i className='fas fa-eye'></i>
                            </Link>
                            <Link
                              to={`/dashboard/management/policies/PoiciesDetailsEdit?id=${policy.id}`}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                            </Link>

                            <button
                              onClick={() => setPolicyIdToDelete(policy.id)}
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_2'
                              className='btn btn-bg-light me-1'
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                              
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4}>No policies found.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        {/* Delete Confirmation Modal */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Confirmation Message</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
              Please confirm that you want to remove this policy.


              </div>
              <div className='modal-footer justify-content-between'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-dark'
                  onClick={handleDelete}
                  data-bs-dismiss='modal'
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Policies}
