import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { requestPasswordByPhone } from '../core/_requests';
import Select from 'react-select';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const initialValues = {
  phone: '',
  phoneCountry: { value: '', label: '' },
};

const forgotPasswordSchema = Yup.object().shape({
  phone: Yup.string().required('Phone number is required'),
  phoneCountry: Yup.object()
    .shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    })
    .required('Phone country code is required'),
});

export function ForgotPasswordPhone() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();

  // Set a default country (e.g., United States)
  const defaultCountry = { value: 'sa', label: 'Saudi Arabia', flag: '/media/flags/sa.svg' };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      phoneCountry: defaultCountry, // Set the default country initially
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);

      requestPasswordByPhone(values.phone, values.phoneCountry.value)
        .then(({ data: { result } }) => {
          setHasErrors(false);
          setLoading(false);
          const otpUrl = `/auth/forgot-password-otp?phone=${encodeURIComponent(values.phone)}&phone_country=${encodeURIComponent(
            values.phoneCountry.value
          )}`;
          navigate(otpUrl);
        })
   
        .catch((error) => {
          let errorMessage = 'The login detail is incorrect'

          if (typeof error === 'object' && error !== null && 'response' in error) {
            const err = error as {response: {data: {message: string}}}
            errorMessage = err.response.data.message || errorMessage
          }

          toast.error(errorMessage)
          setLoading(false)
          setHasErrors(true) // Set error state if the request fails
          setSubmitting(false);

        })
    },
  });

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://develop.asasware.com/api/provider/general/countries');

        const countryOptions = response.data.data.map((country) => ({
          value: country.iso,
          label: country.name,
          flag: '/' + country.flag,
        }));

        setCountries(countryOptions);
      } catch (error) {
        let errorMessage = 'There was an error fetching the countries. Please try again.';

        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        toast.error(errorMessage)
        setLoading(false)
      }
    
    };

    fetchCountries();
  }, []);

  // Show only the flag in the selected value
  const CustomSingleValue = ({ data }) => (
    <div className='label'>
      {data.flag && <img src={data.flag} alt='flag' className='w-30px h-30px  me-2' />}
    </div>
  );

  // Show flag and label in the dropdown options
  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps} className='react-select-option'>
        {data.flag && <img src={data.flag} alt='flag' className='w-30px h-30px  me-2' />}
        <span>{data.label}</span>
      </div>
    );
  };

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <Toaster />
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Forget my password</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Please enter your Phone Number.</div>
      </div>

      {/* Phone input */}
      <label className='form-label fw-bolder text-gray-900 fs-6'>Phone</label>
      <div className=''>
        <div className='d-flex w-100 input-group border rounded mb-4'>
          <div className=''>
            <Select
              className='react-select-styled'
              classNamePrefix='react-select'
              options={countries}
              value={formik.values.phoneCountry}
              onChange={(option) => formik.setFieldValue('phoneCountry', option)}
              placeholder='Select an option'
              components={{ SingleValue: CustomSingleValue, Option: CustomOption }}
              isSearchable={false}
            />
            {formik.touched.phoneCountry && formik.errors.phoneCountry && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phoneCountry.label}</span>
                </div>
              </div>
            )}
          </div>
          <input
            type='text'
            placeholder='Enter Phone Number'
            autoComplete='off'
            {...formik.getFieldProps('phone')}
            className={clsx(
              'form-control bg-transparent border-0 p-0',
              { 'is-invalid': formik.touched.phone && formik.errors.phone },
              { 'is-valid': formik.touched.phone && !formik.errors.phone }
            )}
          />
        </div>
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container mb-3'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>

      {/* Submit button */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-dark w-100 d-grid'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {loading ? (
            <>
            <div className="d-flex justify-content-center">
              Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </div>
            </>
          ) : (
            'Next'
          )}
        </button>
      </div>

      {/* Separator */}
      <div className='separator separator-content my-14'>
        <p className='text-gray-500 fw-semibold fs-7'>OR</p>
      </div>

      {/* Links */}
      <div className='d-md-flex justify-content-between'>
        <p className='text-gray-500 fw-semibold fs-7'>
          <Link to='/auth/login' className='link-dark'>
            {' '}
            Back to Login
          </Link>
        </p>
        <p className='text-gray-500 fw-semibold fs-7'>
          <Link to='/auth/forgot-password-mail' className='link-dark'>
            {' '}
            Use E-mail
          </Link>
        </p>
      </div>
    </form>
  );
}
