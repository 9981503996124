import React, { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb';
import axios from 'axios';
import { useLocation, useParams, Link } from 'react-router-dom';
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast'; // Import React Hot Toast
import { Modal } from 'bootstrap';  // Import Bootstrap Modal
import { useNavigate } from 'react-router-dom'

const apiUrl = process.env.REACT_APP_API_URL;

interface BookingDetails {
  id: number;
  type: string;
  date: string;
  start_time: string;
  end_time: string;
  gender: string;
  price: {
    total_price: number;
    base_price: number;
    discount: number;
    discount_percent: number;
    tax: number;
    tax_percent: number;
  };
}


type Collection = {
  amount: number;
  date: string;
};
// Define the type for customer options
interface CustomerOption {
  id: string;
  value: string;
  label: string;
  phone: string;
}

const Booking: FC = () => {
  const navigate = useNavigate() // Using useNavigate for programmatic navigation

  const intl = useIntl();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bookingIdQueryParam = queryParams.get('bookingId');
  const unitQueryParam = queryParams.get('unitId');
  const [bookingDetails, setBookingDetails] = useState<BookingDetails | null>(null);
  const [selectedUserType, setSelectedUserType] = useState<string>('walk-in');
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerOption | null>(null);
  const [walkInOptions, setWalkInOptions] = useState<CustomerOption[]>([]);
  const [newUserName, setNewUserName] = useState('');
  const [newUserPhone, setNewUserPhone] = useState('');
  const [collectedAmount, setCollectedAmount] = useState<number>(0);
  const [remainingPrice, setRemainingPrice] = useState<number | undefined>(bookingDetails?.price.total_price);
  const [collections, setCollections] = useState<Collection[]>([]);
  // const [sendSms, setSendSms] = useState<boolean>(false);
  // const [locked, setLocked] = useState<boolean>(false);
  const [sendSMS, setSendSMS] = useState<boolean>(true);
  const [locked, setLocked] = useState<boolean>(false);
  const paidAmount = collections.reduce((total, collection) => total + collection.amount, 0);
  const bookerId = selectedCustomer?.id; // Use optional chaining

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const { data } = await axios.get(
          `${apiUrl}/units/${unitQueryParam}/date-schedule/session_schedule/${bookingIdQueryParam}`
        );
        setBookingDetails(data.data);
        setRemainingPrice(data.data.price.total_price);  // Initialize remainingPrice with the total price

      } catch (error: unknown) {
        let errorMessage = 'There was an error deleting the event. Please try again.';
    
        // Check if the error is of the type we expect
        if (error instanceof Error) {
            // If the error is an instance of the generic Error class, we can log its message
            console.error('Error deleting event:', error.message);
        }
    
        // Use type assertion to narrow down the type of error
        if (typeof error === 'object' && error !== null && 'response' in error) {
            const err = error as { response: { data: { message: string } } };
            errorMessage = err.response.data.message || errorMessage;
        }
    
        // Log the error and show an error toast notification
        console.error('Error deleting event:', errorMessage);
        toast.error(errorMessage);
    }
    };
    const fetchWalkInOptions = async () => {
      try {
        const response = await axios.get(`${apiUrl}/customers/manual`);
        setWalkInOptions(response.data.data.items);
        setWalkInOptions(
          response.data.data.items.map((customer: any) => ({
            value: customer.id,
            label: customer.name,
          })))
      } catch (error) {
        console.error('Error fetching walk-in data:', error);
      }
    };
    fetchWalkInOptions();
    fetchBookingDetails();
  }, [bookingIdQueryParam, unitQueryParam , ]);

  // const fetchHighFiveCustomers = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/customers/high-five`);
  //     const customers = response.data.data.items;

  //     if (customers.length > 0) {
  //       setCustomerOptions(
  //         customers.map((customer: any) => ({
  //           value: customer.id,
  //           label: customer.name,
  //         }))
  //       );
  //     } else {
  //       setCustomerOptions([{ value: '', label: 'No user available' }]);
  //     }
  //   }  catch (error: unknown) {
  //     let errorMessage = 'There was an error deleting the event. Please try again.';
  
  //     // Check if the error is of the type we expect
  //     if (error instanceof Error) {
  //         // If the error is an instance of the generic Error class, we can log its message
  //         console.error('Error deleting event:', error.message);
  //     }
  
  //     // Use type assertion to narrow down the type of error
  //     if (typeof error === 'object' && error !== null && 'response' in error) {
  //         const err = error as { response: { data: { message: string } } };
  //         errorMessage = err.response.data.message || errorMessage;
  //     }
  
  //     // Log the error and show an error toast notification
  //     console.error('Error deleting event:', errorMessage);
  //     toast.error(errorMessage);
  // }
  // };
  const handleUserTypeChange = async (type: string) => {
    setSelectedUserType(type);
    setSelectedCustomer(null); // Clear selected customer
    
    if (type === 'player') {
      fetchHighFiveCustomers();
    } else if (type === 'walk-in') {
      try {
        const response = await axios.get(`${apiUrl}/customers/manual`);
        // Handle the response if needed
        setWalkInOptions(response.data.data.items);
        setWalkInOptions(
          response.data.data.items.map((customer: any) => ({
            value: customer.id,
            label: customer.name,
          })))
      } catch (error: unknown) {
        let errorMessage = 'There was an error processing the request. Please try again.';
  
        if (error instanceof Error) {
          console.error('Error fetching walk-in data:', error.message);
        }
  
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as { response: { data: { message: string } } };
          errorMessage = err.response.data.message || errorMessage;
        }
  
        console.error('Error fetching walk-in data:', errorMessage);
        toast.error(errorMessage);
      }
  
      // setWalkInOptions([{ value: '', label: 'No user available' }]);
    }
  };
  const handleCreateNewUser = async () => {
    if (newUserName && newUserPhone) {
      try {
        const response = await axios.post(`${apiUrl}/customers/manual`, {
          name: newUserName,
          phone: newUserPhone,
          phone_country: 'EG',
        });
  
        const newUser: CustomerOption = {
          id: response.data.data.id,  // Assuming the response contains the new user's ID
          value: response.data.data.id, // You might want to use 'id' as 'value'
          label: newUserName,
          phone: newUserPhone,
        };
  
        // Update the options and automatically select the new user
        setWalkInOptions((prevOptions) => [...prevOptions, newUser]);
        setSelectedCustomer(newUser);
        setNewUserName('');
        setNewUserPhone('');
        toast.success('New user added successfully!');
      } catch (error: unknown) {
        let errorMessage = 'There was an error adding the new user. Please try again.';
  
        if (error instanceof Error) {
          console.error('Error adding new user:', error.message);
        }
  
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as { response: { data: { message: string } } };
          errorMessage = err.response.data.message || errorMessage;
        }
  
        console.error('Error adding new user:', errorMessage);
        toast.error(errorMessage);
      }
    }
  };
  
  const fetchHighFiveCustomers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/customers/high-five`);
      const customers = response.data.data.items;
  
      if (customers.length > 0) {
        setCustomerOptions(
          customers.map((customer: any) => ({
            id: customer.id,
            value: customer.id,
            label: customer.name,
            phone: customer.phone,
          }))
        );
      } else {
        setCustomerOptions([{ id: '', value: '', label: 'No user available', phone: '' }]);
      }
    } catch (error: unknown) {
      let errorMessage = 'There was an error fetching the customer data. Please try again.';
  
      if (error instanceof Error) {
        console.error('Error fetching customers:', error.message);
      }
  
      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as { response: { data: { message: string } } };
        errorMessage = err.response.data.message || errorMessage;
      }
  
      console.error('Error fetching customers:', errorMessage);
      toast.error(errorMessage);
    }
  };
 

  const handleCollect = () => {
    // Ensure bookingDetails and bookingDetails.price.total_price are defined
    const totalBookingPrice = bookingDetails?.price.total_price ?? 0;
  
    if (collectedAmount <= 0 || (remainingPrice !== undefined && collectedAmount > remainingPrice)) {
      toast.error('Please enter a valid amount.'); // Show error toast
      return;
    }
  
    // Calculate the new remaining price
    const newRemainingPrice = (remainingPrice ?? totalBookingPrice) - collectedAmount;
    setRemainingPrice(newRemainingPrice);
  
    // Add the new collection
    const newCollection: Collection = {
      amount: collectedAmount,
      date: new Date().toLocaleDateString(),
    };
    setCollections([...collections, newCollection]);
  
    // Show success toast
    toast.success('Amount collected successfully!');
  
    // Close the modal
    const modalElement = document.getElementById('kt_modal_3');
    if (modalElement) {
      const bootstrapModal = Modal.getInstance(modalElement); // Use the imported Modal
      if (bootstrapModal) {
        bootstrapModal.hide();
      }
    }
  };
  
  const handleDelete = (index: number) => {
    const collectionToRemove = collections[index];
    const newRemainingPrice = (remainingPrice ?? 0) + collectionToRemove.amount;

    setRemainingPrice(newRemainingPrice);
    setCollections(collections.filter((_, i) => i !== index));
  };

const handleBooking = async () => {
  if (!bookingDetails || !selectedCustomer) {
    toast.error('Please select a customer and ensure booking details are loaded.');
    return;
  }
  console.log(selectedCustomer.value);
  const bookerType = selectedUserType; // Use the selectedUserType directly
  let bookerId = selectedCustomer?.value ; // Handle walk-in IDs if needed
  const paidAmount = collections.reduce((total, collection) => total + collection.amount, 0);

  const payload = {
    booker_type: bookerType,
    booker_id: bookerId, // Ensure booker_id is included in the payload
    send_sms: sendSMS ? 1 : 0,
    paid_amount: paidAmount.toString(),
    locked: locked ? 1 : 0,
  };
  console.log(payload);

  try {
    await axios.post(`${apiUrl}/units/${unitQueryParam}/date-schedule/sessions/${bookingIdQueryParam}/booking`, payload);
    toast.success('Booking confirmed successfully!');
    navigate(-1);
  } catch (error: unknown) {
    let errorMessage = 'There was an error confirming the booking. Please try again.';

    if (error instanceof Error) {
      console.error('Error confirming booking:', error.message);
    }

    if (typeof error === 'object' && error !== null && 'response' in error) {
      const err = error as { response: { data: { message: string } } };
      errorMessage = err.response.data.message || errorMessage;
    }

    console.error('Error confirming booking:', errorMessage);
    toast.error(errorMessage);
  }
};

  
  return (
    <>
      <div className='pt-5'>
        <h1>VIEW BOOKING</h1>
        <Toaster />
        <Breadcrumb secondItem=' Page' />
        <div className='row'>
          <div className='col-lg-6'>
            <div className='card p-4 min-h-card'>
              <h4 className=' border-bottom pb-4  pt-2 mb-3 text-lime'>
                Session Information (# {bookingDetails?.id}){' '}
              </h4>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Type :</span> <span>{bookingDetails?.type}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Date :</span> <span> {bookingDetails?.date}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Start :</span> <span>{bookingDetails?.start_time}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>End :</span> <span>{bookingDetails?.end_time}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Gender :</span> <span>{bookingDetails?.gender}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Price :</span>{' '}
                <span>{bookingDetails?.price.total_price} </span>{' '}
                <span>
                  <button className='btn p-0' data-bs-toggle='modal' data-bs-target='#kt_modal_1'>
                    <i className='fa-solid fa-circle-info'></i>
                  </button>
                </span>
              </p>

              {/* modal */}
              <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header py-2'>
                      <h5 className='modal-title'>Break Down</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        x
                      </div>
                    </div>
                    <div className='modal-body mb_no'>
                      <div className=' border-bottom pb-3 mb-3'>
                        <div className='mb-3'>
                          <p className='d-flex gap-3 align-items-center'>
                            <span className='min_w'>Base Price :</span>{' '}
                            <span>{bookingDetails?.price.base_price}</span>
                          </p>
                        </div>
                        <div className='mb-3'>
                          <p className='d-flex gap-3 align-items-center'>
                            <span className='min_w'>
                              Discount ({bookingDetails?.price.discount_percent}%) :
                            </span>{' '}
                            <span>{bookingDetails?.price.discount}</span>
                          </p>
                        </div>
                        <div className='mb-'>
                          <p className='d-flex gap-3 align-items-center'>
                            <span className='min_w'>Total Session Price :</span>{' '}
                            <span>{bookingDetails?.price.base_price}</span>
                          </p>
                        </div>
                      </div>
                      <div className=' border-bottom pb-3 mb-3'>
                        <p className='d-flex gap-3 align-items-center'>
                          <span className='min_w'>
                            Tax ({bookingDetails?.price.tax_percent}%) :
                          </span>{' '}
                          <span>{bookingDetails?.price.tax}</span>
                        </p>
                      </div>
                      <div className=' '>
                        <p className='d-flex gap-3 align-items-center'>
                          <span className='min_w'>Total :</span>{' '}
                          <span>{bookingDetails?.price.total_price}</span>
                        </p>
                      </div>
                    </div>
                    <div className='modal-footer py-2'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='card p-4 min-h-card'>
              <h4 className='border-bottom pb-4 pt-2 mb-3 text-lime'>User Information </h4>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>User :</span>{' '}
                <span className='d-flex gap-5'>
                  <div className=''>
                    <div className='form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='radio'
                        value='walk-in'
                        id='flexRadioChecked0'
                        onChange={() => handleUserTypeChange('walk-in')}
                        checked={selectedUserType === 'walk-in'}

                      />
                                            
                      <label className='form-check-label'>Walk In</label>
                    </div>
                  </div>
                  <div className=''>
                    <div className='form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='radio'
                        value='player'
                        id='flexRadioChecked'
                        checked={selectedUserType === 'player'}
                        onChange={() => handleUserTypeChange('player')}
                      />
                      <label className='form-check-label'>High Five User</label>
                    </div>
                  </div>
                </span>
              </p>
              <p className=''>
                <span className='mb-3 d-block'>Player :</span>{' '}
                <span className='w-100 d-flex gap-5'>
                  <div className='w-75'>
                    <Select
                      className='react-select-styled border h-40px'
                      classNamePrefix='react-select'
                      options={selectedUserType === 'walk-in' ? walkInOptions : customerOptions}
                      value={selectedCustomer} // Pass selectedCustomer as value
                      onChange={(selectedOption) => setSelectedCustomer(selectedOption)} // Update selected customer on change
                      placeholder='Select user'
                    />
                  </div>
                  {selectedUserType === 'walk-in' && (
                    <button
                      className='btn btn-dark w-25 h-40px'
                      data-bs-toggle='modal'
                      data-bs-target='#newUserModal'
                    >
                      New User
                    </button>
                  )}
                </span>
              </p>

              {/* New User Modal */}
              <div className='modal fade' tabIndex={-1} id='newUserModal'>
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header py-2'>
                      <h5 className='modal-title'>New User</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        x
                      </div>
                    </div>
                    <div className='modal-body mb_no'>
                      <div className='mb-3'>
                        <label htmlFor='newUserName'>Name</label>
                        <input
                          id='newUserName'
                          className='form-control'
                          type='text'
                          value={newUserName}
                          onChange={(e) => setNewUserName(e.target.value)}
                        />
                      </div>
                      <div className='mb-3'>
                        <label htmlFor='newUserPhone'>Phone</label>
                        <input
                          id='newUserPhone'
                          className='form-control'
                          type='text'
                          value={newUserPhone}
                          onChange={(e) => setNewUserPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='modal-footer py-2'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        Close
                      </button>
                      <button
                        type='button'
                        className='btn btn-primary'
                        data-bs-dismiss='modal'
                        onClick={handleCreateNewUser}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Send SMS :</span>{' '}
                <span>
                  <div className=''>
                    <div className='form-check form-check-custom form-check-solid'>
                    <input
          className='form-check-input'
          type='checkbox'
          id='send_sms'
          checked={sendSMS}
          onChange={() => setSendSMS(!sendSMS)}
        />
                      <label className='form-check-label'>Send SMS</label>
                    </div>
                  </div>
                </span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Contact :</span>{' '}
                <span>
                  <div className=''>
                    <div className='form-check form-check-custom form-check-solid form-switch'>
                      <span>Unlocked</span>

                
                      <input
          className='form-check-input mx-4 px-8'
          type='checkbox'
          id='locked'
          checked={locked}
          onChange={() => setLocked(!locked)}
          disabled={selectedUserType === 'walk-in'}
        />
                      <span>locked</span>
                    </div>
                  </div>
                </span>
              </p>
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='card p-4 min-h-card mt-5'>
              <h4 className=' border-bottom pb-4  pt-2 mb-3 text-lime d-md-flex justify-content-between'>
                <span>Payment</span>{' '}
                <span>
                  Remaining:{' '}
                  {remainingPrice !== undefined
                    ? remainingPrice
                    : bookingDetails?.price.total_price}
                  <button
                    className='btn btn-dark mx-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_3'
                  >
                    Collect
                  </button>
                </span>
              </h4>
              <div
                className='modal fade'
                id='kt_modal_3'
                tabIndex={-1}
                aria-labelledby='kt_modal_3_Label'
                aria-hidden='true'
              >
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title' id='kt_modal_3_Label'>
                        Collect Amount
                      </h5>
                      <button
                        type='button'
                        className='btn-close'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      ></button>
                    </div>
                    <div className='modal-body'>
                      <input
                        type='number'
                        className='form-control'
                        value={collectedAmount}
                        onChange={(e) => setCollectedAmount(Number(e.target.value))}
                        placeholder='Enter amount to collect'
                      />
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-dark' onClick={handleCollect}>
                        Collect
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Payment Method :</span> <span>Cash</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Payment Status :</span> <span>unpaid</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Cancelation Fee :</span> <span>Subject to agreement</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>collection History</span>
              </p>
              <div className='card bg-transparent shadow-none border-0'>
                <div className='card-body p-3 '>
                  <div className='table-responsive'>
                    <table className='table align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bold text-muted bg-dark'>
                          <th className='rounded-start'>Collected Amount</th>
                          <th>Date of Collection</th>
                          <th className='rounded-end'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {collections.map((collection, index) => (
                          <tr key={index}>
                            <td >
                            <span  className='text-dark fw-bold  d-block mb-1 fs-6'>
                            {collection.amount}
                            </span>

                            </td>
                            <td>
                            <span  className='text-dark fw-bold  d-block mb-1 fs-6'>
                            {collection.date}
                            </span>


                            </td>
                            <td>
                              <button
                                className='btn btn-danger'
                                onClick={() => handleDelete(index)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <hr />
                    <div className='d-flex justify-content-between'>
                      <Link to='/dashboard/fields/Branches' className='btn btn-light'>
                        Cancel
                      </Link>
                      <button className='btn btn-dark' onClick={handleBooking}>Book</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Booking
