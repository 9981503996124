import { PublicHistoryTable } from '../../../../_high-five/partials/widgets/custom_table/PublicHistoryTable'
import { toAbsoluteUrl } from '../../../../_high-five/helpers'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import React, { FC, useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import toast, { Toaster } from 'react-hot-toast' // Import React Hot Toast
import CustomTimePicker from '../../../../_high-five/partials/widgets/calender/CustomTimePicker'
const PublicEventDetails: FC = () => {
  const intl = useIntl()
  const apiUrl = process.env.REACT_APP_API_URL

  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const publicEventIdQueryParam = queryParams.get('publicEventId')
  const [publicEventDetails, setBookingDetails] = useState<any | null>(null)
  const [unitDetails, setUnitDetails] = useState<any | null>(null)
  const [sessionDetails, setSessionDetails] = useState<any | null>(null)
  const [eventDetails, setEventDetails] = useState<any | null>(null)

  const [paymentDetails, setPaymentDetails] = useState<any | null>(null)

  const sessionIdQueryParam = queryParams.get('sessionId')
  const unitQueryParam = queryParams.get('unitId')
  const [startTime, setStartTime] = useState<string>('09:30')
  const [endTime, setEndTime] = useState<string>('')
  const [overnight, setOvernight] = useState<boolean>(false)
  const [modalMode] = useState<string>('edit') // Assuming modalMode is set to 'edit'
  const [price, setPrice] = useState<number>(0)
  const [gender, setGender] = useState<string>('male')
  const editModalRef = useRef<HTMLDivElement>(null)
  const [sessionId, setSessionId] = useState<number>(0)
  const [unitId, setUnitId] = useState<number>(0)
  const [selectedTransactionId, setSelectedTransactionId] = useState<string | null>(null) // State to hold transaction id
  const [selectedPlayers, setSelectedPlayers] = useState<number[]>([]);
  const [selectedPlayerId, setSelectedPlayerId] = useState<number[]>([]);

  useEffect(() => {
    const fetchPublicEventDetails = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/public-event/${publicEventIdQueryParam}`)
        setSessionDetails(data.data.session)
        setBookingDetails(data.data.booking)
        setPaymentDetails(data.data.payment)
        setUnitDetails(data.data.unit)
        setEventDetails(data.data.event)
        setEndTime(data.data.session.end_time)
        setStartTime(data.data.session.start_time)
        setSessionId(data.data.session.id)
        setUnitId(data.data.unit.id)
        setSelectedTransactionId(null) // Reset the transaction ID after success
        setSelectedPlayers(data.data.payment.histories.map(history=>{return history.joined_id}))
        console.log('selectedPlayers', selectedPlayers)
      } catch (error) {
        console.error('Error fetching session data:', error)
      }
    }

    fetchPublicEventDetails()
  }, [publicEventIdQueryParam])
  const fetchPublicEventDetails = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/public-event/${publicEventIdQueryParam}`)
      setSessionDetails(data.data.session)
      setBookingDetails(data.data.booking)
      setPaymentDetails(data.data.payment)
      setUnitDetails(data.data.unit)
      setEventDetails(data.data.event)
      setEndTime(data.data.session.end_time)
      setStartTime(data.data.session.start_time)
      setSessionId(data.data.session.id)
      setUnitId(data.data.unit.id)
      setSelectedTransactionId(null) // Reset the transaction ID after success
      setSelectedPlayers(paymentDetails.histories.map(history=>{return history.joined_id}))

    } catch (error) {
      console.error('Error fetching session data:', error)
    }
  }
  const handleEventUpdate = async () => {
    try {
      console.log('overnight', overnight)

      const response = await axios.post(
        `${apiUrl}/units/${unitId}/date-schedule/session_schedule/${sessionId}`,
        {
          start_time: startTime,
          end_time: endTime,
          overnight: overnight === false ? 0 : 1,
          _method: 'put',
        }
      )

      toast.success('Session updated successfully')
      // Optionally, you can refetch session details or navigate away
      // navigate(-1)
      if (editModalRef.current) {
        const modal = new window.bootstrap.Modal(editModalRef.current)
        modal.hide()
      }
    } catch (error: unknown) {
      let errorMessage = 'There was an error updating the session. Please try again.'

      if (error instanceof Error) {
        console.error('Error updating session:', error.message)
      }

      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as { response: { data: { message: string } } }
        errorMessage = err.response.data.message || errorMessage
      }

      console.error('Error updating session:', errorMessage)
      toast.error(errorMessage)
    }
  }

  const handleCancelBooking = async () => {
    try {
      const response = await axios.post(`${apiUrl}/public-event/${publicEventIdQueryParam}/cancel`)
      toast.success('Public event canceled successfully!')
      navigate(-1)
    } catch (error: unknown) {
      let errorMessage = 'There was an error deleting the event. Please try again.'

      if (error instanceof Error) {
        console.error('Error updating session:', error.message)
      }

      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as { response: { data: { message: string } } }
        errorMessage = err.response.data.message || errorMessage
      }

      console.error('Error updating session:', errorMessage)
      toast.error(errorMessage)
    }
  }
  const removePlayer = async (playerId: number) => {
    try {
      const response = await axios.post(`${apiUrl}/public-event/${publicEventIdQueryParam}/remove/${playerId}`)
      toast.success('player removed successfully!')
      // navigate(-1)
    } catch (error: unknown) {
      let errorMessage = 'There was an error removing the player. Please try again.'

      if (error instanceof Error) {
        console.error('Error updating session:', error.message)
      }

      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as { response: { data: { message: string } } }
        errorMessage = err.response.data.message || errorMessage
      }

      console.error('Error updating session:', errorMessage)
      toast.error(errorMessage)
    }
  }
  const paymentDeletedData = async (playerId: number , transactionId: string) => {
    setSelectedTransactionId(transactionId)
    setSelectedPlayerId([playerId])
  }
  const handleDeletePayment = async () => {
    if (selectedTransactionId) {
      try {
        await axios.post(`${apiUrl}/public-event/${publicEventIdQueryParam}/delete/${selectedPlayerId}/transaction/${selectedTransactionId}`)
        toast.success('Payment deleted successfully!')
        setSelectedTransactionId(null) // Reset the transaction ID after success
        fetchPublicEventDetails();
      } catch (error) {
        toast.error('Failed to delete payment. Please try again.')
        console.error('Error deleting payment:', error)
      }
    }
  }
 
  
  const handleplayerChange = (playerId: number, isChecked: boolean) => {
    setSelectedPlayers((prev) =>
      isChecked ? [...prev, playerId] : prev.filter((id) => id !== playerId)
    );
    console.log(selectedPlayers);
  };
  const handleSavePlayers = async () => {
    try {
      await axios.post(`${apiUrl}/public-event/${publicEventIdQueryParam}/collect`, {
        joined_players: [...selectedPlayers],
      });
      toast.success('Players collected successfully!');
      fetchPublicEventDetails();

      setSelectedPlayers([...selectedPlayers]);
      console.log(selectedPlayers);
    } catch (error) {
      console.error('Error saving collect:', error);
      toast.error('Failed to save collect. Please try again.');
    }
  };
  return (
    <>
      <div className='pt-5'>
        <h1>VIEW PUBLIC EVENT</h1>
        <Toaster />
        <Breadcrumb secondItem=' Page' />
        <div className='row'>
          <div className='col-lg-6'>
            <div className='card p-4 min-h-card'>
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className=' border-bottom pb-4  pt-2 mb-3 text-lime'>
                  Session Information (#{sessionDetails?.id}){' '}
                </h4>
                {eventDetails?.status === 'coming' && (
                  <button
                    className='btn btn-dark p-2 fs-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_20'
                  >
                    <i className='fa-regular fa-pen-to-square'></i> Edit
                  </button>
                )}
              </div>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Type :</span> <span>{sessionDetails?.type}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Date :</span> <span>{sessionDetails?.date}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Start :</span> <span>{sessionDetails?.start_time}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>End :</span> <span>{sessionDetails?.end_time}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Gender :</span> <span>{sessionDetails?.gender}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Price :</span>{' '}
                <span>
                  <i className='fa-solid fa-circle-info px-2'></i>Price of the session is not used
                  in public event.
                </span>
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='card p-4 min-h-card'>
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className=' border-bottom pb-4  pt-2 mb-3 text-lime'>
                  Public Event (#{eventDetails?.id}){' '}
                </h4>
                {eventDetails?.status === 'coming' && (
                  <button
                    className='btn btn-dark p-2 fs-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_21'
                  >
                    cancel booking
                  </button>
                )}
              </div>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Public Event Status :</span>{' '}
                <span>{eventDetails?.status}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Number of player :</span>{' '}
                <span>{eventDetails?.player_numbers}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Age Group :</span> <span> {eventDetails?.age_group}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Created By :</span> <span> {eventDetails?.created_by}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Created At :</span> <span>{eventDetails?.created_at}</span>
              </p>
              {eventDetails?.status === 'cancelled' && (
                <div className="">
                  <p className='d-flex gap-3 align-items-center'>
                    <span className='min_w'>Cancelled By :</span> <span> {eventDetails?.cancelled_by}</span>
                  </p>

                  <p className='d-flex gap-3 align-items-center'>
                    <span className='min_w'>Cancelled At :</span> <span>{eventDetails?.cancelled_at}</span>
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className='col-lg-12'>
            <div className='accordion mt-5' id='kt_accordion_1'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_1'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_1'
                  >
                    <h4 className=' mb-3 text-lime'>
                      <span>
                        Joined Players ({eventDetails?.joined_players.length}/
                        {eventDetails?.player_numbers})
                      </span>
                    </h4>
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_1'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_1'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    <div className='row'>
                      {eventDetails?.joined_players.map((item) => (
                        <div className='col-lg-3'>
                          <div className='d-flex gap-3 align-items-center border p-3 rounded'>
                            <img
                              alt='Logo'
                              src={toAbsoluteUrl(item.avatar)}
                              className='h-50px w-50px rounded-circle border'
                            />
                            <div className=''>
                              <div className='d-flex justify-content-between align-items-center'>
                                <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                  {item.name}
                                </span>
                                <button className='btn p-0' onClick={() => removePlayer(item.id)}>x</button>
                              </div>
                              <span className='text-muted fw-bold text-hover-primary d-block mb-1 fs-8'>
                                @{item.vie_id}
                              </span>
                              <span className='text-muted fw-bold text-hover-primary d-block mb-1 fs-8'>
                                {item.date}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {paymentDetails?.payment_method === 'cash' && (
            <div className=''>
              <div className='col-lg-12'>
                <div className='accordion' id='kt_accordion_1'>
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_2'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_2'
                        aria-expanded='false'
                        aria-controls='kt_accordion_1_body_2'
                      >
                        <h4 className=' mb-3 text-lime'>
                          {/* <span>Left Players (0) </span> */}
                          <span>
                            Left Players ({eventDetails?.left_players.length}/
                            {eventDetails?.player_numbers})
                          </span>
                        </h4>
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_2'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_1_header_2'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body'>
                        <div className='row'>
                          {eventDetails?.left_players.map((item) => (
                            <div className='col-lg-3'>
                              <div className='d-flex gap-3 align-items-center border p-3 rounded'>
                                <img
                                  alt='Logo'
                                  src={toAbsoluteUrl(item.avatar)}
                                  className='h-50px w-50px rounded-circle border'
                                />
                                <div className=''>
                                  <div className='d-flex justify-content-between align-items-center'>
                                    <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                      {item.name}
                                    </span>
                                  </div>
                                  <span className='text-muted fw-bold text-hover-primary d-block mb-1 fs-8'>
                                    @{item.vie_id}
                                  </span>
                                  <span className='text-muted fw-bold text-hover-primary d-block mb-1 fs-8'>
                                    {item.date}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {paymentDetails?.payment_method === 'online' && (
            <div className=''>
              <div className='col-lg-12'>
                <div className='accordion' id='kt_accordion_1'>
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_2'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_2'
                        aria-expanded='false'
                        aria-controls='kt_accordion_1_body_2'
                      >
                        <h4 className=' mb-3 text-lime'>
                          <span>
                            Left Players (Early) ({eventDetails?.left_early_players.length}/
                            {eventDetails?.player_numbers})
                          </span>
                        </h4>
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_2'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_1_header_2'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body'>
                        <div className='row'>
                          {eventDetails?.left_early_players.map((item) => (
                            <div className='col-lg-3'>
                              <div className='d-flex gap-3 align-items-center border p-3 rounded'>
                                <img
                                  alt='Logo'
                                  src={toAbsoluteUrl(item.avatar)}
                                  className='h-50px w-50px rounded-circle border'
                                />
                                <div className=''>
                                  <div className='d-flex justify-content-between align-items-center'>
                                    <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                      {item.name}
                                    </span>
                                  </div>
                                  <span className='text-muted fw-bold text-hover-primary d-block mb-1 fs-8'>
                                    @{item.vie_id}
                                  </span>
                                  <span className='text-muted fw-bold text-hover-primary d-block mb-1 fs-8'>
                                    {item.date}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-12'>
                <div className='accordion' id='kt_accordion_1'>
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_3'
                        aria-expanded='false'
                        aria-controls='kt_accordion_1_body_3'
                      >
                        <h4 className=' mb-3 text-lime'>
                          <span>
                            Left Players (Late) ({eventDetails?.left_late_players.length}/
                            {eventDetails?.player_numbers})
                          </span>
                        </h4>
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_3'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_1_header_3'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body'>
                        <div className='row'>
                          {eventDetails?.left_late_players.map((item) => (
                            <div className='col-lg-3'>
                              <div className='d-flex gap-3 align-items-center border p-3 rounded'>
                                <img
                                  alt='Logo'
                                  src={toAbsoluteUrl(item.avatar)}
                                  className='h-50px w-50px rounded-circle border'
                                />
                                <div className=''>
                                  <div className='d-flex justify-content-between align-items-center'>
                                    <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                      {item.name}
                                    </span>
                                  </div>
                                  <span className='text-muted fw-bold text-hover-primary d-block mb-1 fs-8'>
                                    @{item.vie_id}
                                  </span>
                                  <span className='text-muted fw-bold text-hover-primary d-block mb-1 fs-8'>
                                    {item.date}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className='col-lg-12'>
            <div className='accordion' id='kt_accordion_1'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_4'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_4'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_4'
                  >
                    <h4 className=' mb-3 text-lime'>
                      <span>
                        Removed Players ({eventDetails?.removed_players.length}/
                        {eventDetails?.player_numbers})
                      </span>
                    </h4>
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_4'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_4'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    <div className='row'>
                      {eventDetails?.removed_players.map((item) => (
                        <div className='col-lg-3'>
                          <div className='d-flex gap-3 align-items-center border p-3 rounded'>
                            <img
                              alt='Logo'
                              src={toAbsoluteUrl(item.avatar)}
                              className='h-50px w-50px rounded-circle border'
                            />
                            <div className=''>
                              <div className='d-flex justify-content-between align-items-center'>
                                <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                  {item.name}
                                </span>
                              </div>
                              <span className='text-muted fw-bold text-hover-primary d-block mb-1 fs-8'>
                                @{item.vie_id}
                              </span>
                              <span className='text-muted fw-bold text-hover-primary d-block mb-1 fs-8'>
                                {item.date}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='card p-4 min-h-card mt-5 sum_width'>
              <h4 className=' border-bottom pb-4  pt-2 mb-3 text-lime'>
                <span>Payment</span>
              </h4>
              <div className="d-flex justify-content-between align-items-center">
                <h2 className='my-3'>Details</h2>
                {eventDetails?.status === 'coming' && paymentDetails?.payment_method === 'cash' && (
                  <button
                    className='btn btn-dark p-2 fs-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_55'
                  >
                   collect
                  </button>
                )}

              </div>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Payment Method :</span>{' '}
                <span>{paymentDetails?.payment_method_trans}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Price per user :</span>{' '}
                <span>
                  {paymentDetails?.price_per_user?.total_price} {sessionDetails?.currency}
                  <button className='btn py-0' data-bs-toggle='modal' data-bs-target='#kt_modal_1'>
                    <i className='fa-solid fa-circle-info'></i>
                  </button>
                </span>
              </p>
              { paymentDetails?.payment_method === 'online' && (
              <div className="">
                      <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Charge for leave within :</span>{' '}
                <span> {paymentDetails?.charge_for_leave_hours}</span>
              </p>
              </div>
                )}
       

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Cancelation Fee per user :</span>{' '}
                <span>
                  {' '}
                  {paymentDetails?.cancellation_fees.total_price}


                  { paymentDetails?.payment_method === 'online' && (
              <div className="">
                  {sessionDetails?.currency} (
                    {paymentDetails?.cancellation_fees.cancellation_percent} %)
              </div>
                )}
                 
                </span>
              </p>
              <h2 className='my-3'>Summery</h2>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Total payment from joined players :</span>{' '}
                <span>
                  {paymentDetails?.total_payment_from_joined_player.price_per_user}{' '}
                  {sessionDetails?.currency}
                  <button className='btn py-0' data-bs-toggle='modal' data-bs-target='#kt_modal_2'>
                    <i className='fa-solid fa-circle-info'></i>
                  </button>
                </span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Total fee from Left players :</span>{' '}
                <span>
                  {paymentDetails?.total_fees_from_left_player.price_per_user}{' '}


                  
                  { paymentDetails?.payment_method === 'online' && (
              <div className="">
                {sessionDetails?.currency}
              </div>
                )}
               
                  <button className='btn py-0' data-bs-toggle='modal' data-bs-target='#kt_modal_3'>
                    <i className='fa-solid fa-circle-info'></i>
                  </button>
                </span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Total collected income from public event :</span>{' '}
                <span>
                  {paymentDetails?.total_collected}          {sessionDetails?.currency}
                  <button className='btn py-0' data-bs-toggle='modal' data-bs-target='#kt_modal_4'>
                    <i className='fa-solid fa-circle-info'></i>
                  </button>
                </span>
              </p>

              <h2 className='my-3'>Payment History</h2>

              {/* <PublicHistoryTable className='' /> */}
              <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-dark'>
              <th className='rounded-start'>Player</th>

<th >Type</th>
<th>Amount</th>
<th>Date</th>
<th className='rounded-end'>Action</th>
              </tr>
            </thead>
            <tbody>
              {paymentDetails?.histories?.map((transaction: any) => (
                <tr key={transaction.id}>
                  <td>
                    <span className='text-dark fw-bold d-block mb-1 fs-6'>{transaction.user.name}</span>
                    <span className='text-muted fw-bold d-block mb-1 fs-8'>{transaction.user.email}</span>

                  </td>
                  <td>
                    <span className='text-dark fw-bold d-block mb-1 fs-6'>{transaction.type}</span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold d-block mb-1 fs-6'>{transaction.amount}</span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold d-block mb-1 fs-6'>{transaction.created_at}</span>
                  </td>
                     <td>
                  
                     {paymentDetails?.can_delete_history === true && (
                      <div className="">

                     <button
                       className='btn btn-sm btn-danger'
                       data-bs-toggle='modal'
                       data-bs-target='#kt_modal_40'
                       onClick={() => paymentDeletedData(transaction.joined_id, transaction?.id)} // Set transaction id to be deleted
                     >
                       Delete
                     </button>
                     </div>
                    )}
                   </td>
             
            
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>
            </div>
          </div>
        </div>
        {/* modal 1 */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Price per user Break Down</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Base Price :</span>{' '}
                    <span>{paymentDetails?.price_per_user?.base_price}</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>
                      Discount (%{paymentDetails?.price_per_user?.discount_percent}) :
                    </span>{' '}
                    <span>{paymentDetails?.price_per_user?.discount}</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Public Event Price :</span>{' '}
                    <span>{paymentDetails?.price_per_user?.base_price}</span>
                  </p>
                </div>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>
                      Tax ({paymentDetails?.price_per_user?.tax_percent}%) :
                    </span>{' '}
                    <span>{paymentDetails?.price_per_user?.tax}</span>
                  </p>
                </div>
                <div className=' '>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total :</span>{' '}
                    <span>{paymentDetails?.price_per_user?.total_price}</span>
                  </p>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* modal 2 */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Total Payment from joined players Break Down</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total Number of Players :</span>{' '}
                    <span>
                      {paymentDetails?.total_payment_from_joined_player?.total_joined_players}
                    </span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Price per player :</span>{' '}
                    <span>
                      {paymentDetails?.total_payment_from_joined_player?.price_per_user}{' '}
                      {sessionDetails?.currency}
                    </span>
                  </p>
                </div>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Base Price :</span>{' '}
                    <span>
                      {paymentDetails?.price_per_user?.base_price} {sessionDetails?.currency}
                    </span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>
                      Discount (%{paymentDetails?.price_per_user?.discount_percent}) :
                    </span>{' '}
                    <span>
                      {paymentDetails?.price_per_user?.discount} {sessionDetails?.currency}
                    </span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Public Event Price :</span>{' '}
                    <span>
                      {paymentDetails?.price_per_user?.base_price} {sessionDetails?.currency}
                    </span>
                  </p>
                </div>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>
                      Tax ({paymentDetails?.price_per_user?.tax_percent}%) :
                    </span>{' '}
                    <span>
                      {paymentDetails?.price_per_user?.tax} {sessionDetails?.currency}
                    </span>
                  </p>
                </div>
                <div className=' '>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total :</span>{' '}
                    <span>
                      {paymentDetails?.price_per_user?.total_price} {sessionDetails?.currency}
                    </span>
                  </p>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>

              </div>
            </div>
          </div>
        </div>
        {/* modal 3 */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_3'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Total Fee from Left players Break Down</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total Number of left players with fee :</span>{' '}
                    <span>
                      {' '}
                      {paymentDetails?.total_fees_from_left_player.total_left_late_players}
                    </span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Fee Price per player :</span>{' '}
                    <span>
                      {' '}
                      {paymentDetails?.total_fees_from_left_player.price_per_user}
                      {sessionDetails?.currency}
                    </span>
                  </p>
                </div>

                <div className=' '>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total :</span>
                    <span>
                      {' '}
                      {paymentDetails?.total_fees_from_left_player.price_per_user}
                      {sessionDetails?.currency}
                    </span>
                  </p>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* modal 4*/}
        <div className='modal fade' tabIndex={-1} id='kt_modal_4'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Total income from public event Break Down</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total Number of Players :</span>   <span>
                      {eventDetails?.player_numbers}
                    </span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Price per player :</span>      <span>
                      {paymentDetails?.total_payment_from_joined_player?.price_per_user}{' '}
                      {sessionDetails?.currency}
                    </span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total Number of left players with fee :</span>{' '}
                    <span>
                      {' '}
                      {paymentDetails?.total_fees_from_left_player.total_left_late_players}
                    </span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Fee Price per player :</span> <span>      <span>
                      {' '}
                      {paymentDetails?.total_fees_from_left_player.price_per_user}
                      {sessionDetails?.currency}
                    </span></span>
                  </p>



                </div>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Base Price :</span>{' '}
                    <span>{paymentDetails?.price_per_user?.base_price}</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>
                      Discount (%{paymentDetails?.price_per_user?.discount_percent}) :
                    </span>{' '}
                    <span>{paymentDetails?.price_per_user?.discount}</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Public Event Price :</span>{' '}
                    <span>{paymentDetails?.price_per_user?.base_price}</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>
                      Tax ({paymentDetails?.price_per_user?.tax_percent}%) :
                    </span>{' '}
                    <span>{paymentDetails?.price_per_user?.tax}</span>
                  </p>
                </div>

                <div className=' '>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total :</span>{' '}
                    <span>{paymentDetails?.price_per_user?.total_price}</span>
                  </p>
                </div>

              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">
          Save changes
        </button> */}
              </div>
            </div>
          </div>
        </div>
        {/* modal */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_20' data-bs-backdrop='static'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header py-2'>
                <h5 className='modal-title'>edit</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <div className='row'>
                  <div className='col-12'>
                    <label className='form-label'>Start Time</label>

                    <CustomTimePicker value={startTime} onChange={setStartTime} />
                  </div>
                  <div className='col-12'>
                    <label className='form-label'>End Time</label>

                    <CustomTimePicker value={endTime} onChange={setEndTime} />
                  </div>

                  <small className='text-muted mb-3'>
                    End time must be greater than start time
                  </small>
                  <div className='form-group my-3'>
                    <label className='mb-3'>Overnight</label>
                    <br />
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='overnight'
                        value='1'
                        checked={overnight == true}
                        onChange={() => setOvernight(true)}
                      />
                      <label className='form-check-label'>Yes</label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='overnight'
                        value='0'
                        checked={overnight == false}
                        onChange={() => setOvernight(false)}
                      />
                      <label className='form-check-label'>No</label>
                    </div>
                  </div>
                  <div className='input-group form-control p-0 my-4 align-items-center'>
                    <span className='bg-light btn'>Price</span>
                    <input
                      type='number'
                      className='form-control border-0'
                      value={price}
                      onChange={(e) => setPrice(Number(e.target.value))}
                    />
                    <span className='bg-light btn'>SAR</span>
                  </div>
                  <div className='form-group mb-3'>
                    <label className='mb-3'>Gender</label>
                    <br />
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='gender'
                        value='male'
                        checked={gender === 'male'}
                        onChange={() => setGender('male')}
                      />
                      <label className='form-check-label'>Male</label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='gender'
                        value='female'
                        checked={gender === 'female'}
                        onChange={() => setGender('female')}
                      />
                      <label className='form-check-label'>Female</label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='gender'
                        value='Both'
                        checked={gender == 'Both'}
                        onChange={() => setGender('Both')}
                      />
                      <label className='form-check-label'>Male & Female</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light-primary btn-sm'
                  data-bs-dismiss='modal'
                >
                  {intl.formatMessage({ id: 'cancel' })}
                </button>
                <button
                  type='button'
                  className='btn btn-dark btn-sm'
                  onClick={handleEventUpdate} // Call handleEventUpdate on click
                >
                  update
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Cancel booking modal */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_21'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Message</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <p>Please confirm that you want to remove this Public Event</p>
                <h4>Refund Note</h4>
                <ul>
                  <li>For online Public Event Full refund will be applied.</li>
                  <li>
                    For cash Public Event Payment history will be deleted and refund is subject to
                    agreement.
                  </li>
                </ul>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-dark'
                  data-bs-dismiss='modal'
                  onClick={handleCancelBooking}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
              {/* Delete Payment Modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_40'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Message</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                x
              </div>
            </div>
            <div className='modal-body'>
              Are you sure you want to delete this payment? You cannot undo this action.
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-dark'
                data-bs-dismiss='modal'
                onClick={handleDeletePayment} // Trigger delete payment
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' id='kt_modal_55'>
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title'>Select Players</h5>
                      <button
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        
                      >
                        X
                      </button>
                    </div>
                    <div className='modal-body'>
                      {eventDetails?.joined_players.length > 0 ? (
                        eventDetails?.joined_players.map((player) => (
                          <div key={player.id} className='form-check mb-3'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              id={`player-${player.id}`}
                              checked={selectedPlayers.includes(player.id)}
                              onChange={(e) => handleplayerChange(player.id, e.target.checked)}
                            />
                            <label className='form-check-label' htmlFor={`player-${player.id}`}>
                              {player.name}
                            </label>
                          </div>
                        ))
                      ) : (
                        <p>No Players available.</p>
                      )}
                    </div>
                    <div className='modal-footer'>
                      <button className='btn btn-secondary' data-bs-dismiss='modal'>
                        Close
                      </button>
                      <button className='btn btn-primary' onClick={handleSavePlayers}  data-bs-dismiss='modal'>
                       Collect
                      </button>
                    </div>
                  </div>
                </div>
              </div>
      </div>
    </>
  )
}

export { PublicEventDetails }
