import { FC } from 'react';
import { useIntl } from 'react-intl';
import Calender from '../../../../_high-five/partials/widgets/calender/Calender'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb';
import {SearchDataScad} from '../../../../_high-five/partials/widgets/search/SearchDataScad';

const Schedule: FC = () => {
  const intl = useIntl();

  return (
    <>
      <div className="">
        {/* <h1>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</h1> */}
      <div className="w-75 d-flex justify-content-between align-items-center">
      <h1 className='w-25'>DATE SCHEDULE</h1>
      {/* <SearchDataScad className='w-75'/> */}
      </div>
        <Breadcrumb secondItem=" Page" />

        <div className="">
        <Calender  />

    
        </div>
      </div>
    </>
  );
};

export { Schedule };