import { FC } from 'react';
import { useIntl } from 'react-intl';
import CalenderWeekly from '../../../../_high-five/partials/widgets/week-calender/CalenderWeekly'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb';
import {SearchDataScad} from '../../../../_high-five/partials/widgets/search/SearchDataScad';

const WeeklySchedule: FC = () => {
  const intl = useIntl();

  return (
    <>
      <div className="">
      <div className="w-75 d-flex justify-content-between align-items-center">
      <h1 className='w-25'>WeeklySchedule </h1>
      </div>
        <Breadcrumb secondItem=" Page" />
        <div className="">
        <CalenderWeekly  />

    
        </div>
      </div>
    </>
  );
};

export { WeeklySchedule };