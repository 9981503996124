import {FC, useEffect, useState} from 'react'
import axios from 'axios'
import {useIntl} from 'react-intl'
import Breadcrumb from '../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import LoadingSpinner from '../../../app/shared/LoadingSpinner'
import toast, {Toaster} from 'react-hot-toast'

interface ProfileData {
  bio: string
  message: string

  partner_image: string
}

const CompanyProfile: FC = () => {
  const apiUrl = process.env.REACT_APP_API_URL
  const intl = useIntl()

  const [profileData, setProfileData] = useState<ProfileData | null>(null)

  const [loading, setLoading] = useState<boolean>(true); // Loading state

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setLoading(true); // Start loading
        const response = await axios.get(`${apiUrl}/profile/show`);
        if (response.data.success) {
          setProfileData(response.data.data);
        }
      }  catch (error: unknown) {
        let errorMessage = 'There was an error  Please try again.'

        // Check if the error is of the type we expect
        if (error instanceof Error) {
          // If the error is an instance of the generic Error class, we can log its message
        }

        // Use type assertion to narrow down the type of error
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        // Log the error and show an error toast notification
        toast.error(errorMessage)
      // Ensure loading stops and submission is reset
      setLoading(false)
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchProfileData();
  }, [apiUrl]);

  return (
    <>
    {loading && (
             <LoadingSpinner/>
      )}
 
      <div className='pt-5 company_profile'>
        <div className='row'>
          <div className='col-12'>
            <div className='banner'>
              <img src='/media/auth/bg3-dark.jpg' alt='' className='w-100 img-fluid' />
              <div className='layout'>
                <h1>COMPANY PROFILE </h1>
              </div>
            </div>
            <div className='my-5'>
              <Breadcrumb secondItem=' Page' />
            </div>

            <div className='card p-5 mt-5 profile_box profile_box2'>
              <div className=' d-flex gap-5 align-items-center'>
                <img
                  src={
                    profileData?.partner_image ? profileData.partner_image : '/media/logos/logo.png'
                  }
                  alt='Profile Avatar'
                  className='img-fluid mb-7 border'
                />
                <div className=''>
                  <h4>{profileData?.bio || 'No Bio'}</h4>
                  <p className='text-muted'>{profileData?.message || '-'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {CompanyProfile}
