import { useIntl } from 'react-intl'
import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'

const NewMember: FC = () => {
  const intl = useIntl()
  const apiUrl = process.env.REACT_APP_API_URL
  const [searchQuery, setSearchQuery] = useState('') // State to store search input
  const [isTableVisible, setIsTableVisible] = useState(false) // State to control table visibility
  const [playerData, setPlayerData] = useState<any>(null) // State to store player data from API
  const [loading, setLoading] = useState(false) // State for loading indicator

  const handleSearch = async () => {
    if (searchQuery.trim() === '') {
      toast.error('Please enter a valid search query') // Display error if input is empty
      return
    }

    setLoading(true)
    try {
      // Make POST request to check membership with vie_id
      const response = await axios.post(`${apiUrl}/memberships/check`, {
        vie_id: searchQuery,
      })

      if (response.data.success) {
        setPlayerData(response.data.data.player) // Set player data from API
        setIsTableVisible(true) // Show the table with results
        toast.success('Loaded Successfully')
      } else {
        toast.error(response.data.message || 'No data found')
      }
    } catch (error) {
      toast.error('Error while fetching data. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className='pt-5'>
      <h1> New Membership
      </h1>
      <Breadcrumb secondItem=" Page" />
        <div className='row mb-5'>
          <div className=' col-lg-6 '>
            <div className='card'>
              <div className='input-group border rounded p-2'>
                <input
                  type='search'
                  className='form-control bg-transparent border-0'
                  placeholder='Search By User Or Username or Reference Number'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)} // Update search query on input change
                />
                <div className='input-group-prepend'>
                  <span className='input-group-text bg-transparent border-0 p-0' id='basic-addon1'>
                    <button className='btn btn-dark' onClick={handleSearch} disabled={loading}>
                      {loading ? 'Searching...' : 'Search'}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isTableVisible && playerData && (
          <div className='card'>
            <div className='card-body pb-3'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted bg-dark'>
                      <th className='rounded-start'>ID</th>
                      <th className='text-start'>User</th>
                      <th className=''>Status </th>
                      <th className=''>Actions </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {playerData.id}
                        </span>
                      </td>
                      <td className='text-start'>
                        <div className='d-flex gap-3 align-items-center'>
                          <img
                            alt='Logo'
                            src={playerData.image || '/media/logos/default.png'}
                            className='h-50px w-50px rounded-circle border'
                          />
                          <div>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {playerData.name}
                            </span>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                              @{playerData.vie_id}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {playerData.status_translated}
                        </span>
                      </td>
                      <td className='d-flex gap-4 justify-content-center'>
                 
                        <Link
                         to={`/dashboard/members/CreatMembers?player_id=${playerData.id}`} 

                        
                        className='d-flex align-items-center btn-dark btn'>
                          {/* Create Membership */}
                          {playerData.status_translated === 'Active membership' ? 'Edit Membership' : 'Create Membership'}

                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export { NewMember }
