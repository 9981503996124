import React from 'react';
import './ContextMenuWeekly.css';

interface ContextMenuProps {
  x: number;
  y: number;
  onEdit: () => void;
  onDelete: () => void;
  onClose: () => void;

}

const ContextMenuWeekly: React.FC<ContextMenuProps> = ({ x, y, onEdit, onDelete, onClose}) => {
  return (
    <div className="context-menu" style={{ top: y, left: x }}>
      <button className='btn float-end w-25 p-2' onClick={onClose}><i className="fa-solid fa-xmark"></i></button>
      <button className='btn p-2' onClick={onEdit}>Edit Session</button>
      <button className='btn p-2' onClick={onDelete}>Delete Session</button>

    </div>
  );
};

export default ContextMenuWeekly;
