import React, {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {BranchesTable} from '../../../../_high-five/partials/widgets/custom_table/BranchesTable'
import {fetchBranches} from '../../../services/branchesService'

const Branches: FC = () => {
  const intl = useIntl()
  const [branches, setBranches] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [paginate, setPaginate] = useState<any>(null)

  const loadBranches = async (page = 1) => {
    try {
      setLoading(true)
      const data = await fetchBranches(page)
      setBranches(data.branches)
      setPaginate(data.paginate)
    } catch (err) {
      setError('Failed to load branches')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadBranches()
  }, [])

  const handlePageChange = (page: number) => {
    loadBranches(page)
  }

  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{height: '100vh'}}>
        <div className='spinner'></div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='alert alert-primary d-flex align-items-center p-5 mb-10'>
        <div className='d-flex flex-column'>
          <span>{error}</span>
        </div>
      </div>
    )
  }

  return (
    <div className='pt-5'>
      <h1>Branches</h1>
      <BranchesTable
        className='mt-5'
        branches={branches}
        paginate={paginate}
        onPageChange={handlePageChange}
      />
    </div>
  )
}

export {Branches}
