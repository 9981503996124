import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'

const initialValues = {
  email: 'admin@demo.com',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  
 

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
        Please choose one of the methods
        </div>
        {/* end::Link */}
      </div>




      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>

        <Link to='/auth/forgot-password-mail' className='btn btn-dark w-100 d-grid mb-10'>
        Using Email
        </Link>
        <Link to='/auth/forgot-password-phone' className='btn btn-dark w-100 d-grid'>
        Using Phone

        </Link>
     
      </div>
      <div className='separator separator-content my-14'>
        <p className=' text-gray-500 fw-semibold fs-7'>OR</p>
      </div>
      <p className='text-gray-500 fw-semibold fs-7 text-center'><Link to='/auth/login' className='link-dark '> Back to Login</Link></p>

      {/* end::Separator */}
      {/* end::Form group */}
    </form>
  )
}
