import React, {useState, useEffect} from 'react'
import {DayPilot, DayPilotCalendar} from 'daypilot-pro-react'
import CustomTimePicker from './CustomTimePicker'
import ContextMenu from './ContextMenu' // import ContextMenu
import './Calendar.css'
import toast, {Toaster} from 'react-hot-toast'

import {SearchDataScad} from '../../../../_high-five/partials/widgets/search/SearchDataScad'
import {useParams, useLocation, Link} from 'react-router-dom'
import axios from 'axios'
import LoadingSpinner from '../../../../app/shared/LoadingSpinner'
import {useNavigate} from 'react-router-dom'
import {  useRef } from 'react';
declare global {
  interface Window {
    navigateToDashboard: () => void;
  }
}
interface Event {
  id: string;
  eventId: any;
  text: string;
  start: string;
  end: string;
  startTime: string;
  endTime: string;
  overnight: string;
  price: any;
  gender: string;
  barColor: string;
  backColor: string;
  sessionType: any;
  currency: any;
  booking_type: any;
  booking_id:any;
}

interface Props {
  unitDataGender: 'male' | 'female' | 'Both' | null;
}

const Calendar: React.FC = () => {
  const [events, setEvents] = useState<any[]>([])
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const unitQueryParam = queryParams.get('unit_id')
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [modalMode, setModalMode] = useState('create') // 'create' or 'edit'

  const apiUrl = process.env.REACT_APP_API_URL
  const [startDate, setStartDate] = useState(DayPilot.Date.today())
  const [modalVisible, setModalVisible] = useState(false)
  const [newEventStart, setNewEventStart] = useState<any>(null)
  const [newEventEnd, setNewEventEnd] = useState<any>(null)
  const [startTime, setStartTime] = useState<string>('00:00')
  const [endTime, setEndTime] = useState<string>('00:00')
  const [overnight, setOvernight] = useState('no')
  const [price, setPrice] = useState<number | ''>('')
  const [gender, setGender] = useState('')
  const [sessionType, setSessionType] = useState('Fixed') // State for session type
  const [contextMenuVisible, setContextMenuVisible] = useState(false)
  const [contextMenuPosition, setContextMenuPosition] = useState({x: 0, y: 0})
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null)
  const [bookedEventId, setBookedEventId] = useState<string | null>(null)
  const [publicSessionEventId, setPublicSessionEventId] = useState<string | null>(null)
  const [selectedEventDelet, setSelectedEventDelet] = useState<string | null>(null)
  const [selectedBookingId, setSelectedBookingId] = useState<string | null>(null)
  const [selectedPublicEventId, setSelectedPublicEventId] = useState<string | null>(null)
  const [selectedSessionId, setSelectedSessionId] = useState<string | null>(null)
  const [unitDataGender, setUnitDataGender] = useState<string | null>(null);

  const buttonRef = useRef(null);

  useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true) // Start loading

      console.log(startDate, 'first')
      try {
        const {data} = await axios.get(
          `${apiUrl}/units/${unitQueryParam}/date-schedule/session_schedule?start_date=${startDate}`
        )
        let responsUnitData = axios.get(`${apiUrl}/units/${unitQueryParam}`).then((response) => {
          setUnitDataGender(response?.data?.data?.gender)
          console.log(unitDataGender ,'unitDataGender')
        })
        if (data.data) {
          const sessions = data.data

          const getBarColor = (item: any) => {
            if (item.session_type === 'Single' && !item.booking_type && !item.public_event_id) {
              return 'rgb(109 143 16)'
            } else if (item.session_type === 'Single' && item.booking_type === 'public_event') {
              return '#ad730a'
            } else if (item.session_type === 'Single' && item.booking_type === 'booking') {
              return 'rgb(191 87 87)'
            } else if (item.session_type === 'Fixed' && item.booking_type === 'public_event') {
              return 'orange'
            } else if (item.session_type === 'Fixed' && item.booking_type === 'booking') {
              return 'red'
            }
            return '#A2C837' // Default color
          }

          const getBackColor = (item: any) => {
            if (item.booking_type === 'booking') {
              return 'red'
            } else if (item.booking_type === 'public_event') {
              return 'orange'
            } else if (item.session_type === 'Single') {
              return '#A2C837'
            } else if (item.session_type === 'Fixed') {
              return '#A2C837'
            }
            return '#A2C837' // Default color
          }

          // const mappedEvents = sessions.flatMap((session: any) => {
          //   return session.items.map((item: any) => {
          //     let displayText = item.currency + ' ' + item.price
          //     if (item.booking_type === 'booking' && item.booker_name) {
          //       displayText = item.booker_name
          //     } else if (item.booking_type === 'public_event' && item.event_details) {
          //       displayText = ` ${item.event_details.joined} / ${item.event_details.player_numbers}`
          //     }

          //     return {
          //       id: DayPilot.guid(),
          //       eventId: item.id,
          //       text: displayText,
          //       start: `${item.date}T${item.start_time}`,
          //       end: `${item.date}T${item.end_time}`,
          //       startTime: item.start_time,
          //       endTime: item.end_time,
          //       overnight: item.overnight == 0 ? 'no' : 'yes',
          //       price: item.price,
          //       gender: 'both',
          //       barColor: getBarColor(item), // Set bar color
          //       backColor: getBackColor(item), // Set cell background color
          //       sessionType: item.session_type,
          //       currency: item.currency,
          //     }
          //   })
          // })
          const mappedEvents: Event[] = sessions.flatMap((session: any) => {
            return session.items.flatMap((item: any) => {
              const events: Event[] = [];
              let displayText = `${item.currency} ${item.price}`;
              
              if (item.booking_type === 'booking' && item.booker_name) {
                displayText = item.booker_name;
              } else if (item.booking_type === 'public_event' && item.event_details) {
                displayText = `${item.event_details.joined} / ${item.event_details.player_numbers}`;
              }
          
              const startDate = `${item.date}T${item.start_time}`;
              const endDate = `${item.date}T${item.end_time}`;
              const isOvernight = item.overnight == 1;
          
              if (isOvernight) {
                events.push({
                  id: DayPilot.guid(),
                  eventId: item.id,
                  text: displayText,
                  start: startDate,
                  end: `${item.date}T${"23:59:00"}`,
                  startTime: item.start_time,
                  endTime: "23:59:00",
                  overnight: 'yes',
                  price: item.price,
                  gender: 'both',
                  barColor: getBarColor(item),
                  backColor: getBackColor(item),
                  sessionType: item.session_type,
                  currency: item.currency,
                  booking_type: item.booking_type,
                  booking_id: item.booking_id
                });
          
                const nextDate = new Date(item.date);
                nextDate.setDate(nextDate.getDate() + 1);
                const nextDayString = nextDate.toISOString().split("T")[0];
          
                events.push({
                  id: DayPilot.guid(),
                  eventId: item.id,
                  text: displayText,
                  start: `${nextDayString}T00:00:00`,
                  end: `${nextDayString}T${item.end_time}`,
                  startTime: "00:00:00",
                  endTime: item.end_time,
                  overnight: 'yes',
                  price: item.price,
                  gender: 'both',
                  barColor: getBarColor(item),
                  backColor: getBackColor(item),
                  sessionType: item.session_type,
                  currency: item.currency,
                  booking_type: item.booking_type,
                  booking_id: item.booking_id

                });
              } else {
                events.push({
                  id: DayPilot.guid(),
                  eventId: item.id,
                  text: displayText,
                  start: startDate,
                  end: endDate,
                  startTime: item.start_time,
                  endTime: item.end_time,
                  overnight: 'no',
                  price: item.price,
                  gender: 'both',
                  barColor: getBarColor(item),
                  backColor: getBackColor(item),
                  sessionType: item.session_type,
                  currency: item.currency,
                  booking_type: item.booking_type,
                  booking_id: item.booking_id

                });
              }
          
              return events;
            });
          });
          
          setEvents(mappedEvents) // Set the mapped events
        }
      } catch (error: unknown) {
        let errorMessage = 'There was an error deleting the event. Please try again.'

        // Check if the error is of the type we expect
        if (error instanceof Error) {
          // If the error is an instance of the generic Error class, we can log its message
          console.error('Error deleting event:', error.message)
        }

        // Use type assertion to narrow down the type of error
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        // Log the error and show an error toast notification
        console.error('Error deleting event:', errorMessage)
        toast.error(errorMessage)
      } finally {
        setLoading(false) // Stop loading
      }
    }

    fetchSessions()
  }, [apiUrl, unitQueryParam, startDate])

  const getDayAbbreviation = (dateString) => {
    const date = new Date(dateString)
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return dayNames[date.getDay()]
  }

  const handleEventCreation = async () => {
    if (price === '' || !newEventStart || !newEventEnd) {
      toast.error('Please enter all session details.')
      return
    }
    if (!startTime || !endTime) {
      toast.error('Start and End Time are required')
      return
    }
    if (overnight !== 'yes' ) {
      if (startTime >= endTime) {
        toast.error('Start Time must be before End Time')
        return
      }
    }
    // if (startTime >= endTime) {
    //   toast.error('Start Time must be before End Time')
    //   return
    // }
    if (price < 0) {
      toast.error('Price cannot be negative')
      return
    }

    // Extract the day abbreviation from the date
    const dayAbbreviation = getDayAbbreviation(newEventStart.toString('yyyy-MM-dd'))

    const eventData = {
      day: dayAbbreviation, // Use the manual abbreviation here
      date: newEventStart.toString('yyyy-MM-dd'),
      start_time: startTime,
      end_time: endTime,
      overnight: overnight === 'yes' ? '1' : '0',
      price: price.toString(),
      gender: gender,
    }

    try {
      // Make the POST request to create the event
      await axios.post(
        `${apiUrl}/units/${unitQueryParam}/date-schedule/session_schedule`,
        eventData
      )

      // If successful, show success toast and fetch sessions again
      toast.success('Event created successfully!')

      // Fetch sessions again to update the data, using the current date
      // fetchSessions(startDate)
      // window.location.reload();
    } catch (error: unknown) {
      let errorMessage = 'There was an error deleting the event. Please try again.'

      // Check if the error is of the type we expect
      if (error instanceof Error) {
        // If the error is an instance of the generic Error class, we can log its message
        console.error('Error deleting event:', error.message)
      }

      // Use type assertion to narrow down the type of error
      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as {response: {data: {message: string}}}
        errorMessage = err.response.data.message || errorMessage
      }

      // Log the error and show an error toast notification
      console.error('Error deleting event:', errorMessage)
      toast.error(errorMessage)
    } finally {
      closeModal() // Close the modal after handling the event creation
    }
  }
  const handleEventUpdate = async () => {
    if (price === '' || !startTime || !endTime) {
      toast.error('Please enter all session details.')
      return
    }

    console.log('am here now in update event')

    if (startTime >= endTime) {
      toast.error('Start Time must be before End Time')
      return
    }

    if (price < 0) {
      toast.error('Price cannot be negative')
      return
    }

    const dayAbbreviation = getDayAbbreviation(newEventStart)

    const eventData = {
      day: dayAbbreviation,
      date: newEventStart,
      start_time: startTime,
      end_time: endTime,
      overnight: overnight === 'yes' ? '1' : '0',
      price: price,
      gender: gender,
      _method: 'put', // Include the _method field for PUT requests
    }

    console.log('Selected Event ID:', selectedEventId)

    try {
      // Make the POST request with the selectedEventId for dynamic session ID
      await axios.post(
        `${apiUrl}/units/${unitQueryParam}/date-schedule/session_schedule/${selectedEventId}`, // Use selectedEventId here
        eventData
      )

      // If successful, show success toast and fetch sessions again
      toast.success('Event updated successfully!')
      fetchSessions(startDate)
    } catch (error: unknown) {
      let errorMessage = 'There was an error deleting the event. Please try again.'

      // Check if the error is of the type we expect
      if (error instanceof Error) {
        // If the error is an instance of the generic Error class, we can log its message
        console.error('Error deleting event:', error.message)
      }

      // Use type assertion to narrow down the type of error
      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as {response: {data: {message: string}}}
        errorMessage = err.response.data.message || errorMessage
      }

      // Log the error and show an error toast notification
      console.error('Error deleting event:', errorMessage)
      toast.error(errorMessage)
    } finally {
      closeModal() // Close the modal after handling the event update
    }
  }
  const deleteEvent = (id: string) => {
    setEvents(events.filter((event) => event.id !== id))
  }
  const handleTimeRangeSelected = (args: any) => {
    openModal(args.start, args.end)
  }
  const handleEdit = () => {
    console.log(selectedEventId, 'selectedEventId')
    const event = events.find((event) => event.id === selectedEventId)

    if (event) {
      console.log(event, 'data all data')
      setSelectedEventId(event.eventId)
      // Populate the modal with the selected event's details
      setStartTime(event.startTime)
      setEndTime(event.endTime)
      setPrice(event.price)
      setOvernight(event.overnight ? 'yes' : 'no')
      setGender(event.gender)
      setNewEventStart(event.startDate)
      setNewEventEnd(event.endDate)

      // Set mode to 'edit'
      setModalMode('edit')

      // Open the modal for editing
      setModalVisible(true)
    }
    closeContextMenu()
  }
  const openModal = (start, end) => {
    setNewEventStart(start)
    setNewEventEnd(end)
    setStartTime(start.toString('HH:mm'))
    setEndTime(end.toString('HH:mm'))

    // Set mode to 'create'
    setModalMode('create')

    setModalVisible(true)
  }
  const closeModal = () => {
    setModalVisible(false)
    setNewEventStart(null)
    setNewEventEnd(null)
    setStartTime('00:00')
    setEndTime('00:00')
    setOvernight('no')
    setPrice('')
    setGender('both')
    setSessionType('Fixed') // Reset sessionType to 'Fixed' when closing modal
    // setSelectedEventId(null)

    // This resets the selected event ID, which might be intentional only on modal close
  }

  const handleCellClick = (args: any) => {
    if (args && args.e && args.e.id) {
      // If an event is clicked, set the selectedEventId
      setSelectedEventId(args.e.id())
    }

    if (args && args.cell && args.cell.start) {
      // Open the modal with the cell start and end times
      openModal(args.cell.start, args.cell.end)
    }
  }

  const previousWeek = () => {
    const newStartDate = startDate.addDays(-7)
    setStartDate(newStartDate)
    fetchSessions(newStartDate)
  }

  const nextWeek = () => {
    const newStartDate = startDate.addDays(7)
    setStartDate(newStartDate)
    fetchSessions(newStartDate)
  }

  const onBeforeCellRender = (args: any) => {
    const now = new DayPilot.Date()
    if (args.cell.start < now && args.cell.end > now) {
      // args.cell.html = "<div class='current-time-indicator'></div>"
      // Add a custom HTML element
    } else if (args.cell.start < now) {
      args.cell.disabled = true
      args.cell.html =
        "<div style='color: #999;text-align: center;cursor: not-allowed;height: 100%;background-color: #f8f9fa;'></div>"
    }
  }
  const fetchSessions = async (newStartDate) => {
    setLoading(true) // Start loading

    try {
      const {data} = await axios.get(
        `${apiUrl}/units/${unitQueryParam}/date-schedule/session_schedule?start_date=${newStartDate}`
      )

      if (data.data) {
        const sessions = data.data

        const getBarColor = (item: any) => {
          if (item.session_type === 'Single' && !item.booking_type && !item.public_event_id) {
            return 'rgb(109 143 16)'
          } else if (item.session_type === 'Single' && item.booking_type === 'public_event') {
            return '#ad730a'
          } else if (item.session_type === 'Single' && item.booking_type === 'booking') {
            return 'rgb(191 87 87)'
          } else if (item.session_type === 'Fixed' && item.booking_type === 'public_event') {
            return 'orange'
          } else if (item.session_type === 'Fixed' && item.booking_type === 'booking') {
            return 'red'
          }
          return '#A2C837' // Default color
        }

        const getBackColor = (item: any) => {
          if (item.booking_type === 'booking') {
            return 'red'
          } else if (item.booking_type === 'public_event') {
            return 'orange'
          } else if (item.session_type === 'Single') {
            return '#A2C837'
          } else if (item.session_type === 'Fixed') {
            return '#A2C837'
          }
          return '#A2C837' // Default color
        }

        const mappedEvents = sessions.flatMap((session: any) => {
          return session.items.map((item: any) => {
            let displayText = item.price + ' ' + item.currency
            if (item.booking_type === 'booking' && item.booker_name) {
              displayText = item.booker_name
            } else if (item.booking_type === 'public_event' && item.event_details) {
              displayText = ` ${item.event_details.joined} / ${item.event_details.player_numbers}`
            }

            return {
              id: DayPilot.guid(),
              text: displayText,
              start: `${item.date}T${item.start_time}`,
              end: `${item.date}T${item.end_time}`,
              startTime: item.start_time,
              endTime: item.end_time,
              overnight: item.overnight == 0 ? 'no' : 'yes',
              price: item.price,
              gender: 'both',
              barColor: getBarColor(item), // Set bar color
              backColor: getBackColor(item), // Set cell background color
              sessionType: item.session_type,
              bookingType: item.booking_type,
            }
          })
        })

        setEvents(mappedEvents) // Set the mapped events
      }
    } catch (error: unknown) {
      let errorMessage = 'There was an error deleting the event. Please try again.'

      // Check if the error is of the type we expect
      if (error instanceof Error) {
        // If the error is an instance of the generic Error class, we can log its message
        console.error('Error deleting event:', error.message)
      }

      // Use type assertion to narrow down the type of error
      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as {response: {data: {message: string}}}
        errorMessage = err.response.data.message || errorMessage
      }

      // Log the error and show an error toast notification
      console.error('Error deleting event:', errorMessage)
      toast.error(errorMessage)
    } finally {
      setLoading(false) // Stop loading
    }
  }
  const handleEditDeleteButtonClick = (event: React.MouseEvent, eventId: string) => {
    event.preventDefault()
    setContextMenuVisible(true)
    setContextMenuPosition({x: event.clientX, y: event.clientY})
    setSelectedEventId(eventId)
     event.stopPropagation(); 
  }
  const handleDelete = async () => {
    if (selectedEventDelet) {
      console.log(selectedEventDelet, 'selectedEventDeletselectedEventDeletselectedEventDelet')
      try {
        // Send a DELETE request to the API
        await axios.delete(
          `${apiUrl}/units/${unitQueryParam}/date-schedule/session_schedule/${selectedEventDelet}`
        )

        // If successful, remove the event from the UI
        setEvents(events.filter((event) => event.eventId !== selectedEventId))

        // Show a success toast notification
        toast.success('Event deleted successfully!')
        // Fetch sessions again to update the data, using the current date
        const currentDate = new Date().toISOString().split('T')[0] // Example: '2024-08-27'
        fetchSessions(currentDate)
      } catch (error: unknown) {
        let errorMessage = 'There was an error deleting the event. Please try again.'

        // Check if the error is of the type we expect
        if (error instanceof Error) {
          // If the error is an instance of the generic Error class, we can log its message
          console.error('Error deleting event:', error.message)
        }

        // Use type assertion to narrow down the type of error
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        // Log the error and show an error toast notification
        console.error('Error deleting event:', errorMessage)
        toast.error(errorMessage)
      } finally {
        // Close the context menu
        closeContextMenu()
      }
    }
  }

  // const handleDelete = () => {
  //   if (selectedEventId) {
  //     deleteEvent(selectedEventId)
  //   }
  //   closeContextMenu()
  // }

  const closeContextMenu = () => {
    setContextMenuVisible(false)
    // setSelectedEventId(null)
  }
  const handleCreateBooking = () => {
    if (selectedBookingId) {
      navigate(
        '/dashboard/schedule/Booking?bookingId=' + selectedBookingId + '&unitId=' + unitQueryParam
      )
    }
  }

  const handleCreatePublicEvent = () => {
    if (selectedPublicEventId) {
      navigate(
        '/dashboard/schedule/PublicEvent?publicEventId=' +
          selectedPublicEventId +
          '&unitId=' +
          unitQueryParam
      )
    }
  }

  // const onBeforeEventRender = (args: any) => {
  //   const now = new DayPilot.Date();
  // //onclick="window.location='dashboard/schedule/Session'"
  //   args.data.html = `
  //     <div  style="position: relative;" class="btn">
  //       <button class="edit-delete-btn" style="padding: 0; color: #FFF; font-size: 10px; position: absolute; top: 5px; right: 5px;" data-id="${args.data.id}" data-event-id="${args.data.eventId}" onclick="event.stopPropagation(); ">
  //         <i class="fa-solid fa-chevron-down" style="font-size: 8px; color: #FFF;"></i>
  //       </button>
  //       <span>${args.data.text}</span>
  //     </div>
  //   `;
  
  //   if (args.data.start < now && args.data.end > now) {
  //     args.data.html = "<div class='current-time-indicator'></div>";
  //   } else if (args.data.start < now) {
  //     args.data.disabled = true;
  //     args.data.html = `
  //       <div class="">
  //         <span>${args.data.text}</span>
  //       </div>
  //     `;
  //   }
  // };
  

  const onBeforeEventRender = (args: any) => {
    const now = new DayPilot.Date();

    // Basic HTML structure for event
    args.data.html = `
      <div class="navigate-dashboard" style="    text-decoration: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;" onclick="navigateToDashboard('${args.data.eventId}')">
<button class="btn edit-delete-btn" style="padding: 0; color: #FFF; font-size: 10px; position: absolute; top: 5px; right: 5px;" data-id="${args.data.id}" data-event-id="${args.data.eventId}">
          <i class="fa-solid fa-chevron-down" style="font-size: 8px; color: #FFF;"></i>
        </button>
        <span>${args.data.text}</span>
      </div>
    `;

    // Condition to check if the event is currently active
    if (args.data.start < now && args.data.end > now) {
      args.data.html = "<div class='current-time-indicator'></div>";
    } 
    // Condition to check if the event has ended
    else if (args.data.start < now) {
      args.data.disabled = true;

      // Add an onClick event to navigate to /dashboard
      args.data.html = `
        <div class="navigate-dashboard" style="    text-decoration: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;" onclick="navigateToDashboard()">
          <span>${args.data.text}</span>
        </div>
      `;
    }
  };

  // Function to navigate to the dashboard
  // const navigateToDashboard = (eventId?: string) => {
  //   const event = events.find((event) => event.eventId === eventId)
  //   console.log(eventId)

  //   // navigate(
  //   //   '/dashboard/schedule/Session?sessionId=' + eventId + '&unitId=' + unitQueryParam
  //   // )
  //   if(event?.booking_type === 'booking') {
  //     navigate(
  //       '/dashboard/schedule/Booking?bookingId=' + eventId + '&unitId=' + unitQueryParam
  //     )
  //   }
  // };
  const navigateToDashboard = (eventId?: string) => {
    const event = events.find((event) => event.eventId == eventId);
    console.log(event);
  
    if (!event) {
      console.error("Event not found");
      return;
    }
  
    // If the event's booking type is 'booking', navigate to the Booking URL.
    if (event.booking_type == 'booking') {
      navigate(
        '/dashboard/reservation/BookingDetails?bookingId=' + event.booking_id + '&unitId=' + unitQueryParam
      );
    } else {
      // Navigate to the default Session URL if booking_type is not 'booking'.
      navigate(
        '/dashboard/schedule/Session?sessionId=' + eventId + '&unitId=' + unitQueryParam
      );
    }
  };
  
  // Add the navigateToDashboard function to the window object so it can be called from the inline event handler
  window.navigateToDashboard = navigateToDashboard;
  // const onBeforeEventRender = (args: any) => {
  //   const now = new DayPilot.Date();
  //   args.data.html = `
  //     <div  class="" style="text-decoration: none;">
  //       <button class="btn edit-delete-btn" style="padding: 0; color: #FFF; font-size: 10px; position: absolute; top: 5px; right: 5px;" data-id="${args.data.id}" data-event-id="${args.data.eventId}">
  //         <i class="fa-solid fa-chevron-down" style="font-size: 8px; color: #FFF;"></i>
  //       </button>
  //       <span>${args.data.text}</span>
  //     </div>
  //   `;
  
  //   if (args.data.start < now && args.data.end > now) {
  //     args.data.html = "<div class='current-time-indicator'></div>";
  //   } else if (args.data.start < now) {
  //     args.data.disabled = true;
  //     args.data.html = `
  //         <Link to="/dashboard/" class="" style="text-decoration: none;">
  //         <span>${args.data.text}</span>
  //       </Link>
  //     `;
  //   }
  // }
  const onBeforeHeaderRender = (args: any) => {
    const customDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const customMonthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    const dayIndex = args.header.start.getDayOfWeek() // DayPilot's method to get day of the week
    const monthIndex = args.header.start.getMonth() // DayPilot's method, month is 1-based
    const dayName = customDayNames[dayIndex]
    const monthName = customMonthNames[monthIndex]
    const day = args.header.start.getDay() // DayPilot's method to get day of the month
    const year = args.header.start.getYear() // DayPilot's method to get the year

    const date = `${monthName} ${day} ${year}`

    args.header.html = `
      <div style="text-align: center;">
        <div>${dayName}</div>
        <div style="font-size: smaller;">${date}</div>
      </div>
    `
  }

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      const button = target.closest('.edit-delete-btn')
      if (button) {
        const eventId = button.getAttribute('data-id')
        const eventIdFromBackend = button.getAttribute('data-event-id')
        setSelectedEventId(eventIdFromBackend)
        setSelectedEventDelet(eventIdFromBackend)
        setSelectedBookingId(eventIdFromBackend)
        setSelectedPublicEventId(eventIdFromBackend)
        setSelectedSessionId(eventIdFromBackend)
        if (eventId) {
          handleEditDeleteButtonClick(event as unknown as React.MouseEvent, eventId)
        }
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <div>
      <Toaster />
      <div className='d-flex justify-content-between gap-5 mb-4'>
        <button className='btn btn-dark' onClick={previousWeek}>
          <i className='fa-solid fa-chevron-left'></i>
        </button>
        <div className='w-75'>
          <SearchDataScad className='' />
        </div>

        <button className='btn btn-dark' onClick={nextWeek}>
          <i className='fa-solid fa-chevron-right'></i>
        </button>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        // Render your calendar component here with the events
        <DayPilotCalendar
          startDate={startDate}
          days={7}
          events={events}
          headerDateFormat='dddd MMM d'
          onTimeRangeSelected={handleTimeRangeSelected}
          onEventClick={handleCellClick}
          timeRangeSelectedHandling='Enabled'
          onBeforeCellRender={onBeforeCellRender}
          onBeforeEventRender={onBeforeEventRender}
          onBeforeHeaderRender={onBeforeHeaderRender} // Custom day names
          headerHeight={50}
          cellHeight={50} // Set cell height to 50px
        />
      )}
      <div className=''>
        {contextMenuVisible && (
          <ContextMenu
            x={contextMenuPosition.x}
            y={contextMenuPosition.y}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onClose={closeContextMenu}
            onCreateBooking={handleCreateBooking}
            onCreatePublicEvent={handleCreatePublicEvent}
          />
        )}
      </div>
      {modalVisible && (
        <div
          className={`modal show fade cust_backdrop d-block ${
            modalVisible ? 'opacity-100' : 'opacity-0'
          }`}
          tabIndex={-1}
          role='dialog'
          style={{
            transition: 'opacity 0.3s ease-in-out',
            visibility: modalVisible ? 'visible' : 'hidden',
          }}
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header '>
                <h5 className='modal-title'>
                  {modalMode === 'edit' ? 'Edit Session' : 'Create Session'}
                </h5>
                <button type='button' className='close btn' aria-label='Close' onClick={closeModal}>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='d-flex gap-5'>
                  <div className='form-group mb-3'>
                    <label className='mb-3'>Start At :</label>
                    <CustomTimePicker value={startTime} onChange={setStartTime} />
                  </div>
                  <div className='form-group mb-3'>
                    <label className='mb-3'>End At :</label>
                    <CustomTimePicker value={endTime} onChange={setEndTime} />
                  </div>
                </div>
                <small className='text-muted mb-3'>End time must be greater than start time</small>
                <div className='form-group my-3'>
                  <label className='mb-3'>Overnight</label>
                  <br />
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='overnight'
                      value='yes'
                      checked={overnight === 'yes'}
                      onChange={() => setOvernight('yes')}
                    />
                    <label className='form-check-label'>Yes</label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='overnight'
                      value='no'
                      checked={overnight === 'no'}
                      onChange={() => setOvernight('no')}
                    />
                    <label className='form-check-label'>No</label>
                  </div>
                </div>
                <div className='input-group form-control p-0 my-4 align-items-center'>
                  <span className='bg-light btn'>Price</span>
                  <input
                    type='number'
                    className='form-control border-0'
                    value={price}
                    onChange={(e) => setPrice(Number(e.target.value))}
                  />
                  <span className='bg-light btn'>SAR</span>
                </div>
                <div className='form-group mb-3'>
  <label className='mb-3'>Gender</label>
  <br />
  {unitDataGender === 'Male' || unitDataGender === 'Both' ? (
    <div className='form-check form-check-inline'>
      <input
        className='form-check-input'
        type='radio'
        name='gender'
        value='male'
        checked={gender === 'male'}
        onChange={() => setGender('male')}
      />
      <label className='form-check-label'>Male</label>
    </div>
  ) : null}
  {unitDataGender === 'Female' || unitDataGender === 'Both' ? (
    <div className='form-check form-check-inline'>
      <input
        className='form-check-input'
        type='radio'
        name='gender'
        value='female'
        checked={gender === 'female'}
        onChange={() => setGender('female')}
      />
      <label className='form-check-label'>Female</label>
    </div>
  ) : null}
  {unitDataGender === 'Both' && (
    <div className='form-check form-check-inline'>
      <input
        className='form-check-input'
        type='radio'
        name='gender'
        value='both'
        checked={gender === 'both'}
        onChange={() => setGender('both')}
      />
      <label className='form-check-label'>Male & Female</label>
    </div>
  )}
</div>

                {/* <div className='form-group mb-3'>
                  <label className='mb-3'>Gender</label>
                  <br />
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='gender'
                      value='male'
                      checked={gender === 'male'}
                      onChange={() => setGender('male')}
                    />
                    <label className='form-check-label'>Male</label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='gender'
                      value='female'
                      checked={gender === 'female'}
                      onChange={() => setGender('female')}
                    />
                    <label className='form-check-label'>Female</label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='gender'
                      value='both'
                      checked={gender === 'both'}
                      onChange={() => setGender('both')}
                    />
                    <label className='form-check-label'>Male & Female</label>
                  </div>
                </div> */}
              </div>
              <div className='modal-footer justify-content-between'>
                <button type='button' className='btn btn-secondary' onClick={closeModal}>
                  Close
                </button>
                {modalMode === 'edit' ? (
                  <button type='button' className='btn btn-dark' onClick={handleEventUpdate}>
                    Update Event
                  </button>
                ) : (
                  <button type='button' className='btn btn-dark' onClick={handleEventCreation}>
                    Create Event
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Calendar
