import { FC } from 'react';
import { useIntl } from 'react-intl';
// import {AccordionField} from './AccordionField';
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb';

const FieldDetails: FC = () => {
  const intl = useIntl();

  return (
    <>
      <div className="pt-5">
        <h1>VIEW FIELD DETAILS</h1>
        <Breadcrumb secondItem=" Page" />
        <div className="card p-5 ">
        <div className="d-flex justify-content-between  align-items-center mb-5 border-bottom pb-5">
          <h4>Field Status: <span className='text-muted ms-5'> Available</span></h4>
          <button   data-bs-toggle="modal"
  data-bs-target="#kt_modal_1" className='btn btn-dark'>
          Update Status
          </button>
          <div className="modal fade" tabIndex={-1} id="kt_modal_1">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Update Status</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
     x
        </div>
      </div>
      <div className="modal-body">
        <p>Field Status :</p>


<div className=" d-flex gap-10">
<div className="form-check form-check-custom form-check-solid">
      <input className="form-check-input" type="radio" value="" id="flexRadioDefault" name='test'/>
      <label className="form-check-label" >
      Maintains
      </label>
  </div>
  <div className="form-check form-check-custom form-check-solid">
      <input className="form-check-input" type="radio" value="" id="flexRadioDefault" name='test'/>
      <label className="form-check-label" >
      Available
      </label>
  </div>
</div>
      </div>
      <div className="modal-footer justify-content-between">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        <button type="button" className="btn btn-dark">
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>
        </div>
        <div className="row">
          <div className="col-12 mb-5">
            <h3>General Information</h3>
          </div>
        <div className="col-lg-6">
        <p className='d-flex gap-3  align-items-start'><span className='min_w'>Field Name :
        </span> <span> Egypt</span></p>
        <p className='d-flex gap-3  align-items-start'><span className='min_w'>Description :</span> <span> Description</span></p>
        <p className='d-flex gap-3  align-items-start'><span className='min_w'>Gender :
        </span> <span> Both
        </span></p>
        <p className='d-flex gap-3  align-items-start'><span className='min_w'>Sport type:</span> <span> Padal</span></p>
        <p className='d-flex gap-3  align-items-start'><span className='min_w'>Type:
        </span> <span> sssswtg</span></p>
        <p className='d-flex gap-3  align-items-start'><span className='min_w'>Field Size :
        </span> <span> 150 - 150
        </span></p>
        <p className='d-flex gap-3  align-items-start'><span className='min_w'>Capacity:
        </span> <span> 11x11
        </span></p>
        <p className='d-flex gap-3  align-items-start'><span className='min_w'>Starting From:
        </span> <span> 100 SAR
        </span></p>

          </div>
          <div className="col-lg-6">
          <img src="/media/auth/bg5.jpg" alt="" className='w-100 h-75 img-fluid accord_img' /> 

          </div>
        <div className="col-12 border-top mb-5 pt-5">
        <h3>Location Information :</h3>

        </div>
        <div className="col-lg-6">
        <p className='d-flex gap-3  align-items-start'><span className='min_w'> Type :</span> <span> Main</span></p>
        <p className='d-flex gap-3  align-items-start'><span className='min_w'> Address  :</span> <span> 
        Egypt</span></p>
        <p className='d-flex gap-3  align-items-start'><span className='min_w'> Conflicted With :</span> <span> Aquila Mays</span></p>
        <div className=" border-top my-5 pt-5">
        <h3 className='my-5'>Services Information :</h3>
        <p className='d-flex gap-3  align-items-start'><span className='min_w'> Services :</span> <span> Parking, and Shirts
        </span></p>

        </div>
        <div className=" border-top my-5 pt-5">
        <p className='d-flex gap-3  align-items-start'><i className="fa-solid fa-circle-info"></i> Note: Incase of wrong data, please contact High Five support</p>

        </div>
        </div>
        <div className="col-lg-6">
        <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=..."
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen={true}
        loading="lazy"
      ></iframe>
        </div>
        </div>
        </div>
     {/*  */}
      </div>
    </>
  );
};

export { FieldDetails };