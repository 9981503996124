import React, { FC, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { KTIcon } from '../../../../_high-five/helpers';

const Comments: FC = () => {
  const [comments, setComments] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { unit_id } = useParams<{ unit_id: string }>();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const unitQueryParam = queryParams.get('unit_id');

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`${apiUrl}/units/${unitQueryParam}/comments`);
        setComments(response.data.data.items);
      } catch (error) {
        console.error('Error fetching comments:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchComments();
  }, [unit_id]);

  const handleDelete = async (commentId: string, reason: string) => {
    try {
      await axios.post(`${apiUrl}/units/${unitQueryParam}/comments`, {
        comment_id: commentId,
        deleted_reason: reason
      });
      setComments(comments.filter(comment => comment.id !== commentId));
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  return (
    <div className="pt-5">
      <h1>COMMENTS</h1>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '75vh' }}>
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="card">
          <div className="card-body py-3">
            <div className="table-responsive">
              <table className="table align-middle gs-0 gy-4">
                <thead>
                  <tr className="fw-bold text-muted bg-dark">
                    <th className="rounded-start">ID</th>
                    <th>User</th>
                    <th>Rating</th>
                    <th>Comment</th>
                    <th>Created Date</th>
                    <th className="rounded-end">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {comments.map((comment) => (
                    <tr key={comment.id}>
                      <td>{comment.id}</td>
                      <td>{comment.player.name}</td>
                      <td>{comment.rate}</td>
                      <td>{comment.description}</td>
                      <td>{comment.date}</td>
                      <td className="d-flex gap-4 justify-content-center">
                        {/* <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                          <KTIcon iconName="pencil" className="fs-3" />
                        </button> */}
                        <button
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_1"
                          data-comment-id={comment.id}
                        >
                          <KTIcon iconName="trash" className="fs-3" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Delete Confirmation */}
      <div className="modal fade" id="kt_modal_1" tabIndex={-1}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this comment?</p>
              <input
                type="text"
                id="delete-reason"
                className="form-control"
                placeholder="Enter reason for deletion"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  const reason = (document.getElementById('delete-reason') as HTMLInputElement).value;
                  const commentId = (document.querySelector('[data-comment-id]') as HTMLElement)?.getAttribute('data-comment-id');
                  if (commentId && reason) {
                    handleDelete(commentId, reason);
                  }
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Comments };
