import React, {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import {useParams, useLocation} from 'react-router-dom'
import axios from 'axios'
import Flatpickr from 'react-flatpickr'
import toast, {Toaster} from 'react-hot-toast'

import 'flatpickr/dist/flatpickr.css'
import 'react-tooltip/dist/react-tooltip.css'
import {KTIcon} from '../../../../_high-five/helpers'
import {Link} from 'react-router-dom'

interface Unit {
  name: string
  type: string
}

const SessionMangement: FC = () => {
  const intl = useIntl()
  const [sessions, setSessions] = useState<any[]>([])
  const [unit, setUnit] = useState<Unit | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [newSession, setNewSession] = useState({
    name: '',
    start_date: new Date(),
    end_date: new Date(),
  })
  const [sessionToDelete, setSessionToDelete] = useState<any | null>(null) // Store the session to delete
  const apiUrl = process.env.REACT_APP_API_URL
  const {unit_id} = useParams<{unit_id: string}>()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const unitQueryParam = queryParams.get('unit_id')
  const [sessionToUpdate, setSessionToUpdate] = useState<any | null>(null)

  const fetchSessions = async () => {
    try {
      const response = await axios.get(`${apiUrl}/units/${unitQueryParam}/schedules`)
      setSessions(response.data.data || [])
    } catch (error) {
      console.error('Error fetching sessions:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchUnit = async () => {
    try {
      const response = await axios.get(`${apiUrl}/units/${unitQueryParam}`)
      setUnit(response.data.data)
    } catch (error) {
      console.error('Error fetching unit:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUnit()
    fetchSessions()
  }, [unitQueryParam])

  const handleCreateSession = async () => {
    try {
      const response = await axios.post(`${apiUrl}/units/${unitQueryParam}/schedules`, newSession)
      fetchSessions()
      toast.success('Session created successfully!')
    } catch (error) {
      console.error('Error creating session:', error)
      toast.error('Failed to create session!')
    }
  }

  const handleDeleteSession = async () => {
    if (sessionToDelete) {
      try {
        await axios.delete(`${apiUrl}/units/${unitQueryParam}/schedules/${sessionToDelete.id}`)
        fetchSessions() // Refresh the sessions list
        toast.success('Session deleted successfully!')
      } catch (error) {
        console.error('Error deleting session:', error)
        toast.error('Failed to delete session!')
      }
    }
  }

  const handleUpdateSession = async () => {
    if (!sessionToUpdate) return
  
    const updateData = {
      name: sessionToUpdate.name,
      _method: 'put',
    }
  
    try {
      await axios.post(`${apiUrl}/units/${unitQueryParam}/schedules/${sessionToUpdate.id}`, updateData)
      fetchSessions()
      toast.success('Session updated successfully!')
      setSessionToUpdate(null) // Clear sessionToUpdate after updating
      const closeModalButton = document.querySelector('#kt_modal_1 .btn[data-bs-dismiss="modal"]')
      if (closeModalButton) {
        (closeModalButton as HTMLElement).click() // Simulate click to close modal
      }
    } catch (error) {
      console.error('Error updating session:', error)
      toast.error('Failed to update session!')
    }
  }
  
  
  return (
    <>
      <div className='pt-5'>
        <h1>SESSIONS MANAGEMENT</h1>
        <Toaster />
        <Breadcrumb secondItem='Page' />
        <div className='card p-3'>
          <div className='d-flex justify-content-end my-5'>
            <button className='btn btn-dark' data-bs-toggle='modal' data-bs-target='#kt_modal_3'>
              <i className='fa-solid fa-plus'></i> Create Temporary Schedule
            </button>
          </div>
          {unit && (
            <h4 className='d-flex justify-content-between w-100 align-items-center p-3 shadow-sm'>
              <span>
                <img src='/ball.png' alt='' className='w-40px h-40px me-2 object-fit-contain' />{' '}
                {unit.name}
              </span>

              <span className='d-flex justify-content-center align-items-center gap-5 text-muted'>
                {unit.type}
                <span
                  id='button-tooltip'
                  data-tooltip-content='Activated'
                  className='badge_custom badge_custom-active me-2'
                ></span>
              </span>
            </h4>
          )}
        </div>
        <div className='card'>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted bg-dark'>
                    <th className='rounded-start'>ID</th>
                    <th className=''>Name</th>
                    <th className=''>Period</th>
                    <th className=''>Status</th>
                    <th className='rounded-end'>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {!isLoading && sessions.length > 0 ? (
                    sessions
                      .filter((session) => session !== null)
                      .map((session) => (
                        <tr key={session.id}>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {session.id}
                            </span>
                          </td>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {session.name}
                            </span>
                          </td>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {session.period}
                            </span>
                          </td>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {session.status}
                            </span>
                          </td>

                          <td className='d-flex gap-4 justify-content-center'>
                            <Link
                       
                              to={`/dashboard/schedule/WeeklySchedule?unit_id=${unitQueryParam}&session_id=${session.id}&type=${session.period}`}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <i className='fa-regular fa-calendar-days'></i>
                            </Link>

                            <button
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_1'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={() => setSessionToUpdate(session)}
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                            </button>
                            <button
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_2'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              onClick={() => setSessionToDelete(session)} // Set the session to delete when this button is clicked
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </button>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={5} className='text-center'>
                        {isLoading ? 'Loading...' : 'No sessions available'}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Create Modal */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_3'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Create Temporary Session</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>

              <div className='modal-body'>
                <div className='mb-10'>
                  <label className='form-label'>Name:</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Schedule Name:'
                    value={newSession.name}
                    onChange={(e) => setNewSession({...newSession, name: e.target.value})}
                  />
                </div>
                <div className='mb-10'>
                  <label className='form-label'>Start Date:</label>
                  <Flatpickr
                    value={newSession.start_date}
                    onChange={([start_date]) => setNewSession({...newSession, start_date})}
                    className='form-control'
                    placeholder='Pick start date'
                  />
                </div>
                <div className='mb-10'>
                  <label className='form-label'>End Date:</label>
                  <Flatpickr
                    value={newSession.end_date}
                    onChange={([end_date]) => setNewSession({...newSession, end_date})}
                    className='form-control'
                    placeholder='Pick end date'
                  />
                </div>
              </div>
              <div className='modal-footer d-flex justify-content-between'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-dark'
                  onClick={handleCreateSession}
                  data-bs-dismiss='modal'
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Delete Modal */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Delete Session</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <p>Are you sure you want to delete this session?</p>
              </div>
              <div className='modal-footer d-flex justify-content-between'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Cancel
                </button>
                <button
                  type='button'
                  className='btn btn-dark'
                  onClick={handleDeleteSession}
                  data-bs-dismiss='modal'
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
                      {/* start modal */}
                      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Edit Temporary Schedule</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
      x
        </div>
      </div>

      <div className="modal-body">
      <div className="mb-10">
  <label className="form-label">Schedule name :</label>


  <input
  type='text'
  className='form-control'
  placeholder='Enter Schedule Name:'
  value={sessionToUpdate?.name || ''} // Bind to sessionToUpdate.name
  onChange={(e) => setSessionToUpdate({ ...sessionToUpdate, name: e.target.value })}
/>

  </div>

      </div>
      <div className="modal-footer d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        <button type="button" className="btn btn-dark"                   onClick={handleUpdateSession}
        >
         Create
        </button>
      </div>
    </div>
  </div>
</div>
      </div>
    </>
  )
}

export {SessionMangement}
