import React from 'react';
// import './LoadingSpinner.css'; // Optional: For custom spinner styles

const LoadingSpinner: React.FC = () => {
  return (
    <div className='d-flex justify-content-center align-items-center' style={{height: '100vh'}}>
      <div className='spinner'></div>
    </div>
  );
};

export default LoadingSpinner;
