import { FC } from 'react';
import { useIntl } from 'react-intl';
import { ChartsWidget3 } from '../../../_high-five/partials/widgets/custom_charts/ChartsWidget3';
import { ChartsWidget1 } from '../../../_high-five/partials/widgets/custom_charts/ChartsWidget1';

const DashboardWrapper: FC = () => {
  const intl = useIntl();

  return (
    <>
      <div className="container-fluid">
        <h1>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</h1>

        {/* <div className="row  mt-4">
          <div className="col-lg-6">
            <ChartsWidget3 className="card card-xl-stretch mb-5 " />
          </div>
          <div className="col-lg-6">
            <ChartsWidget1 className="card card-xl-stretch mb-5 " />
          </div>
        </div> */}
      </div>
    </>
  );
};

export { DashboardWrapper };