import React, { useState } from 'react';
import { KTIcon } from '../../../../_high-five/helpers';

type Props = {
  className: string;
};

const branches = [
  { id: 1, name: 'Branch One' },
  { id: 2, name: 'Branch Two' },
  { id: 3, name: 'Branch Three' },
];

const units = [
  { id: 1, name: 'Unit 1-1' },
  { id: 2, name: 'Unit 1-2' },
  { id: 3, name: 'Unit 2-1' },
  { id: 4, name: 'Unit 2-2' },
  { id: 5, name: 'Unit 3-1' },
  { id: 6, name: 'Unit 3-2' },
];

const SearchDataScad: React.FC<Props> = ({ className }) => {
  const [selectedBranch, setSelectedBranch] = useState<number | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<number | null>(null);

  const handleBranchChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const branchId = parseInt(event.target.value, 10);
    setSelectedBranch(branchId);
  };

  const handleUnitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const unitId = parseInt(event.target.value, 10);
    setSelectedUnit(unitId);
  };

  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="card">
          <div className="input-group p-2">
            <select
              className="form-select rounded me-2"
              value={selectedBranch || ''}
              onChange={handleBranchChange}
              aria-label="Select Branch"
            >
              <option value="">all</option>
              {branches.map(branch => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
            </select>
            <div className="input-group-prepend">
              <span
                className="input-group-text bg-transparent border-0 p-0"
                id="basic-addon1"
              >
                <button className="btn btn-dark fs-7 px-3">
                  Branch
                  <KTIcon iconName="filter" className="fs-7 mx-1" />
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-6">
        <div className="card">
          <div className="input-group p-2">
            <select
              className="form-select rounded me-2"
              value={selectedUnit || ''}
              onChange={handleUnitChange}
              aria-label="Select Unit"
            >
              <option value="">all</option>
              {units.map(unit => (
                <option key={unit.id} value={unit.id}>
                  {unit.name}
                </option>
              ))}
            </select>
            <div className="input-group-prepend">
              <span
                className="input-group-text bg-transparent border-0 p-0"
                id="basic-addon1"
              >
                <button className="btn btn-dark fs-7 px-3">
                  Unit
                  <KTIcon iconName="filter" className="fs-7 mx-1" />
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SearchDataScad };
