import React, { useEffect, useRef } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

type Props = {
  className: string;
  branch: {
    description: string;
    latitude: number | null;
    longitude: number | null;
    address: string;
  };
};

const UserData2: React.FC<Props> = ({ branch, className }) => {
  const { description, latitude, longitude, address } = branch || {};
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  const isValidCoordinates = latitude !== undefined && longitude !== undefined && !isNaN(latitude!) && !isNaN(longitude!);
  const defaultCenter = { lat: 40.712776, lng: -74.005974 }; // Default to New York City
  const mapCenter = isValidCoordinates ? { lat: latitude!, lng: longitude! } : defaultCenter;

  useEffect(() => {
    if (isValidCoordinates && mapRef.current && !markerRef.current) {
      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        map: mapRef.current,
        position: { lat: latitude!, lng: longitude! },
        title: "Branch Location",
      });
    }
  }, [latitude, longitude, isValidCoordinates]);

  return (
    <div className={`card ${className} p-0 bg-cust`}>
      <div className="card-body py-3 p-0 px-3 text-start">
        <div className="row">
          <div className="col-md-6">
            <h5 className="mb-2 d-flex gap-10">Description</h5>
            <span className="text-dark fw-bold d-block mb-3 fs-6">{description}</span>
            <h5 className="mb-2 d-flex gap-10">Location</h5>
            <div className="text-dark fw-bold d-block mb-3 fs-6">
              Address: {address}
            </div>
          </div>
          <div className="col-md-6">
            <h5 className="mb-2 d-flex gap-10">Map</h5>
            <LoadScript googleMapsApiKey="AIzaSyBtbpZ_-ETvYcJP5stMqWuSacGPj9MSTag">
              <GoogleMap
                mapContainerStyle={{ height: '400px', width: '100%' }}
                zoom={15}
                center={mapCenter}
                onLoad={(map) => {
                  mapRef.current = map; // Assign map instance to mapRef
                }}
              />
            </LoadScript>
          </div>
        </div>
      </div>
    </div>
  );
};

export { UserData2 };
