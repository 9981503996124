import { FC, useState, useEffect, useRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb';
import { fetchCountries, fetchStates, fetchCities, addBranch } from '../../../services/branchesService';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useLoadScript, GoogleMap, Marker, StandaloneSearchBox, LoadScriptProps } from '@react-google-maps/api';
import { Link } from 'react-router-dom';
interface Country {
  iso: string;
  name: string;
}

interface State {
  id: number;
  name: string;
}

interface City {
  id: number;
  name: string;
}

const libraries: LoadScriptProps['libraries'] = ['places'];

const AddNewBranch: FC = () => {
  const intl = useIntl();
  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedState, setSelectedState] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [branchName, setBranchName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const navigate = useNavigate();
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBtbpZ_-ETvYcJP5stMqWuSacGPj9MSTag', // Replace with your actual API key
    libraries,
  });

  useEffect(() => {
    const fetchCountriesData = async () => {
      try {
        const countries = await fetchCountries();
        setCountries(countries || []);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountriesData();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const fetchStatesData = async () => {
        try {
          const states = await fetchStates(selectedCountry);
          setStates(states || []);
        } catch (error) {
          console.error('Error fetching states:', error);
        }
      };

      fetchStatesData();
    }
  }, [selectedCountry]);

  const handleCountryChange = (countryCode: string) => {
    setSelectedCountry(countryCode);
    setSelectedState(null);
    setSelectedCity(null);
    setStates([]);
    setCities([]);
  };

  const handleStateChange = async (stateId: string) => {
    setSelectedState(stateId);
    setSelectedCity(null);
    setCities([]);

    try {
      const cities = await fetchCities(stateId);
      setCities(cities || []);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const handleCityChange = (cityId: string) => {
    setSelectedCity(cityId);
  };


  const normalizeString = (str: string) => {
    return str.toLowerCase().replace(/\s+/g, ' ').trim();
  };
  
  
  const handlePlaceChanged = useCallback(async () => {
    const place = searchBoxRef.current?.getPlaces()?.[0];
    if (place && place.geometry?.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setLatitude(lat);
      setLongitude(lng);
      setAddress(place.formatted_address || '');
  
      // Reverse geocode to get country and state
      const geocoder = new google.maps.Geocoder();
      const { results } = await geocoder.geocode({ location: { lat, lng } });
      
      let selectedPlaceCountry: string | null = null;
      let selectedPlaceState: string | null = null;
      
      if (results && results.length > 0) {
        results[0].address_components.forEach((component) => {
          if (component.types.includes('country')) {
            selectedPlaceCountry = component.short_name; // ISO country code
          }
          if (component.types.includes('administrative_area_level_1')) {
            selectedPlaceState = component.long_name;
          }
        });
  
        // Normalize the strings for comparison
        const normalizedSelectedCountry = normalizeString(selectedCountry || '');
        const normalizedPlaceCountry = normalizeString(selectedPlaceCountry || '');
        const normalizedSelectedState = normalizeString(selectedState || '');
        const normalizedPlaceState = normalizeString(selectedPlaceState || '');
  console.log(normalizedSelectedState, 'from choosesing i think');
  console.log(normalizedPlaceState, 'from google map is think');
  
        if (
          (normalizedSelectedCountry && normalizedSelectedCountry !== normalizedPlaceCountry) 
          // (normalizedSelectedState && normalizedSelectedState !== normalizedPlaceState)
        ) {
          toast.error('Your location does not belong to your selected country!');
        }
      }
    }
  }, [selectedCountry, selectedState]);
  
  
  
  const onSearchBoxLoad = useCallback((searchBox: google.maps.places.SearchBox) => {
    searchBoxRef.current = searchBox;
  }, []);

  const handleSave = async () => {
    try {
      const payload = {
        name: branchName,
        description,
        country_code: selectedCountry,
        state_id: selectedState,
        city_id: selectedCity,
        address,
        latitude,
        longitude,
        country_iso: selectedCountry,
      };

      await addBranch(payload);
      toast.success('Branch added successfully!');
      setErrors({});
      setTimeout(() => {
        navigate('/dashboard/fields/Branches');
      }, 2000);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errorMessages = error.response.data.errors || {};
        setErrors(errorMessages);
        
        Object.keys(errorMessages).forEach((key) => {
          toast.error(`Error in ${key}: ${errorMessages[key].join(', ')}`);
        });
      } else {
        toast.error('Unexpected error occurred. Please try again later.');
      }
    }
  };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (
    <>
      <Toaster />
      <div className="">
        <h1>Add New Branch</h1>
        <Breadcrumb secondItem="Page" />
        <div className="card p-5 ">
          <div className="row">
            <div className="col-lg-12 ">
              <h3 className="mb-5">General Information </h3>

              <div className="mb-5">
                <label className="form-label">Branch Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Branch Name"
                  value={branchName}
                  onChange={(e) => setBranchName(e.target.value)}
                />
                {errors.name && (
                  <div className="text-danger">{errors.name.join(', ')}</div>
                )}
              </div>
              <div className="mb-5">
                <label className="form-label">Description </label>
                <textarea
                  className="form-control "
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={5}
                ></textarea>
              </div>
              <hr />
            </div>

            <div className="col-lg-12 mb-5">
              <h3 className="mb-5">Location Information </h3>

              <div className="d-flex gap-5 mb-5">
                <div className="w-100">
                  <label className="form-label">Country </label>
                  <select
                    className="form-select"
                    value={selectedCountry || ''}
                    onChange={(e) => handleCountryChange(e.target.value)}
                  >
                    <option value="">Select a country</option>
                    {countries.length > 0 &&
                      countries.map((country) => (
                        <option key={country.iso} value={country.iso}>
                          {country.name}
                        </option>
                      ))}
                  </select>
                  {errors.country_code && (
                    <div className="text-danger">{errors.country_code.join(', ')}</div>
                  )}
                </div>
              </div>

              <div className="d-flex gap-5">
                <div className="mb-5 w-100">
                  <label className="form-label">State </label>
                  <select
                    className="form-select"
                    value={selectedState || ''}
                    onChange={(e) => handleStateChange(e.target.value)}
                    disabled={!selectedCountry}
                  >
                    <option value="">Select a state</option>
                    {states.length > 0 &&
                      states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                  </select>
                  {errors.state_id && (
                    <div className="text-danger">{errors.state_id.join(', ')}</div>
                  )}
                </div>
                <div className="mb-5 w-100">
                  <label className="form-label">City </label>
                  <select
                    className="form-select"
                    value={selectedCity || ''}
                    onChange={(e) => handleCityChange(e.target.value)}
                    disabled={!selectedState}
                  >
                    <option value="">Select a city</option>
                    {cities.length > 0 &&
                      cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                  </select>
                  {errors.city_id && (
                    <div className="text-danger">{errors.city_id.join(', ')}</div>
                  )}
                </div>
              </div>
              <div className="mb-5">
                <label className="form-label">Address </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              {/* <h3 className="mb-5 border-bottom pb-3">Location on Map </h3> */}
              <label className="form-label">Search for address  </label>

<StandaloneSearchBox onLoad={onSearchBoxLoad} onPlacesChanged={handlePlaceChanged}>
  <input
    type="text"
    className="form-control mb-3"
    placeholder="Search location"
  />
</StandaloneSearchBox>

<GoogleMap
  mapContainerStyle={{ height: '400px', width: '100%' }}
  zoom={15}
  center={latitude && longitude ? { lat: latitude, lng: longitude } : { lat: 40.712776, lng: -74.005974 }} // Default center
>
  {latitude && longitude && <Marker position={{ lat: latitude, lng: longitude }} />}
</GoogleMap>
              {/* <div className="mb-5 d-none">
                <label className="form-label">Latitude</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Latitude"
                  value={latitude || ''}
                  onChange={(e) => setLatitude(parseFloat(e.target.value))}
                />
                {errors.latitude && (
                  <div className="text-danger">{errors.latitude.join(', ')}</div>
                )}
              </div>
              <div className="mb-5 d-none">
                <label className="form-label">Longitude</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Longitude"
                  value={longitude || ''}
                  onChange={(e) => setLongitude(parseFloat(e.target.value))}
                />
                {errors.longitude && (
                  <div className="text-danger">{errors.longitude.join(', ')}</div>
                )}
              </div> */}
            </div>
          </div>

   
<hr />
          <div className="d-flex justify-content-between">
          <Link to="/dashboard/fields/Branches" className="btn btn-light">
             Cancel
            </Link>
            <button className="btn btn-dark" onClick={handleSave}>
              Save Branch
            </button>
      
          </div>
        </div>
      </div>
    </>
  );
};

export { AddNewBranch };
