import { FC, useEffect, useState } from 'react'
import axios from 'axios'
import { useIntl } from 'react-intl'
import Breadcrumb from '../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import { Link } from 'react-router-dom'
import LoadingSpinner from '../../../app/shared/LoadingSpinner'
import toast, { Toaster } from 'react-hot-toast'

interface TermsData {
  id: number
  title: string
  excerpt: string | null
  body: string
  image: string | null
}

const Terms: FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(true)
  const [termsData, setTermsData] = useState<TermsData | null>(null)
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await axios.get(`${apiUrl}/general/pages/partner-terms-and-conditions`)
        if (response.data.success) {
          setTermsData(response.data.data)
        } else {
          toast.error(intl.formatMessage({ id: 'error.loadingData' }))
        }
      } catch (error) {
        toast.error(intl.formatMessage({ id: 'error.networkError' }))
      } finally {
        setLoading(false)
      }
    }

    fetchTerms()
  }, [apiUrl, intl])

  return (
    <>
      <Toaster />
      {loading ? (
                <LoadingSpinner />
              ) : (
      <div className='pt-5 company_profile'>
        <div className='row'>
          <div className='col-12'>
            <div className='banner'>
              <img src='/media/auth/bg3-dark.jpg' alt='' className='w-100 img-fluid' />
              <div className='layout'>
                <h1>Terms and Conditions</h1>
              </div>
            </div>
            <div className='my-5'>
              <Breadcrumb secondItem='Page' />
            </div>
          </div>
          <div className='col-12'>
            <div className='card p-5'>
       
                <>
                  <h3 className='mb-3 pb-3 border-bottom'>{termsData?.title || 'Terms Title'}</h3>
                  <p>{termsData?.body}</p>
                </>
           
            </div>
          </div>
        </div>
      </div>
         )}
    </>
  )
}

export { Terms }
