import clsx from 'clsx'
import {FC, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'
// import rtlStylesheetPath from '../../../assets/css/style.rtl.css' 
const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/us.svg'),
  },
  {
    lang: 'ar',
    name: 'Arabic',
    flag: toAbsoluteUrl('/media/flags/sa.svg'),
  },
]

const Languages: FC = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  // Dynamically add RTL styles when 'ar' is selected
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.id = 'rtlStylesheet';
    console.log(lang);

    if (lang === 'ar') {
      // Use the imported stylesheet path
      link.href = '../../../../../src/_high-five/assets/css/style.rtl.css';  
      document.head.appendChild(link);
      document.body.classList.add('rtl'); // Add 'rtl' class to body if needed
    } else {
      document.body.classList.remove('rtl');  // Remove 'rtl' class if switching to LTR
      const existingRtlLink = document.getElementById('rtlStylesheet');
      if (existingRtlLink) {
        existingRtlLink.remove();
      }
    }

    // Cleanup if component unmounts or language changes
    return () => {
      const existingRtlLink = document.getElementById('rtlStylesheet');
      if (existingRtlLink) {
        existingRtlLink.remove();
      }
      document.body.classList.remove('rtl');
    };
  }, [lang]);

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          Language
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='metronic'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
