import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Define the shape of a unit, category, and activity
interface Unit {
  id: number;
  name: string;
}

interface Category {
  id: number;
  name: string;
}

interface Activity {
  id: number;
  name: string;
}

interface Dropdown4Props {
  onFilterChange: (filterName: string, value: string) => void;
  onApply: () => void;
}

export function Dropdown4({ onFilterChange, onApply }: Dropdown4Props) {
  const [units, setUnits] = useState<Unit[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]); // For activities
  const [years, setYears] = useState<number[]>([]);
  const [loadingUnits, setLoadingUnits] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingActivities, setLoadingActivities] = useState(false); // For activity loading
  const [loadingYears, setLoadingYears] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null); // To store selected category
  const apiUrl = process.env.REACT_APP_API_URL;
  const paymentTypes = [
    { value: 'cash', label: 'Cash' },
    { value: 'online', label: 'Online' },
  ];

  const transactionTypes = [
    { value: 'online_booking', label: 'Online Booking' },
    { value: 'linked_booking', label: 'Linked Booking' },
    { value: 'manual_booking', label: 'Manual Booking' },
    { value: 'public_event', label: 'Public Event' },
  ];
  const months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
  ];

  useEffect(() => {
    // Fetch Units
    axios.get(apiUrl + '/general/units')
      .then((response) => {
        setUnits(response.data.data);
        setLoadingUnits(false);
      })
      .catch((error) => {
        console.error("Error fetching units:", error);
        setLoadingUnits(false);
      });

    // Fetch Categories
    axios.get(`${apiUrl}/memberships/categories`)
      .then((response) => {
        setCategories(response.data.data.categories);
        setLoadingCategories(false);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        setLoadingCategories(false);
      });

    // Generate a list of years (last 30 years for example)
    const currentYear = new Date().getFullYear();
    const yearsArray = Array.from(new Array(30), (val, index) => currentYear - index);
    setYears(yearsArray);
    setLoadingYears(false);
  }, []);

  // Fetch activities based on selected category_id
  useEffect(() => {
    if (selectedCategoryId) {
      setLoadingActivities(true); // Start loading
      axios.get(`${apiUrl}/general/activities?category_id=${selectedCategoryId}`)
        .then((response) => {
          setActivities(response.data.data); // Assuming `data` holds the activities
          setLoadingActivities(false);
        })
        .catch((error) => {
          console.error("Error fetching activities:", error);
          setLoadingActivities(false);
        });
    }
  }, [selectedCategoryId]); // Runs when `selectedCategoryId` changes

  // Handles change in any dropdown
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>, filterName: string) => {
    const value = event.target.value;
    onFilterChange(filterName, value); // Call parent function to update the filter state

    // If category is selected, set the selectedCategoryId state
    if (filterName === 'category_id') {
      setSelectedCategoryId(Number(value));
    }
  };
  const handleReset = () => {
    setSelectedCategoryId(null);
    setUnits([]);
    setCategories([]);
    setActivities([]);
    setYears([]);
    onFilterChange('year', '');
    onFilterChange('month', '');
    onFilterChange('unit_id', '');
    onFilterChange('category_id', '');
    onFilterChange('activity_id', '');
    onFilterChange('payment_type', '');
    onFilterChange('transaction_type', '');
  };
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className="height_dropdown">
          
          {/* Year Dropdown */}
          <div className='mb-10'>
            <label className='form-label fw-bold'>Year</label>
            <div>
              {loadingYears ? (
                <div>Loading years...</div>
              ) : (
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select year'
                  data-allow-clear='true'
                  onChange={(e) => handleSelectChange(e, 'year')}
                >
                  <option value=''>Select Year</option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>

          {/* Month Dropdown */}
          <div className='mb-10'>
            <label className='form-label fw-bold'>Month</label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select month'
                data-allow-clear='true'
                onChange={(e) => handleSelectChange(e, 'month')}
              >
                <option value=''>Select Month</option>
                {months.map((month) => (
                  <option key={month.id} value={month.id}>
                    {month.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Unit Dropdown */}
          <div className='mb-10'>
            <label className='form-label fw-bold'>Units</label>
            <div>
              {loadingUnits ? (
                <div>Loading units...</div>
              ) : (
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select unit'
                  data-allow-clear='true'
                  onChange={(e) => handleSelectChange(e, 'unit_id')}
                >
                  <option value=''>Select Unit</option>
                  {units.map((unit) => (
                    <option key={unit.id} value={unit.id}>
                      {unit.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>

          {/* Category Dropdown */}
          <div className='mb-10'>
            <label className='form-label fw-bold'>Categories</label>
            <div>
              {loadingCategories ? (
                <div>Loading categories...</div>
              ) : (
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select category'
                  data-allow-clear='true'
                  onChange={(e) => handleSelectChange(e, 'category_id')}
                >
                  <option value=''>Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>

          {/* Activities Dropdown */}
          <div className='mb-10'>
            <label className='form-label fw-bold'>Activities</label>
            <div>
              {loadingActivities ? (
                <div>Loading activities...</div>
              ) : (
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select activity'
                  data-allow-clear='true'
                  onChange={(e) => handleSelectChange(e, 'activity_id')}
                >
                  <option value=''>Select Activity</option>
                  {activities.map((activity) => (
                    <option key={activity.id} value={activity.id}>
                      {activity.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          {/* Payment Type Dropdown */}
          <div className='mb-10'>
            <label className='form-label fw-bold'>Payment Type</label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select payment type'
                data-allow-clear='true'
                onChange={(e) => handleSelectChange(e, 'payment_type')}
              >
                <option value=''>Select Payment Type</option>
                {paymentTypes.map((paymentType) => (
                  <option key={paymentType.value} value={paymentType.value}>
                    {paymentType.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Transaction Type Dropdown */}
          <div className='mb-10'>
            <label className='form-label fw-bold'>Transaction Type</label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select transaction type'
                data-allow-clear='true'
                onChange={(e) => handleSelectChange(e, 'transaction_type')}
              >
                <option value=''>Select Transaction Type</option>
                {transactionTypes.map((transactionType) => (
                  <option key={transactionType.value} value={transactionType.value}>
                    {transactionType.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

        </div>

        <div className='d-flex justify-content-between'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
            
          >
            Reset
          </button>

          <button 
            type='button' 
            className='btn btn-sm btn-dark' 
            data-kt-menu-dismiss='true'
            onClick={onApply} // Call the parent’s apply function when clicked
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}