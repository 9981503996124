import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import {Dropdown4} from '../../../../_high-five/partials/content/dropdown/Dropdown4'
import {KTIcon} from '../../../../_high-five/helpers'
import axios from 'axios'
import toast from 'react-hot-toast'
import {Link} from 'react-router-dom'

interface BookingItem {
  id: number
  category: string
  activity_category: string
  unit: {
    id: number
    name: string
  }
  transaction_type: string
  transaction_id: string

  booking_status: string
  date: string
  amount: string
  collected: string
  type: string
}

interface BookingResponse {
  success: boolean
  message: string
  data: {
    items: {
      items: BookingItem[]
      paginate: {
        total: number
        count: number
        per_page: number
        current_page: number
        total_pages: number
        next_page_url: string
        prev_page_url: string
      }
    }
  }
}

// interface FinancialData {
//   success: boolean
//   message: string
//   data: {
//     statistics: {
//       booking: {
//         online_revenue: string
//         cash_revenue: string
//         online_collected: string
//         cash_collected: string
//       }
//       public_event: {
//         online_revenue: string
//         cash_revenue: string
//         online_collected: string
//         cash_collected: string
//       }
//     }
//   }
// }
interface FinancialData {
  success: boolean
  message: string
  data: {
    statistics: {
      booking: {
        online_revenue: string
        cash_revenue: string
        online_collected: string
        cash_collected: string
      }
      public_event: {
        online_revenue: string
        cash_revenue: string
        online_collected: string
        cash_collected: string
      }
    },
    items?: {
      items: BookingItem[]
      paginate: {
        total: number
        count: number
        per_page: number
        current_page: number
        total_pages: number
        next_page_url: string | null
        prev_page_url: string | null
      }
    }
  }
}

interface Pagination {
  current_page: number;
  total_pages: number;
  next_page_url: string | null;
  prev_page_url: string | null;
}
const Account: FC = () => {
  const intl = useIntl()
  const apiUrl = process.env.REACT_APP_API_URL
  const [financialData, setFinancialData] = useState<FinancialData | null>(null)
  const [bookings, setBookings] = useState<BookingItem[]>([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({
    year: '',
    month: '',
    category_id: '',
    activity_id: '',
    transaction_type: '',
    payment_type: ''
  });
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear(); // Get the current year
  const currentMonthIndex = currentDate.getMonth(); // Get the current month (0-indexed)
  const [pagination, setPagination] = useState<Pagination>({
    current_page: 1,
    total_pages: 1,
    next_page_url: null,
    prev_page_url: null,
  });
  // Array of month names
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Get the current month's name
  const displayMonth = monthNames[currentMonthIndex]; // e.g., "October"
  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
    
  };

  const applyFilters = async () => {
    console.log("Applied Filters: ", filters);
  
    // Create a params object with only non-empty values
    const params = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== '')
    );
  
    const response = await axios.get<FinancialData>(`${apiUrl}/financials`, {
      params: params
    });
  
    setFinancialData(response.data);
  };
  
  useEffect(() => {
    const fetchFinancialData = async () => {
      try {
        const response = await axios.get<FinancialData>(`${apiUrl}/financials`)
        setFinancialData(response.data)
        const paginate = response.data.data?.items?.paginate;

        toast.success('Financial data loaded successfully')
        // setPagination({
        //   current_page: paginate.current_page,
        //   total_pages: paginate.total_pages,
        //   next_page_url: paginate.next_page_url,
        //   prev_page_url: paginate.prev_page_url,
        // });
      } catch (error) {
        toast.error('Error loading financial data')
      }
    }

    const fetchBookingData = async () => {
      try {
        const response = await axios.get<BookingResponse>(`${apiUrl}/financials`) // Adjust this URL if needed
        setBookings(response.data.data.items.items)
        toast.success('Items data loaded successfully')
      } catch (error) {
        toast.error('Error loading booking data')
      }
    }

    fetchFinancialData()
    fetchBookingData()
  }, [apiUrl])
  const handleNextPage = () => {
    if (pagination.next_page_url) {
      setPagination((prev) => ({ ...prev, current_page: prev.current_page + 1 }));
    }
  };

  const handlePrevPage = () => {
    if (pagination.prev_page_url) {
      setPagination((prev) => ({ ...prev, current_page: prev.current_page - 1 }));
    }
  };
  // Calculate totals for booking and public event
  const bookingTotalRevenue =
    parseFloat(financialData?.data.statistics.booking.online_revenue || '0') +
    parseFloat(financialData?.data.statistics.booking.cash_revenue || '0')

  const bookingTotalCollected =
    parseFloat(financialData?.data.statistics.booking.online_collected || '0') +
    parseFloat(financialData?.data.statistics.booking.cash_collected || '0')

  const publicEventTotalRevenue =
    parseFloat(financialData?.data.statistics.public_event.online_revenue || '0') +
    parseFloat(financialData?.data.statistics.public_event.cash_revenue || '0')

  const publicEventTotalCollected =
    parseFloat(financialData?.data.statistics.public_event.online_collected || '0') +
    parseFloat(financialData?.data.statistics.public_event.cash_collected || '0')
    const cashoverallTotalRevenue =     parseFloat(financialData?.data.statistics.booking.cash_revenue || '0') + parseFloat(financialData?.data.statistics.public_event.cash_revenue || '0')

    
    const onlineoverallTotalRevenue =     parseFloat(financialData?.data.statistics.booking.online_revenue || '0') + parseFloat(financialData?.data.statistics.public_event.online_revenue || '0')

    const onlineoverallTotalCollected =     parseFloat(financialData?.data.statistics.booking.online_collected || '0') + parseFloat(financialData?.data.statistics.public_event.online_collected || '0')
    const cashoverallTotalCollected =     parseFloat(financialData?.data.statistics.booking.cash_collected || '0') + parseFloat(financialData?.data.statistics.public_event.cash_collected || '0')




  const overallTotalRevenue = bookingTotalRevenue + publicEventTotalRevenue
  const overallTotalCollected = bookingTotalCollected + publicEventTotalCollected

  return (
    <>
      <div className='pt-5'>
        <h1>Account</h1>
        <Breadcrumb secondItem='Page' />

        <div className='card mb-5 p-3'>
          <div className='d-md-flex justify-content-between gap-5 align-items-center'>
            <h3 className='mb-0'>
            Financial <small>Transaction Of {displayMonth}/{currentYear}</small> For all units
            </h3>
             <div className=' d-flex gap-5 '>
              <button className='btn btn-dark'>Export</button>
              <button
                className='btn btn-dark'
                data-kt-menu-trigger='click'
                
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='filter' className='fs-6 text-muted me-1' />
                Filter
              </button>
      <Dropdown4 onFilterChange={handleFilterChange} onApply={applyFilters} />
            </div> 
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='card p-3 rounded-4 min_accTable'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4 account_table_sm'>
                  <thead>
                    <tr className='border_bottom_dashed'>
                      <th>
                        <h4 className='text-dark fw-bolder d-block mb-1 fs-6'>Booking</h4>
                      </th>
                      <th>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>Revenue</span>
                      </th>
                      <th>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>Collected</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='border_bottom_dashed'>
                      <th>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>Online</span>
                      </th>
                      <td>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>
                          {financialData?.data.statistics.booking.online_revenue || '0'}
                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>
                          {financialData?.data.statistics.booking.online_collected || '0'}
                        </span>
                      </td>
                    </tr>
                    <tr className='border_bottom_dashed'>
                      <th>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>Cash</span>
                      </th>
                      <td>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>
                          {financialData?.data.statistics.booking.cash_revenue || '0'}
                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>
                          {financialData?.data.statistics.booking.cash_collected || '0'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <h4 className='text-dark fw-bolder d-block mb-1 fs-6'>Total (SAR)</h4>
                      </th>
                      <td>
                        <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                          {bookingTotalRevenue.toFixed(2)}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                          {bookingTotalCollected.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='card p-3 rounded-4 min_accTable'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4 account_table_sm'>
                  <thead>
                    <tr className='border_bottom_dashed'>
                      <th>
                        <h4 className='text-dark fw-bolder d-block mb-1 fs-6'>Public Event</h4>
                      </th>
                      <th>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>Revenue</span>
                      </th>
                      <th>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>Collected</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='border_bottom_dashed'>
                      <th>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>Online</span>
                      </th>
                      <td>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>
                          {financialData?.data.statistics.public_event.online_revenue || '0'}
                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>
                          {financialData?.data.statistics.public_event.online_collected || '0'}
                        </span>
                      </td>
                    </tr>
                    <tr className='border_bottom_dashed'>
                      <th>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>Cash</span>
                      </th>
                      <td>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>
                          {financialData?.data.statistics.public_event.cash_revenue || '0'}
                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-bold d-block mb-1 fs-8'>
                          {financialData?.data.statistics.public_event.cash_collected || '0'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <h4 className='text-dark fw-bolder d-block mb-1 fs-6'>Total (SAR)</h4>
                      </th>
                      <td>
                        <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                          {publicEventTotalRevenue.toFixed(2)}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                          {publicEventTotalCollected.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='card p-3 rounded-4 min_accTable'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4 account_table_sm'>
                  <thead>
                    <tr className='border_bottom_dashed'>
                      <th>
                        {' '}
                        <h4 className='text-dark fw-bolder  d-block mb-1 fs-6'> All Revenues</h4>
                      </th>
                      <th>
                        {' '}
                        <span className='text-muted fw-bold  d-block mb-1 fs-8'>Revenue </span>
                      </th>
                      <th>
                        {' '}
                        <span className='text-muted fw-bold  d-block mb-1 fs-8'>Collected </span>
                      </th>

                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='border_bottom_dashed'>
                      <th>
                        <span className='text-muted fw-bold  d-block mb-1 fs-8'>Online </span>
                      </th>
                      <td>
                        {' '}
                        <span className='text-muted fw-bold  d-block mb-1 fs-8'>
                        {onlineoverallTotalRevenue.toFixed(2)}

                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-bold  d-block mb-1 fs-8'>
                          {' '}
                      
                          {onlineoverallTotalCollected.toFixed(2)}

                        </span>
                      </td>
                    </tr>
                    <tr className='border_bottom_dashed'>
                      <th>
                        <span className='text-muted fw-bold  d-block mb-1 fs-8'>Cash </span>
                      </th>
                      <td>
                        {' '}
                        <span className='text-muted fw-bold  d-block mb-1 fs-8'>
                          {' '}
                       
                          {cashoverallTotalRevenue.toFixed(2)}
                        </span>
                      </td>
                      <td>
                        {' '}
                        <span className='text-muted fw-bold  d-block mb-1 fs-8'>
                          {' '}
                          {cashoverallTotalCollected.toFixed(2)}

           
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <h4 className='text-dark fw-bolder d-block mb-1 fs-6'>Total (SAR)</h4>
                      </th>
                      <td>
                        <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                          {overallTotalRevenue.toFixed(2)}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                          {overallTotalCollected.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='card mt-5'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table align-middle gs-0 gy-4 account_table'>
                    <thead>
                      <tr className='fw-bold text-muted bg-dark'>
                        <th className='rounded-start'>ID</th>
                        <th>Category</th>
                        <th>Unit</th>
                        <th>Transaction Type</th>
                        <th>Booking Status</th>
                        <th>Date & Time</th>
                        <th>Revenue</th>
                        <th >Collected Amount</th>
                        <th className='rounded-end'>action</th>

                      </tr>
                    </thead>
                    <tbody>
                      {bookings && bookings.length > 0 ? (
                        bookings.map((booking) => (
                          <tr key={booking.id}>
                            <td>
                              <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                                {' '}
                                #{booking.id}
                              </span>
                            </td>
                            <td>
                              {' '}
                              <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                                {booking.category}{' '}
                              </span>
                            </td>
                            <td>
                              {' '}
                              <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                                {booking.unit.name}{' '}
                              </span>
                            </td>
                            <td>
                              <h4> {booking.transaction_type}</h4>

                              <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                                Id: #{booking.transaction_id}
                              </span>
                            </td>
                            <td>
                              {' '}
                              <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                                {booking.booking_status}{' '}
                              </span>
                            </td>
                            <td>
                              {' '}
                              <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                                {booking.date}{' '}
                              </span>
                            </td>
                            <td>
                              {' '}
                              <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                                {booking.amount}{' '}
                              </span>
                            </td>
                            <td>
                              {' '}
                              <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                                {booking.collected}{' '}
                              </span>
                              <span className='text-dark fw-bold d-block mb-1 fs-6 text-warning'>
                                {booking.amount === booking.collected ? (
                                  <p></p>
                                ) : (
                                  <p>
                                    Remaining:{' '}
                                    {(
                                      parseFloat(booking.amount) - parseFloat(booking.collected)
                                    ).toFixed(2)}
                                  </p>
                                )}
                              </span>
                              {/* <span className='text-dark fw-bold  d-block mb-1 fs-6'>Partner</span> */}
                            </td>
                            <td>    <Link
                                  to={
                                    '/dashboard/reservation/BookingDetails?bookingId=' + booking.id
                                  }
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                  <i className='fas fa-eye'></i>
                                </Link></td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8}>No bookings available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between mt-4">
              <button
                onClick={handlePrevPage}
                disabled={!pagination.prev_page_url}
                className="btn btn-secondary"
              >
                Previous
              </button>
              <span>Page {pagination.current_page} of {pagination.total_pages}</span>
              <button
                onClick={handleNextPage}
                disabled={!pagination.next_page_url}
                className="btn btn-secondary"
              >
                Next
              </button>
            </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Account}