import { FC, useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

interface TaxData {
  can_update_tax: boolean;
  tax: 'not_applicable' | 'included' | 'excluded';
}

const TaxSettings: FC = () => {
  const [taxData, setTaxData] = useState<TaxData | null>(null);
  const [selectedTax, setSelectedTax] = useState<TaxData['tax']>('not_applicable');
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTaxData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/tax`);
        setTaxData(response.data.data);
        setSelectedTax(response.data.data.tax); // Set selected tax based on the response
      } catch (error) {
        toast.error('Failed to fetch tax data');
      } finally {
        setLoading(false);
      }
    };

    fetchTaxData();
  }, [apiUrl]);

  const handleUpdate = async () => {
    if (!taxData?.can_update_tax) return;

    setUpdating(true);
    try {
      const response = await axios.post(`${apiUrl}/tax`, { tax: selectedTax });
      if (response.data.success) {
        toast.success('Tax settings updated successfully!');
      }
    } catch (error) {
      toast.error('Failed to update tax settings');
    } finally {
      setUpdating(false);
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className='pt-5'>
      <div className="bredcrumb_card mb-10">
        <h1>TAX SETTING</h1>
      </div>
      <div className="mt-10 shadow-sm rounded p-10">
        <h2>Tax methods</h2>
        <div className="d-flex gap-20 mt-10 pt-5 border-top mb-10">
          <div>Tax status :</div>
          <div>
            <div className="mb-10">
              {/* Conditionally render the radio buttons based on can_update_tax */}
              {taxData?.can_update_tax ? (
                <>
                  <div className="form-check form-check-custom form-check-solid form-check-sm align-items-start mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="flexRadioIncluded"
                      name="taxStatus"
                      value="included"
                      checked={selectedTax === 'included'}
                      onChange={() => setSelectedTax('included')}
                    />
                    <label className="form-check-label">
                      <span className="text-muted">Tax Included</span>
                    </label>
                  </div>
                  <div className="form-check form-check-custom form-check-solid form-check-sm align-items-start">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="flexRadioExcluded"
                      name="taxStatus"
                      value="excluded"
                      checked={selectedTax === 'excluded'}
                      onChange={() => setSelectedTax('excluded')}
                    />
                    <label className="form-check-label">
                      <span className="text-muted">Tax Excluded</span>
                    </label>
                  </div>
                </>
              ) : (
                <div className="form-check form-check-custom form-check-solid form-check-sm align-items-start mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="flexRadioNotApplicable"
                    name="taxStatus"
                    value="not_applicable"
                    checked={selectedTax === 'not_applicable'}
                    onChange={() => setSelectedTax('not_applicable')}
                    disabled={!taxData?.can_update_tax}
                  />
                  <label className="form-check-label">
                    <span className="text-muted">Not Applicable for Tax (Default)</span>
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-10 pt-5 border-top mb-10">
          <p className="text-muted"><i className="fa-solid fa-circle-info mx-1"></i> Any change will affect all upcoming sessions not yet booked.</p>
          <p className="text-muted"><i className="fa-solid fa-circle-info mx-1"></i>Taxes applied: Country: SA VAT: 12%</p>
        </div>

        <div className="d-flex justify-content-between mt-10 pt-5 border-top mb-10">
          <button className="btn btn-light-dark" disabled={updating}>
            Cancel
          </button>
          <button
            className="btn btn-dark"
            onClick={handleUpdate}
            disabled={!taxData?.can_update_tax || updating}
          >
            {updating ? 'Updating...' : 'Update'}
          </button>
        </div>
      </div>
    </div>
  );
};

export { TaxSettings };
