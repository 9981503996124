import React, {FC, useState, useEffect} from 'react'
import {useParams, useLocation} from 'react-router-dom'
import axios from 'axios'
import {DayPilot, DayPilotCalendar} from 'daypilot-pro-react'
import CustomTimePickerWeek from './CustomTimePickerWeek'
import ContextMenuWeekly from './ContextMenuWeekly' // import ContextMenu
import './CalenderWeekly.css'
import toast, {Toaster} from 'react-hot-toast'

interface Props {
  unitDataGender: 'male' | 'female' | 'Both' | null;
}
const CalenderWeekly: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const apiUrl = process.env.REACT_APP_API_URL
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const unitQueryParam = queryParams.get('unit_id')
  const sessionQueryParam = queryParams.get('session_id')
  const typeQueryParam = queryParams.get('type')
  const [unitDataGender, setUnitDataGender] = useState<string | null>(null);

  const [events, setEvents] = useState<any[]>([])

  const [startDate, setStartDate] = useState(() => {
    const today = DayPilot.Date.today()
    const dayOfWeek = today.getDayOfWeek() // Get the day of the week (0 = Sunday, 1 = Monday, ...)
    const offset = dayOfWeek === 0 ? 0 : dayOfWeek // Adjust the offset to ensure alignment
    const startOfWeek = today.addDays(-offset) // Adjust the start of the week based on offset
    return startOfWeek
  })
  const [modalVisible, setModalVisible] = useState(false)
  const [newEventStart, setNewEventStart] = useState<any>(null)
  const [newEventEnd, setNewEventEnd] = useState<any>(null)
  const [startTime, setStartTime] = useState<string>('00:00')
  const [endTime, setEndTime] = useState<string>('00:00')
  const [overnight, setOvernight] = useState('no')
  const [price, setPrice] = useState<number | ''>('')
  const [gender, setGender] = useState('both')
  const [sessionType, setSessionType] = useState('fixed')
  const [contextMenuVisible, setContextMenuVisible] = useState(false)
  const [contextMenuPosition, setContextMenuPosition] = useState({x: 0, y: 0})
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null)
  const [selectedEvent, setSelectedEvent] = useState<{} | null>(null)
  const [selectedDay, setSelectedDay] = useState<string | null>(null) // Add state to store the selected day

  const addEvent = () => {
    if (!newEventStart || !newEventEnd) {
      return
    }
    const newEvent = {
      id: DayPilot.guid(),
      text: `$${price}`,
      start: newEventStart,
      end: newEventEnd,
      startTime,
      endTime,
      overnight,
      price,
      // genderessionType,
    }
    setEvents([...events, newEvent])
  }
  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const {data} = await axios.get(
          `${apiUrl}/units/${unitQueryParam}/schedules/${sessionQueryParam}`
        )
        let responsUnitData = axios.get(`${apiUrl}/units/${unitQueryParam}`).then((response) => {
          setUnitDataGender(response?.data?.data?.gender)
          console.log(unitDataGender ,'unitDataGender')
        })
        if (data.success && data.data && data.data.sessions) {
          const sessions = data.data.sessions

          const mappedEvents = sessions.map((session: any) => {
            const startTimeParts = session.start_time.split(':')
            const endTimeParts = session.end_time.split(':')

            const sessionStartDate = startDate.addDays(getDayIndex(session.day)) // Adjust day based on week start

            return {
              id: session.id,
              text: `${session.price} ${session.currency}`,
              start: sessionStartDate
                .addHours(parseInt(startTimeParts[0]))
                .addMinutes(parseInt(startTimeParts[1])),
              end: sessionStartDate
                .addHours(parseInt(endTimeParts[0]))
                .addMinutes(parseInt(endTimeParts[1])),
              startTime: session.start_time,
              endTime: session.end_time,
              overnight: session.overnight ? 'yes' : 'no',
              price: session.price,
              gender: session.gender,
              sessionType: session.coming_public_events > 0 ? 'public' : 'private',
            }
          })

          setEvents(mappedEvents)
        }
        setIsLoading(false)
      } catch (error: unknown) {
        let errorMessage = 'There was an error  Please try again.'

        // Check if the error is of the type we expect
        if (error instanceof Error) {
          // If the error is an instance of the generic Error class, we can log its message
          console.error('Error deleting event:', error.message)
        }

        // Use type assertion to narrow down the type of error
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        // Log the error and show an error toast notification
        console.error('Error deleting event:', errorMessage)
        toast.error(errorMessage)
      }
    }

    fetchSessions()
  }, [apiUrl, unitQueryParam, sessionQueryParam])

  const getDayIndex = (day: string) => {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    return daysOfWeek.indexOf(day)
  }
  const openModal = (start: any, end: any) => {
    setNewEventStart(start)
    setNewEventEnd(end)
    setStartTime(start.toString('HH:mm'))
    setEndTime(end.toString('HH:mm'))
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
    setNewEventStart(null)
    setNewEventEnd(null)
    setStartTime('00:00')
    setEndTime('00:00')
    setOvernight('no')
    setPrice('')
    setGender('both')
    setSessionType('fixed') // Reset sessionType to 'fixed' when closing modal
  }

  const handleEventCreation = async () => {
    // if ((price === '' || !newEventStart || !newEventEnd || !selectedDay) || !selectedEvent) {
    //   alert('Please enter event details.')
    //   return
    // }
    if (overnight !== 'yes' ) {
      if (startTime >= endTime) {
        toast.error('Start Time must be before End Time')
        return
      }
    }
    let type = typeQueryParam === 'Fixed' ? 'fixed' : 'temporary'

    // Prepare the data to be sent to the backend
    const eventData = {
      start_time: startTime,
      end_time: endTime,
      overnight: 0,
      price,
      gender: gender,
      day: selectedDay|| 'Sun',
    }
    console.log(eventData)
    if (type === 'temporary') {
      eventData['temporary_schedule_id'] = sessionQueryParam
    }

    try {
      let response
      if (selectedEventId) {
        // Update existing event
        response = await axios.put(
          `${apiUrl}/units/${unitQueryParam}/schedules/${type}/sessions/${selectedEventId}`,
          eventData
        )
      } else {
        // Create new event
        response = await axios.post(
          `${apiUrl}/units/${unitQueryParam}/schedules/${type}/sessions`,
          eventData
        )
      }

      if (response.data.success) {
        // Refresh event list or update state
        if (selectedEventId) {
          editEvent(selectedEventId, `$${price}`)
        } else {
          addEvent()
        }
        closeModal()
      } else {
        alert('Failed to create/update event. Please try again.')
      }
    } catch (error: unknown) {
      let errorMessage = 'There was an error  Please try again.'

      // Check if the error is of the type we expect
      if (error instanceof Error) {
        // If the error is an instance of the generic Error class, we can log its message
        console.error('Error deleting event:', error.message)
      }

      // Use type assertion to narrow down the type of error
      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as {response: {data: {message: string}}}
        errorMessage = err.response.data.message || errorMessage
      }

      // Log the error and show an error toast notification
      console.error('Error deleting event:', errorMessage)
      toast.error(errorMessage)
    }
  }

  const handleCellClick = (args: any) => {
    if (args && args.start && args.end) {
      const dayOfWeek = args.start
        .toDate()
        .toLocaleDateString('en-US', {weekday: 'short'})
        .slice(0, 3)
      setSelectedDay(dayOfWeek) // Store the selected day as the first three characters
      openModal(args.start, args.end)
    }
  }

  const onBeforeCellRender = (args: any) => {
    const now = new DayPilot.Date()
    if (args.cell.start < now && args.cell.end > now) {
      args.cell.backColor = '#f8f9fa'
      args.cell.html = "<div class='current-time-indicator'></div>"
    }
  }

  const editEvent = (eventId: string, newText: string) => {
    setEvents(events.map((event) => (event.id === eventId ? {...event, text: newText} : event)))
  }

  const deleteEvent = async (id: string) => {
    console.log(id, 'this is id for delete event')
    

    let type = typeQueryParam === 'Fixed' ? 'fixed' : 'temporary'
    const selectedEvent = events.find((event) => event.id == id);
      // setSelectedEvent(selectedEvent)
      let data = {
        day : 'Sun',
        start_time : selectedEvent.startTime,
        end_time : selectedEvent.endTime,
  
      };
      
      if (type == 'temporary') {
        data['temporary_schedule_id'] = sessionQueryParam;
      }
      
      // Include the data in the Axios request configuration
      const response = await axios.delete(`${apiUrl}/units/${unitQueryParam}/schedules/${type}/sessions`, {
        data: data,  // Pass the data inside the AxiosRequestConfig object
      }); 
    setEvents(events.filter((event) => event.id !== id))
    setSelectedEventId(null)
  }

  const handleEditDeleteButtonClick = (event: React.MouseEvent, eventId: string) => {
    event.preventDefault()
    setContextMenuVisible(true)
    setContextMenuPosition({x: event.clientX, y: event.clientY})
    setSelectedEventId(eventId)
  }

  const handleEdit = () => {
    if (selectedEventId) {
      setSelectedEventId(selectedEventId)
      console.log(selectedEventId, 'chosen event');
      console.log('Events Array string:', events);
  
      const selectedEvent = events.find((event) => event.id == selectedEventId);
      setSelectedEvent(selectedEvent)
      if (selectedEvent) { // Check if selectedEvent was found
        // Load selected event data into state
        setNewEventStart(selectedEvent.start);
        setNewEventEnd(selectedEvent.end);
        setStartTime(selectedEvent.startTime);
        setEndTime(selectedEvent.endTime);
        setOvernight(selectedEvent.overnight);
        setPrice(selectedEvent.price);
        setGender(selectedEvent.gender);
        // setSelectedDay(dayOfWeek);
        console.log(selectedEvent, 'sselecklfjdslk ')
        setSessionType(selectedEvent.sessionType);
  
        // Show modal with pre-filled values
        setModalVisible(true);
      } else {
        console.log('Event not found in the array');
      }
    } else {
      console.log('No event ID selected');
    }
    closeContextMenu();
  };
  

  const handleDelete = () => {
    if (selectedEventId) {
      deleteEvent(selectedEventId)
    }
    closeContextMenu()
  }

  const closeContextMenu = () => {
    setContextMenuVisible(false)
    setSelectedEventId(null)
  }

  const onBeforeEventRender = (args: any) => {
    const now = new DayPilot.Date()
    args.data.backColor = '#A2C837'
    args.data.barColor = '#A2C837'
    args.data.borderColor = '#A2C837'
    
    args.data.html = `
    <div class="d-flex align-content-center justify-content-between">
      <span>${args.data.text}</span>
      <button class="btn edit-delete-btn" style="float: right; padding: 0; width: 15px !important; height: 15px !important; border-radius: 50%; border: 1px solid #fff; display: flex; align-items: center; justify-content: end; margin: 5px; color: #FFF; font-size: 10px;" data-id="${args.data.id}">
        <i class="fa-solid fa-chevron-down" style="font-size: 8px; color: #FFF;"></i>
      </button>
      
    </div>
  `
  }

  const customDayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const onBeforeHeaderRender = (args: any) => {
    const dayIndex = args.header.start.getDayOfWeek()
    const dayName = customDayNames[dayIndex]

    args.header.html = `
      <div style="text-align: center;">
        <div>${dayName}</div>
      </div>
    `
  }

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      const button = target.closest('.edit-delete-btn')
      if (button) {
        const eventId = button.getAttribute('data-id')
        if (eventId) {
          handleEditDeleteButtonClick(event as unknown as React.MouseEvent, eventId)
        }
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <div>
      <div>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <div>
            <DayPilotCalendar
              startDate={startDate}
              days={7}
              weekStarts={0} // 0 corresponds to Sunday
              events={events}
              headerDateFormat='ddd'
              onTimeRangeSelected={handleCellClick} // Correct event handler for cell clicks
              onBeforeCellRender={onBeforeCellRender}
              onBeforeEventRender={onBeforeEventRender}
              onBeforeHeaderRender={onBeforeHeaderRender} // Custom day names
              headerHeight={50}
            />

            {contextMenuVisible && (
              <ContextMenuWeekly
                x={contextMenuPosition.x}
                y={contextMenuPosition.y}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onClose={closeContextMenu}
              />
            )}
          </div>
        )}
      </div>

      {modalVisible && (
        <div className='modal show d-block' tabIndex={-1} role='dialog'>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header '>
                {selectedEventId ? 'Edit Event' : 'Create New Event'}
                <button type='button' className='close btn' aria-label='Close' onClick={closeModal}>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='d-flex gap-5'>
                  <div className='form-group mb-3'>
                    <label className='mb-3'>Start Time</label>
                    <CustomTimePickerWeek value={startTime} onChange={setStartTime} />
                  </div>
                  <div className='form-group mb-3'>
                    <label className='mb-3'>End Time</label>
                    <CustomTimePickerWeek value={endTime} onChange={setEndTime} />
                  </div>
                </div>
                <p>End time must be greater than start time</p>
                <div className='form-group mb-3'>
                  <label className='mb-3'>Overnight</label>
                  <br />
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='overnight'
                      value='yes'
                      checked={overnight === 'yes'}
                      onChange={() => setOvernight('yes')}
                    />
                    <label className='form-check-label'>Yes</label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='overnight'
                      value='no'
                      checked={overnight === 'no'}
                      onChange={() => setOvernight('no')}
                    />
                    <label className='form-check-label'>No</label>
                  </div>
                </div>
                <div className='input-group form-control p-0 my-4 align-items-center'>
                  <span className='bg-light btn'>Price</span>
                  <input
                    type='number'
                    className='form-control border-0'
                    value={price}
                    onChange={(e) => setPrice(Number(e.target.value))}
                  />
                  <span className='bg-light btn'>USD</span>
                </div>
                {/* <div className='form-group mb-3'>
                  <label className='mb-3'>Gender</label>
                  <br />
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='gender'
                      value='male'
                      checked={gender === 'male'}
                      onChange={() => setGender('male')}
                    />
                    <label className='form-check-label'>Male</label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='gender'
                      value='female'
                      checked={gender === 'female'}
                      onChange={() => setGender('female')}
                    />
                    <label className='form-check-label'>Female</label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='gender'
                      value='both'
                      checked={gender === 'both'}
                      onChange={() => setGender('both')}
                    />
                    <label className='form-check-label'>Both</label>
                  </div>
                </div> */}
                                <div className='form-group mb-3'>
  <label className='mb-3'>Gender</label>
  <br />
  {unitDataGender === 'Male' || unitDataGender === 'Both' ? (
    <div className='form-check form-check-inline'>
      <input
        className='form-check-input'
        type='radio'
        name='gender'
        value='male'
        checked={gender === 'male'}
        onChange={() => setGender('male')}
      />
      <label className='form-check-label'>Male</label>
    </div>
  ) : null}
  {unitDataGender === 'Female' || unitDataGender === 'Both' ? (
    <div className='form-check form-check-inline'>
      <input
        className='form-check-input'
        type='radio'
        name='gender'
        value='female'
        checked={gender === 'female'}
        onChange={() => setGender('female')}
      />
      <label className='form-check-label'>Female</label>
    </div>
  ) : null}
  {unitDataGender === 'Both' && (
    <div className='form-check form-check-inline'>
      <input
        className='form-check-input'
        type='radio'
        name='gender'
        value='both'
        checked={gender === 'both'}
        onChange={() => setGender('both')}
      />
      <label className='form-check-label'>Male & Female</label>
    </div>
  )}
</div>
              </div>
              <div className='modal-footer justify-content-between'>
                <button type='button' className='btn btn-secondary' onClick={closeModal}>
                  Close
                </button>
                <button type='button' className='btn btn-dark' onClick={handleEventCreation}>
                  Create Event
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CalenderWeekly
