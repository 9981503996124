import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast'

interface Customer {
  id: number
  name: string
  vie_id: string
  image: string | null
  gender: string
  age: string
  booking: {
    cancelled: number
    coming: number
    past: number
  }
  event: {
    cancelled: number
    coming: number
    past: number
  }
  blocked_from_booking: number
  blocked_from_event: number
}

const Customers: FC = () => {
  const intl = useIntl()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [customers, setCustomers] = useState<Customer[]>([])
  const [page, setPage] = useState<number>(1) // For pagination
  const [totalPages, setTotalPages] = useState<number>(1) // Total pages available from API
  const apiUrl = process.env.REACT_APP_API_URL

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/customers/high-five`, {
        params: {
          search: searchTerm,
          page, // Adding pagination to the request
        },
      })
      const { data } = response.data
      setCustomers(data.items)
      setTotalPages(data.total_pages || 1) // Assuming the API returns total_pages
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(`Error: ${error.response.data.message}`)
      } else {
        toast.error('Failed to load customers')
      }
    }
  }

  useEffect(() => {
    fetchCustomers() // Only call on page change
  }, [page]) // Re-fetch data only when the page changes

  const handleSearch = () => {
    setPage(1) // Reset to the first page when searching
    fetchCustomers() // Fetch customers when the search button is clicked
  }

  const handleToggleBooking = async (customer: Customer) => {
    try {
      await axios.post(`${apiUrl}/customers/high-five/${customer.id}/block`, {
        for: 'booking',
      })
      toast.success('Booking status updated successfully')
      fetchCustomers()
    } catch (error) {
      toast.error('Failed to update booking status')
    }
  }

  const handleToggleEvent = async (customer: Customer) => {
    try {
      await axios.post(`${apiUrl}/customers/high-five/${customer.id}/block`, {
        for: 'event',
      })
      toast.success('Event status updated successfully')
      fetchCustomers()
    } catch (error) {
      toast.error('Failed to update event status')
    }
  }

  return (
    <>
      <div className='pt-5'>
        <h1>WALK-IN CUSTOMERS</h1>
        <Toaster />
        <Breadcrumb secondItem=' Page' />
        <div className='col-lg-6'>
          <div className='input-group border rounded p-2 bg-white mb-5'>
            <input
              type='search'
              className='form-control bg-transparent border-0'
              placeholder='Search By user or username  ...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className='input-group-prepend'>
              <span className='input-group-text bg-transparent border-0 p-0' id='basic-addon1'>
                <button className='btn btn-dark' onClick={handleSearch}>
                  Search
                </button>
              </span>
            </div>
          </div>
        </div>

        <div className=''>
          <div className='card'>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted bg-dark'>
                      <th className='rounded-start'>#</th>
                      <th className=''>User</th>
                      <th className=''> Gender - Age</th>
                      <th className=''>
                        Booking
                        <div className='d-flex justify-content-center gap-5'>
                          <span className='text-white fw-bold  d-block mb-1 fs-6'>Coming </span>
                          <span className='text-white fw-bold  d-block mb-1 fs-6'>Past </span>
                          <span className='text-white fw-bold  d-block mb-1 fs-6'>Cancelled </span>
                        </div>
                      </th>
                      <th className=''>
                      Public Event
                        <div className='d-flex justify-content-center gap-5'>
                          <span className='text-white fw-bold  d-block mb-1 fs-6'>Coming </span>
                          <span className='text-white fw-bold  d-block mb-1 fs-6'>Past </span>
                          <span className='text-white fw-bold  d-block mb-1 fs-6'>Cancelled </span>
                        </div>
                      </th>
                      <th className=''> Block Booking	</th>

                      <th className='rounded-end'>Block Public Events</th>

                    </tr>
                  </thead>
                  <tbody>
                    {customers.map((customer, index) => (
                      <tr key={customer.id}>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {index + 1}
                          </span>
                        </td>
                        <td className='text-start'>
                          <div className='d-flex gap-3 align-items-center'>
                            <img
                              alt='Logo'
                              src={customer.image || '/media/logos/default-avatar.png'}
                              className='h-50px w-50px rounded-circle border'
                            />
                            <div>
                              <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                {customer.name}
                              </span>
                              <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                                @{customer.vie_id}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {customer.gender} - {customer.age}
                          </span>
                        </td>
                        <td>
                          <div className='d-flex justify-content-center gap-5'>
                            <span className='fw-bold orang_circle fs-6'>
                              {customer.booking.coming}
                            </span>
                            <span className='fw-bold orang_circle fs-6'>
                              {customer.booking.past}
                            </span>
                            <span className='fw-bold orang_circle fs-6'>
                              {customer.booking.cancelled}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-center gap-5'>
                            <span className='fw-bold orang_circle fs-6'>
                              {customer.event.coming}
                            </span>
                            <span className='fw-bold orang_circle fs-6'>
                              {customer.event.past}
                            </span>
                            <span className='fw-bold orang_circle fs-6'>
                              {customer.event.cancelled}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='form-check form-switch form-check-custom form-check-solid justify-content-center'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={customer.blocked_from_booking === 1}
                              onChange={() => handleToggleBooking(customer)}
                            />
                            <label className='form-check-label'>Block Booking</label>
                          </div>
                    
                        </td>
                        <td>      <div className='form-check form-switch form-check-custom form-check-solid justify-content-center'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={customer.blocked_from_event === 1}
                              onChange={() => handleToggleEvent(customer)}
                            />
                            <label className='form-check-label'>Block Event</label>
                          </div></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Pagination */}
          <div className='d-flex justify-content-between mt-4'>
            <button
              className='btn btn-primary'
              onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
              disabled={page === 1}
            >
              Previous
            </button>
            <span className='align-self-center'>
              Page {page} of {totalPages}
            </span>
            <button
              className='btn btn-primary'
              onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Customers
