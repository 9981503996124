// src/services/branchesService.ts

import axios from 'axios';
import { setupAxios } from '../modules/auth/core/AuthHelpers';
import toast, { Toaster } from 'react-hot-toast';

const API_URL = process.env.REACT_APP_API_URL;

// Initialize Axios with auth setup
setupAxios(axios);

// export const fetchBranches = async () => {
//   try {
//     const response = await axios.get(`${API_URL}/branches`);
//     console.log(response.data.data.items, 'branches')
//     return response.data.data.items; // Assuming the branches are returned directly in the response data
    
//   } catch (error) {
//     console.error('Error fetching branches:', error);
//     throw error;
//   }
// };
export const fetchBranches = async (page = 1) => {
  try {
    const response = await axios.get(`${API_URL}/branches`, {
      params: { page }
    });
    
    return {
      branches: response.data.data.items,
      paginate: response.data.data.paginate,
    };
  } catch (error) {
    console.error('Error fetching branches:', error);
    throw error;
  }
};
export const fetchCountries = async () => {
  const response = await axios.get(`${API_URL}/general/countries`);
  return response.data.data;
};

// Fetch states by country
export const fetchStates = async (countryCode: string) => {
  const response = await axios.get(`${API_URL}/general/states/${countryCode}`);
  return response.data.data;
};

// Fetch cities by state
export const fetchCities = async (stateId: string) => {
  const response = await axios.get(`${API_URL}/general/cities/${stateId}`);
  return response.data.data;
};

// Add a new branch
export const addBranch = async (payload: any) => {
  const response = await axios.post(`${API_URL}/branches`, payload).then(response =>{

    // toast.success('Branch added successfully!');

    return response.data.data;
  });
}

