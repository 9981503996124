import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import toast, {Toaster} from 'react-hot-toast'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('This field is required.'),
})

export function ForgotPasswordMail() {
  const navigate = useNavigate() // Use useNavigate instead of useHistory
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      requestPassword(values.email)
        .then(({data: {result}}) => {
          setHasErrors(false)
          setLoading(false)
          const otpUrl = `/auth/forgot-password-otp?email=${encodeURIComponent(values.email)}`
          navigate(otpUrl) // Use navigate for redirection
        })
        .catch((error) => {
          let errorMessage = 'Failed to verify OTP. Please try again.'

          if (error instanceof Error) {
          }

          if (typeof error === 'object' && error !== null && 'response' in error) {
            const err = error as {response: {data: {message: string}}}
            errorMessage = err.response.data.message || errorMessage
          }

          toast.error(errorMessage)
          setLoading(false)
          setSubmitting(false)
        })
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <Toaster />
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>

        <div className='text-gray-500 fw-semibold fs-6'>
          Enter your email to reset your password.
        </div>
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {'is-valid': formik.touched.email && !formik.errors.email}
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
{/* 
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-dark w-100'>
          <span className='indicator-label'>Next</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div> */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
  <button
    type='submit'
    id='kt_password_reset_submit'
    className='btn btn-dark w-100'
    disabled={loading} // Disable button while loading to prevent multiple submits
  >
    {!loading && <span className='indicator-label'>Next</span>}
    {loading && (
      <span className='indicator-label'>
        Please wait...
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
    )}
  </button>
</div>


      <div className='separator separator-content my-14'>
        <p className='text-gray-500 fw-semibold fs-7'>OR</p>
      </div>

      <div className='d-md-flex justify-content-between'>
        <p className='text-gray-500 fw-semibold fs-7'>
          <Link to='/auth/login' className='link-dark'>
            Back to Login
          </Link>
        </p>
        <p className='text-gray-500 fw-semibold fs-7'>
          <Link to='/auth/forgot-password-phone' className='link-dark'>
            Use Phone Number
          </Link>
        </p>
      </div>
    </form>
  )
}
