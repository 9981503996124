import {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_high-five/helpers'
import axios from 'axios'
import toast, {Toaster} from 'react-hot-toast'
import Select, {MultiValue, ActionMeta} from 'react-select'

// interface Category {
//   id: number
//   name: string
//   fields_count: number
//   active_memberships_count: number
// }
interface Category {
  id: number;
  name: string;
  fields_count: number;
  active_memberships_count: number;
  units: number[]; // Assuming units is an array of field IDs
}


interface Option {
  value: number
  label: string
}
interface Unit {
  id: number
  name: string
}

const MembersCategory: FC = () => {
  const [categories, setCategories] = useState<Category[]>([])
  const [options, setOptions] = useState<Option[]>([]) // State to store options for Select
  const [selectedunits, setSelectedunits] = useState<Option[]>([])
  const [newCategoryName, setNewCategoryName] = useState('')
  const [categoryIdToDelete, setCategoryIdToDelete] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)
  const [categoryIdToUpdate, setCategoryIdToUpdate] = useState<number | null>(null)

  const intl = useIntl()
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const {data} = await axios.get(`${apiUrl}/memberships/categories`)
        if (data.success) {
          setCategories(data.data.categories)
        }
      } catch (error) {
        toast.error('Error fetching categories.')
      }
    }

    const fetchUnits = async () => {
      setLoading(true)
      try {
        const {data} = await axios.get(`${apiUrl}/units`)
        console.log(data, 'TEEEEE') // Log the response to ensure correct structure

        if (data.success) {
          const unitOptions = data.data.map((unit: Unit) => ({
            value: unit.id,
            label: unit.name,
          }))
          setOptions(unitOptions)
        } else {
          toast.error('No units available.')
        }
      } catch (error) {
        console.error('Error fetching units:', error)
        toast.error('Error fetching units.')
      } finally {
        setLoading(false)
      }
    }

    fetchCategories()
    fetchUnits()
  }, [apiUrl])

  const handleSelectChange = (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
    // Ensure that newValue is an array of options
    setSelectedunits(newValue as Option[])
  }

  const handleCreate = async () => {
    if (!newCategoryName || selectedunits.length === 0) return

    try {
      const {data} = await axios.post(`${apiUrl}/memberships/categories`, {
        name: newCategoryName,
        units: selectedunits.map((field) => field.value),
      })

      if (data.success) {
        const {data: fetchData} = await axios.get(`${apiUrl}/memberships/categories`)
        if (fetchData.success) {
          setCategories(fetchData.data.categories)
        }

        setNewCategoryName('')
        setSelectedunits([])
        toast.success('Category created successfully')
      }
    } catch (error) {
      toast.error('Error creating category.')
    } finally {
      // const modalElement = document.querySelector('#kt_modal_3') as HTMLElement;
      // if (modalElement) {
      //   const bsModal = new (window as any).bootstrap.Modal(modalElement);
      //   bsModal.hide();
      // }
    }
  }

  const handleDelete = async () => {
    if (categoryIdToDelete === null) return

    try {
      const {data} = await axios.delete(`${apiUrl}/memberships/categories/${categoryIdToDelete}`)
      if (data.success) {
        setCategories(categories.filter((category) => category.id !== categoryIdToDelete))
        toast.success('Category deleted successfully')
      }
    } catch (error) {
      toast.error('Error deleting category.')
    } finally {
      setCategoryIdToDelete(null)
    }
  }
  const handleUpdate = async () => {
    // if (!newCategoryName || selectedunits.length === 0 || categoryIdToUpdate === null) return

    try {
      const {data} = await axios.post(`${apiUrl}/memberships/categories/${categoryIdToUpdate}`, {
        name: newCategoryName,
        units: selectedunits.map((field) => field.value),
        _method: 'put',
      })

      if (data.success) {
        const {data: fetchData} = await axios.get(`${apiUrl}/memberships/categories`)
        if (fetchData.success) {
          setCategories(fetchData.data.categories)
        }

        setNewCategoryName('')
        setSelectedunits([])
        toast.success('Category updated successfully')
      }
    }catch (error: unknown) {
      let errorMessage = 'There was an error updating the contact. Please try again.'

      if (error instanceof Error) {
        console.error('Error updating contact:', error.message)
      }

      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as { response: { data: { message: string } } }
        errorMessage = err.response.data.message || errorMessage
      }

      console.error('Error updating contact:', errorMessage)
      toast.error(errorMessage)
    } finally {
      setCategoryIdToUpdate(null)
    }
  }

  const handleEdit = (category: Category) => {
    setCategoryIdToUpdate(category.id);
    setNewCategoryName(category.name);
    console.log(category, 'category')
    // Check if `category.units` exists and is an array before filtering
    const selectedUnits = options.filter(
      (option) => Array.isArray(category.units) && category.units.includes(option.value)
    );
    
    setSelectedunits(selectedUnits);
  };
  
  

  

  return (
    <>
      <div className='pt-5'>
        <Toaster />
        <h1>Category Management</h1>
        <Breadcrumb secondItem=' Page' />

        <div className=''>
          <div className='card'>
            <div className='card-header border-0 justify-content-end gap-5 pt-5'>
              <button
                className='btn btn-dark h-45px'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_3'
              >
                <KTIcon iconName='plus' className='fs-2' />
                Create Category
              </button>
            </div>
            <div className='text-center'></div>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted bg-dark'>
                      <th className='rounded-start'>#</th>
                      <th>Name</th>
                      <th className=''>No. of units</th>
                      <th className=''>No. of active memberships</th>
                      <th className='rounded-end'>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {categories.map((category, index) => (
                      <tr key={category.id}>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {index + 1}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {category.name}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {category.fields_count}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {category.active_memberships_count}
                          </span>
                        </td>
                        <td className='d-flex gap-4 justify-content-center'>
                          <button
                            onClick={() => handleEdit(category)}
                            className='d-flex align-items-center btn-dark btn'
                                   data-bs-toggle='modal'
                            data-bs-target='#kt_modal_4'
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => setCategoryIdToDelete(category.id)}
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_2'
                            className='btn btn-bg-light me-1'
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Delete Confirmation Modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>Confirmation Message</h5>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      x
                    </div>
                  </div>
                  <div className='modal-body'>
                    <p>Are you sure that you want to delete this category?</p>
                    <p>Deleting category will end all active memberships under this category.</p>
                  </div>
                  <div className='modal-footer justify-content-between'>
                    <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      Close
                    </button>
                    <button
                      type='button'
                      className='btn btn-dark'
                      onClick={handleDelete}
                      data-bs-dismiss='modal'
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Create Category Modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_3'>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>New Category Management</h5>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      x
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='mb-10'>
                      <label className='form-label'>Name</label>
                      <input
                        type='text'
                        className='form-control border-b border'
                        placeholder='Enter Category Name'
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label'>units</label>
                      <Select
                        className='react-select-styled border mb-3 rounded'
                        classNamePrefix='react-select'
                        options={options}
                        isMulti
                        value={selectedunits}
                        onChange={handleSelectChange}
                      />
                      <small>
                        Please select units you would like to enroll under this category:
                      </small>
                    </div>
                  </div>
                  <div className='modal-footer justify-content-between'>
                    <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      Close
                    </button>
                    <button
                      type='button'
                      className='btn btn-dark'
                      onClick={handleCreate}
                      data-bs-dismiss='modal'
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Update Category Modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_4'>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>Update Category Management</h5>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      x
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='mb-10'>
                      <label className='form-label'>Name</label>
                      <input
                        type='text'
                        className='form-control border-b border'
                        placeholder='Enter Category Name'
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label'>units</label>
                      <Select
                        className='react-select-styled border mb-3 rounded'
                        classNamePrefix='react-select'
                        options={options}
                        isMulti
                        value={selectedunits}
                        onChange={handleSelectChange}
                        
                      />
                      
                      <small>
                        Please select units you would like to enroll under this category:
                      </small>
                    </div>
                  </div>
                  <div className='modal-footer justify-content-between'>
                    <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      Close
                    </button>
                    <button
                      type='button'
                      className='btn btn-dark'
                      onClick={handleUpdate}
                   data-bs-dismiss='modal'
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {MembersCategory}
