import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_high-five/assets/ts/components'
import {useAuth} from '../core/Auth'
import axios from 'axios' // Ensure axios is imported
import toast, {Toaster} from 'react-hot-toast'

const initialValues = {
  password: '',
  password_confirmation: '',
}

const registrationMSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const location = useLocation()
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()
  const state = location.state as {
    data: {email?: string; phone?: string; phone_country?: string; code: string}
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationMSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const {password} = values

      try {
        const requestData = {
          ...state.data,
          password,
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/auth/update-password`, requestData)
    // Show success toast
    toast.success('Password updated successfully!');
        navigate('/auth/login')
      } catch (error: unknown) {
        let errorMessage = 'There was an error deleting the event. Please try again.'

        if (error instanceof Error) {
        }

        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        toast.error(errorMessage)
        setLoading(false)
        setSubmitting(false)
      } finally {
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <Toaster/>
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Forget my password</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Set up new password</div>
      </div>

      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='text-muted'>
          <small>          Use 8 or more characters with a mix of letters, numbers & symbols.
          </small>
        </div>
      </div>

      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('password_confirmation')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid':
                formik.touched.password_confirmation && formik.errors.password_confirmation,
            },
            {
              'is-valid':
                formik.touched.password_confirmation && !formik.errors.password_confirmation,
            }
          )}
        />
        {formik.touched.password_confirmation && formik.errors.password_confirmation && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password_confirmation}</span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-dark w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {error && <div className='text-danger text-center'>{error}</div>}
      </div>
      <div className='separator separator-content my-14'>
        <p className='text-gray-500 fw-semibold fs-7'>OR</p>
      </div>
      <p className='text-gray-500 fw-semibold fs-7 text-center'>
        <Link to='/auth/login' className='link-dark'>
          Back to Login
        </Link>
      </p>
    </form>
  )
}
