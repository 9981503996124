import { FC, useEffect, useState } from 'react'
import axios from 'axios'
import { KTSVG } from '../../../../../../src/_high-five/helpers'
import toast, { Toaster } from 'react-hot-toast'

interface PaymentData {
  public_event: {
    allow_online: boolean
    payment_method: string
  }
  booking: {
    allow_online: boolean
    payment_method: string
  }
}

const PaymentSettings: FC = () => {
  const [paymentData, setPaymentData] = useState<PaymentData>({
    public_event: { allow_online: false, payment_method: '' },
    booking: { allow_online: false, payment_method: '' },
  })
  const apiUrl = process.env.REACT_APP_API_URL

  // Fetch the payment method data from the backend
  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/payment-method`)
        if (response.data.success) {
          setPaymentData(response.data.data)
        }
      } catch (error) {
        console.error('Error fetching payment data:', error)
        toast.error('Failed to load payment data.')
      }
    }

    fetchPaymentData()
  }, [])

  // Handle radio button change
  const handlePaymentMethodChange = (section: 'public_event' | 'booking', method: string) => {
    setPaymentData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        payment_method: method,
      },
    }))
  }

  // Handle update request
  const handleUpdate = async () => {
    try {
      const postData = {
        public_event_payment_method: paymentData.public_event.payment_method,
        booking_payment_method: paymentData.booking.payment_method,
      }

      const response = await axios.post(`${apiUrl}/payment-method`, postData)

      if (response.data.success) {
        toast.success('Payment method updated successfully!')
      } else {
        toast.error('Failed to update payment method.')
      }
    } catch (error) {
      console.error('Error updating payment method:', error)
      toast.error('Error occurred while updating payment method.')
    }
  }

  return (
    <div className='pt-5'>
      {/* Toaster for notifications */}
      <Toaster />

      <div className='bredcrumb_card mb-10'>
        <h1>PAYMENT METHODS</h1>
        <ol className='breadcrumb breadcrumb-dot text-muted fs-6 fw-bold'>
          <li className='breadcrumb-item pe-3'>
            <a href='#' className='pe-3 d-flex align-items-center'>
              <KTSVG path='media/icons/duotune/general/gen001.svg' className='svg-icon-muted me-2' />
              Dashboard
            </a>
          </li>
          <li className='breadcrumb-item px-3 text-muted'>Display Payment method</li>
        </ol>
      </div>

      <div className='mt-10 shadow-sm rounded p-10'>
        <h2>Payment methods</h2>

        {/* Bookings Section */}
        <div className='d-flex gap-20 mt-10 pt-5 border-top mb-10'>
          <div>Bookings :</div>
          <div>
            <div className='mb-10'>
              <div className='form-check form-check-custom form-check-solid form-check-sm align-items-start'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='flexRadioCashBooking'
                  name='flexRadioBooking'
                  checked={paymentData.booking.payment_method === 'cash'}
                  onChange={() => handlePaymentMethodChange('booking', 'cash')}
                />
                <label className='form-check-label'></label>
                <div className='d-grid'>
                  <p className='mb-0'>Cash</p>
                  <span className='text-muted'>Does not require online payment agreement</span>
                </div>
              </div>
            </div>

            <div className='mb-10'>
              <div className='form-check form-check-custom form-check-solid form-check-sm align-items-start'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='flexRadioOnlineBooking'
                  name='flexRadioBooking'
                  checked={paymentData.booking.payment_method === 'online'}
                  onChange={() => handlePaymentMethodChange('booking', 'online')}
                />
                <label className='form-check-label'></label>
                <div className='d-grid'>
                  <p className='mb-0'>Online</p>
                  <span className='text-muted'>Requires online payment agreement</span>
                </div>
              </div>
            </div>

            <div className='mb-10'>
              <div className='form-check form-check-custom form-check-solid form-check-sm align-items-start'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='flexRadioCashOnlineBooking'
                  name='flexRadioBooking'
                  checked={paymentData.booking.payment_method === 'both'}
                  onChange={() => handlePaymentMethodChange('booking', 'both')}
                />
                <label className='form-check-label'></label>
                <div className='d-grid'>
                  <p className='mb-0'>Cash & Online</p>
                  <span className='text-muted'>Requires online payment agreement</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Public Event Section */}
        <div className='d-flex gap-20 mt-10 pt-5 border-top mb-10'>
          <div>Public Event :</div>
          <div>
            <div className='mb-10'>
              <div className='form-check form-check-custom form-check-solid form-check-sm align-items-start'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='flexRadioCashPublic'
                  name='flexRadioPublic'
                  checked={paymentData.public_event.payment_method === 'cash'}
                  onChange={() => handlePaymentMethodChange('public_event', 'cash')}
                />
                <label className='form-check-label'></label>
                <div className='d-grid'>
                  <p className='mb-0'>Cash</p>
                  <span className='text-muted'>Does not require online payment agreement</span>
                </div>
              </div>
            </div>

            <div className='mb-10'>
              <div className='form-check form-check-custom form-check-solid form-check-sm align-items-start'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='flexRadioOnlinePublic'
                  name='flexRadioPublic'
                  checked={paymentData.public_event.payment_method === 'online'}
                  onChange={() => handlePaymentMethodChange('public_event', 'online')}
                />
                <label className='form-check-label'></label>
                <div className='d-grid'>
                  <p className='mb-0'>Online</p>
                  <span className='text-muted'>Requires online payment agreement</span>
                </div>
              </div>
            </div>

            <div className='mb-10'>
              <div className='form-check form-check-custom form-check-solid form-check-sm align-items-start'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='flexRadioCashOnlinePublic'
                  name='flexRadioPublic'
                  checked={paymentData.public_event.payment_method === 'both'}
                  onChange={() => handlePaymentMethodChange('public_event', 'both')}
                />
                <label className='form-check-label'></label>
                <div className='d-grid'>
                  <p className='mb-0'>Cash & Online</p>
                  <span className='text-muted'>Requires online payment agreement</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='text-muted mt-10 pt-5 border-top mb-10'>
          In order to activate online payment for your company, you have to complete an online payment agreement.
        </div>

        <div className='d-flex justify-content-end mt-10 pt-5 border-top mb-10'>
          <button className='btn btn-dark' onClick={handleUpdate}>Update</button>
        </div>
      </div>
    </div>
  )
}

export { PaymentSettings }
