import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb';

interface Player {
  id: number;
  name: string;
  vie_id: string;
  image: string;
}

interface Membership {
  id: number;
  player: Player;
  status: string;
  category_id: number;
  category_name: string;
  length: string;
  expired_at: string;
  reference_number: string | null;
}

interface MembershipData {
  can_manage_membership: boolean;
  memberships: Membership[];
}

interface Category {
  id: number;
  name: string;
}

const MembersExpired: FC = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [memberships, setMemberships] = useState<Membership[]>([]);
  const [categories, setCategories] = useState<Category[]>([]); // New state for categories
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null); // State for selected category
  const [isLoading, setIsLoading] = useState(false);

  const fetchExpiredMemberships = async (search = '', categoryId: number | null = null) => {
    setIsLoading(true);
    try {
      const response = await axios.get<{ data: MembershipData }>(`${apiUrl}/memberships/expired`, {
        params: {
          search,
          category_id: categoryId // Pass the selected category ID
        },
      });
      setMemberships(response.data.data.memberships);
      toast.success('Data loaded successfully');
    } catch (error) {
      setMemberships([]);
      toast.error('Failed to load data');
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch memberships and categories on initial render
  useEffect(() => {
    fetchExpiredMemberships();
    const fetchCategories = async () => {
      try {
        axios.get(`${apiUrl}/memberships/categories`)
      .then((response) => {
        setCategories(response.data.data.categories);
      })
        
      } catch (error) {
        toast.error('Failed to load categories');
      }
    };
    fetchCategories();
  }, [apiUrl]);

  // Handle search and filter
  const handleSearch = () => {
    fetchExpiredMemberships(searchQuery, selectedCategory); // Call API with search and category
  };

  return (
    <div className='pt-5'>
      <h1>EXPIRED MEMBERSHIPS</h1>
      <Breadcrumb secondItem=" Page" />
      <div className="card mb-5 p-3">
        <div className='row'>
          <div className='col-lg-6'>
            <div className='input-group border rounded p-2'>
              <input
                type='search'
                className='form-control bg-transparent border-0'
                placeholder='Search By User Or Username or Reference Number'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className='input-group-prepend'>
                <span className='input-group-text bg-transparent border-0 p-0' id='basic-addon1'>
                  <button className='btn btn-dark' onClick={handleSearch}>
                    Search
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='input-group border rounded p-2'>
              <select
                className='form-select border-0'
                value={selectedCategory || ''}
                onChange={(e) => setSelectedCategory(Number(e.target.value) || null)} // Update selected category
                aria-label='Select Category'
              >
                <option value=''>All Categories</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <div className='input-group-prepend'>
                <span className='input-group-text bg-transparent border-0 p-0' id='basic-addon1'>
                  <button className='btn btn-dark' onClick={handleSearch}>
                    Filter
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className='card'>
          <div className='card-body pb-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted bg-dark'>
                    <th className='rounded-start'>#</th>
                    <th className='text-start'>User</th>
                    <th className=''>Reference Number</th>
                    <th className=''>Category</th>
                    <th className=''>Ending</th>
                    <th className='rounded-end'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {memberships.length > 0 ? (
                    memberships.map((membership, index) => (
                      <tr key={membership.id}>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {index + 1}
                          </span>
                        </td>
                        <td className='text-start'>
                          <div className='d-flex gap-3 align-items-center'>
                            <img
                              alt={membership.player.name}
                              src={membership.player.image}
                              className='h-50px w-50px rounded-circle border'
                            />
                            <div>
                              <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                {membership.player.name}
                              </span>
                              <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                                @{membership.player.vie_id}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {membership.reference_number || 'null'}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {membership.category_name}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {membership.expired_at}
                          </span>
                        </td>
                        <td className='d-flex gap-4 justify-content-center'>
                          {membership.status === 'expired' ? (
                            <Link to={`/dashboard/members/CreatMembers?player_id=${membership.id}`} className='d-flex align-items-center btn-dark btn'>
                              Renew
                            </Link>
                          ) : (
                            <div className='text-center'>
                              <span>Have an active membership</span>
                              <Link to='/' className='d-block btn btn-dark w-100px mx-auto mt-2'>
                                Edit
                              </Link>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className='text-center'>
                        No expired memberships found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { MembersExpired };