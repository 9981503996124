import {useState, useEffect} from 'react'
import axios from 'axios'
import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'

const apiUrl = process.env.REACT_APP_API_URL

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {config} = useLayout()
  
  // State to store user profile data
  const [userData, setUserData] = useState({name: '', image: ''})

  // Fetch user profile data from the /profile/show endpoint
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${apiUrl}/profile/show`)
        if (response.data.success) {
          setUserData({
            name: response.data.data.name,
            image: response.data.data.image || '/media/avatars/blank.png' // Fallback to a default image if image is empty
          })
        }
      } catch (error) {
        console.error('Error fetching profile data:', error)
      }
    }

    fetchUserProfile()
  }, [])

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <i className="far fa-bell"></i>
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {/* Dynamically display the fetched user image */}
          <img src={userData.image ? toAbsoluteUrl(userData.image) : toAbsoluteUrl('/media/avatars/300-3.jpg')} alt={userData.name} /> 
        </div>
        {/* Pass user name and image to HeaderUserMenu */}
        <HeaderUserMenu  />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
