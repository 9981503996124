import { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb';
import { KTIcon, toAbsoluteUrl } from '../../../../_high-five/helpers';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';

// Define the Category interface
interface Category {
  id: number;
  name: string;
  fields_count: number;
  active_memberships_count: number;
}

const EditMembers: FC = () => {
  const intl = useIntl();
  const { player_id } = useParams<{ player_id: string }>();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const memberId = queryParams.get('memberId'); // Get member_id from query params

  const apiUrl = process.env.REACT_APP_API_URL;
  const [referenceNumber, setReferenceNumber] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [length, setLength] = useState('open');
  const [expiredAt, setExpiredAt] = useState('');
  const [categories, setCategories] = useState<Category[]>([]); // Use Category[] for categories
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [image, setImage] = useState('');
  // Fetch categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${apiUrl}/memberships/categories`);
        setCategories(response.data.data.categories);
      } catch (error) {
        toast.error('Failed to load categories.');
      }
    };

    fetchCategories();
  }, [apiUrl]);

  // Fetch member details if memberId is present
  useEffect(() => {
    const fetchMemberDetails = async () => {
      if (memberId) {
        try {
          const response = await axios.get(`${apiUrl}/memberships/members/${memberId}`);
          const memberData = response.data.data; // Adjust based on the actual API response structure
          console.log(memberData, 'member data');
          setReferenceNumber(memberData.reference_number);
          setCategoryId(memberData.category_id);
          setLength(memberData.length);
          setName(memberData.player.name);
          setEmail(memberData.player.email);
          setImage(memberData.player.image);
          setExpiredAt(memberData.expired_at || ''); // Use empty string if expired_at is undefined
        } catch (error) {
          toast.error('Failed to load member details.');
        }
      }
    };

    fetchMemberDetails();
  }, [memberId, apiUrl]);

  const handleCreate = async () => {
    const data = {
      player_id: memberId,
      reference_number: referenceNumber,
      category_id: categoryId,
      length,
      expired_at: length === 'limited' ? expiredAt : undefined, // Only include expired_at if 'Ends on' is selected
    };

    try {
      if (memberId) {
        // Update existing member
        await axios.put(`${apiUrl}/memberships/members/${memberId}`, data);
        toast.success('Membership updated successfully!');
      } else {
        // Create new member
        await axios.post(`${apiUrl}/memberships/members`, data);
        toast.success('Membership created successfully!');
      }
    } catch (error) {
      toast.error('Failed to update membership. Please try again.');
    }
  };

  return (
    <>
      <div className='pt-5'>
        <h1>{memberId ? 'EDIT MEMBERSHIP' : 'NEW MEMBERSHIP'}</h1>
        <Breadcrumb secondItem=' Page' />

        <div className='profile_widget card mb-5 mb-xl-10'>
          <div className='card-body p-9'>
            <div className='d-flex gap-4 align-items-center'>
              <img
                alt='Logo'
                src={image ? image : toAbsoluteUrl('/media/default-avatar.png')} // Conditional rendering for the image
                className='rounded-circle h-100px w-100px'
              />
              <div className=''>
                <h3 className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                  {name}
                </h3>
                <p className='text-gray-500 text-hover-primary me-5 mb-2'>{email}</p>
              </div>
            </div>
            <div className='form_wrap mt-10'>
              <h3 className='border-bottom pb-5 mb-5'>General Information</h3>
              <div className='form-group mb-5'>
                <label className='form-label'>Reference Number</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Reference Number:'
                  value={referenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                />
              </div>

              <div className='form-group mb-5'>
                <label className='form-label'>Category *</label>
                <select
                  className='form-select'
                  aria-label='Select category'
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                >
                  <option value=''>Open this select menu</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group mb-5'>
                <label className='form-label'>Length *</label>
                <div className='d-flex gap-10 mt-3'>
                  <div className='form-check form-check-custom form-check-solid form-check-lg'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='open'
                      id='flexCheckboxOpen'
                      name='length'
                      checked={length === 'open'}
                      onChange={() => setLength('open')}
                    />
                    <label className='form-check-label' htmlFor='flexCheckboxOpen'>
                      Open
                    </label>
                  </div>
                  <div className='form-check form-check-custom form-check-solid form-check-lg'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='limited'
                      id='flexCheckboxEndsOn'
                      name='length'
                      checked={length === 'limited'}
                      onChange={() => setLength('limited')}
                    />
                    <label className='form-check-label' htmlFor='flexCheckboxEndsOn'>
                      Ends on
                    </label>
                  </div>
                </div>
                {length === 'limited' && (
                  <input
                    type='date'
                    className='form-control mt-3'
                    value={expiredAt}
                    onChange={(e) => setExpiredAt(e.target.value)}
                  />
                )}
              </div>

              <div className='border-top pt-5 d-flex gap-5'>
                <button className='btn btn-light'>Cancel</button>
                <button className='btn btn-dark' onClick={handleCreate}>
                  {memberId ? 'Update' : 'Create'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { EditMembers };