/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import toast, {Toaster} from 'react-hot-toast'

const loginSchema = Yup.object().shape({
  username: Yup.string()

    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('This field is required.'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('This field is required.'),
  acceptTerms: Yup.bool().required('remmber me ?'),
})

const initialValues = {
  username: '',
  password: '',
  acceptTerms: false,
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.username, values.password)
        saveAuth(auth['data'])
        localStorage.setItem('user', JSON.stringify(auth['data']))

        const user = getUserByToken()
        setCurrentUser(auth['data'])
            // Show success toast
    toast.success('Login successful!');


      } catch (error: unknown) {
        let errorMessage = 'There was an error  Please try again.'

        // Check if the error is of the type we expect
        if (error instanceof Error) {
          // If the error is an instance of the generic Error class, we can log its message
        }

        // Use type assertion to narrow down the type of error
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        // Log the error and show an error toast notification
        toast.error(errorMessage)
      // Ensure loading stops and submission is reset
      setLoading(false)
      setSubmitting(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <Toaster />
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3 '>Sign In</h1>
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.username && formik.errors.username},
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          type='text'
          name='username'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.username}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-lg-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8 justify-content-between align-items-center mt-10'>
        <div className='fv-row'>
          <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('acceptTerms')}
            />
            Remember me
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        <Link to='/auth/forgot-password' className='link-dark'>
          Forgot Password ?
        </Link>
      </div>

      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-dark'
          disabled={formik.isSubmitting || !formik.isValid }
        >
          {!loading && <span className='indicator-label'>Sign In</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <div className='separator separator-content my-14'>
        <p className='mb-0 text-gray-500 fw-semibold fs-7'>OR</p>
      </div>
      <p className='text-gray-500 fw-semibold fs-4 text-center'>
        Don't have an account yet ?
        <Link to='/auth/registration' className='link-dark'>
          {' '}
          Sign up
        </Link>
      </p>
    </form>
  )
}
