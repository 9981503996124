import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import axios from 'axios'
import toast, {Toaster} from 'react-hot-toast'

const initialValues = {
  phone: '',
  otp: ['', '', '', ''],
}

const validationSchema = Yup.object({
  otp: Yup.array().length(4, 'OTP must be 4 digits'),
})

interface VerifyData {
  email?: string
  phone?: string
  phone_country?: string
  code: string
}

function ForgotPasswordOtp() {
  const [activeInputIndex, setActiveInputIndex] = useState(0)
  const [countdown, setCountdown] = useState(60)
  const location = useLocation()
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [phoneCountry, setPhoneCountry] = useState('')
  const [apiError, setApiError] = useState('')
  const [apiSuccess, setApiSuccess] = useState('')
  const [loading, setLoading] = useState(false) // Ensure setLoading is defined here
  const [hasErrors, setHasErrors] = useState(false) // Define setHasErrors
  const API_URL = process.env.REACT_APP_API_URL
  const navigate = useNavigate()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const emailParam = params.get('email')
    const phoneParam = params.get('phone')
    const phoneCountryParam = params.get('phone_country')

    if (emailParam) {
      setEmail(emailParam)
    } else if (phoneParam && phoneCountryParam) {
      setPhone(phoneParam)
      setPhoneCountry(phoneCountryParam)
    }
  }, [location.search])

  useEffect(() => {
    let interval
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1)
      }, 1000)
    } else {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [countdown])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setLoading(true)
      setHasErrors(false) // Reset error state on form submit
      const code = values.otp.join('')
      let data: VerifyData = {code}

      if (email) {
        data.email = email
      } else if (phone && phoneCountry) {
        data.phone = phone
        data.phone_country = phoneCountry
      }

      axios
        .post(`${API_URL}/auth/verify-attr`, data)
        .then((response) => {
          setLoading(false)
          setApiSuccess('OTP verified successfully.')
          navigate('/auth/forgot-password-reset', {state: {data}})
        })
        .catch((error) => {
          let errorMessage = 'Failed to verify OTP. Please try again.'

          if (typeof error === 'object' && error !== null && 'response' in error) {
            const err = error as {response: {data: {message: string}}}
            errorMessage = err.response.data.message || errorMessage
          }

          toast.error(errorMessage)
          setLoading(false)
          setHasErrors(true) // Set error state if the request fails
        })
    },
  })

  const handleOtpChange = (index, value, event) => {
    if (value.length > 1) {
      return
    }

    formik.setFieldValue(`otp.${index}`, value)

    const allFieldsFilled = formik.values.otp.every((digit) => digit.trim() !== '')

    if (allFieldsFilled) {
      formik.setFieldError('otp', undefined)
    }

    if (value && index < 3) {
      setActiveInputIndex(index + 1)
    }

    handleKeyDown(index, event)
  }

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && formik.values.otp[index] === '') {
      if (index > 0) {
        formik.setFieldValue(`otp.${index}`, '')
        setActiveInputIndex(index - 1)
      } else {
        formik.setFieldValue('otp.0', '')
      }
    }
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <Toaster />
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Forget my password</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Please enter the verification code sent to{' '}
          {email || phone ? `${phoneCountry} ${phone}` : 'your contact'}
        </div>
      </div>

      <div className='fv-row mb-8'>
        <div className='d-flex gap-4'>
          {formik.values.otp.map((_, index) => (
            <input
              key={index}
              maxLength={1}
              type='text'
              placeholder=''
              className='form-control h-50px text-center shadow-sm'
              value={formik.values.otp[index]}
              onChange={(e) => handleOtpChange(index, e.target.value, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              ref={(input) => {
                if (index === activeInputIndex && input) {
                  input.focus()
                }
              }}
            />
          ))}
        </div>
        <p className='text-muted pt-3'><span className='text-danger'>Temporary tip*</span> Your OTP is 1 2 3 4</p>
        {formik.errors.otp && formik.touched.otp && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.otp}</div>
          </div>
        )}
        {apiError && (
          <div className='fv-plugins-message-container mt-4'>
            <div className='alert alert-danger'>{apiError}</div>
          </div>
        )}
        {apiSuccess && (
          <div className='fv-plugins-message-container mt-4'>
            <div className='alert alert-success'>{apiSuccess}</div>
          </div>
        )}
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-dark w-100' disabled={loading}>
          {!loading && <span>Next</span>}
          {loading && (
            <span>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {countdown > 0 && <div className='text-center mt-4'>Resend OTP in {countdown} seconds</div>}
      </div>
      <div className='separator separator-content mt-14 mb-8'>
        <span className='text-gray-500 fw-semibold fs-7'>OR</span>
      </div>
      <div className='text-center'>
        <Link to='/auth/login' className='link-primary mx-2 text-gray-500 fw-semibold fs-7'>
          Back to Login
        </Link>
      </div>
    </form>
  )
}

export {ForgotPasswordOtp}
