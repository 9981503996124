import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
  secondItem: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ secondItem }) => {
  const intl = useIntl();

  return (
    <ol className="breadcrumb breadcrumb-dot text-muted fs-6 fw-bold my-4">
      <li className="breadcrumb-item">
        <Link to="/" className="pe-3 d-flex align-items-center">
          <i className="fas fa-home me-3"></i>
          {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        </Link>
      </li>

      <li className="breadcrumb-item px-3 text-muted">{secondItem}</li>
    </ol>
  );
};

export default Breadcrumb;