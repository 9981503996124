import React, { useState } from 'react';
import './ImageUpload.css';

interface Image {
  id: number;
  file: File;
  preview: string;
}

interface MultipleImageUploadProps {
  onImagesChange: (files: File[]) => void;
  error?: string;
}

const MultipleImageUpload: React.FC<MultipleImageUploadProps> = ({ onImagesChange, error }) => {
  const [images, setImages] = useState<Image[]>([]);

  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      const newImages = filesArray.map((file) => ({
        id: Date.now() + Math.random(),
        file,
        preview: URL.createObjectURL(file),
      }));

      setImages((prevImages) => [...prevImages, ...newImages]);
      onImagesChange([...images.map((img) => img.file), ...filesArray]);
    }
  };

  const handleImageDelete = (id: number) => {
    const updatedImages = images.filter((image) => image.id !== id);
    setImages(updatedImages);
    onImagesChange(updatedImages.map((img) => img.file));
  };

  return (
    <div className="multiple-image-upload">
      <label className="custom-file-input">
        <input
          type="file"
          className="form-control"
          accept="image/*"
          multiple
          onChange={handleFilesChange}
        />
        <span className="text-muted">
          Choose images
          <i className="fa-solid fa-arrow-up-from-bracket mx-2 text-muted"></i>
        </span>
      </label>
      <div className="image-preview-list">
        {images.map((image) => (
          <div key={image.id} className="image-container">
            <img src={image.preview} alt="Preview" />
            <button className="" onClick={() => handleImageDelete(image.id)}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </button>
          </div>
        ))}
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default MultipleImageUpload;
