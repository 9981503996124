import React, { useState } from 'react';
import './ImageUpload.css';

interface SingleImageUploadProps {
  onImageChange: (file: File | null) => void;
  error?: string;
}

const SingleImageUpload: React.FC<SingleImageUploadProps> = ({ onImageChange, error }) => {
  const [image, setImage] = useState<{ id: number; preview: string } | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImage({
        id: Date.now(),
        preview: URL.createObjectURL(file),
      });
      onImageChange(file);
    } else {
      setImage(null);
      onImageChange(null);
    }
  };

  const handleImageDelete = () => {
    setImage(null);
    onImageChange(null);
  };

  return (
    <div className="">
      <label className="custom-file-input">
        <input
          type="file"
          className="form-control"
          accept="image/*"
          onChange={handleFileChange}
        />
        <span className='text-muted'>
          Choose an image
          <i className="fa-solid fa-arrow-up-from-bracket mx-2 text-muted"></i>
        </span>
      </label>
      {image && (
        <div className="image-preview">
          <div className="image-container">
            <img src={image.preview} alt="Preview" />
            <button className='' onClick={handleImageDelete}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </button>
          </div>
        </div>
      )}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default SingleImageUpload;
