import React, { FC, useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const BookingMethodology: FC = () => {
  const [booking, setBooking] = useState<any[]>([]);
  const [policies, setPolicies] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
  const [selectedPolicy, setSelectedPolicy] = useState<{ id: number; name: string } | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMethod, setSelectedMethod] = useState<string>('walk-in'); // Default to 'walk-in'
  const [isApproved, setIsApproved] = useState(false);
  const [contactsSelected, setContactsSelected] = useState(false);
  const [policySelected, setPolicySelected] = useState(false);
  const [dataOfBooking, setDataOfBooking] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;
  const { unit_id } = useParams<{ unit_id: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const unitQueryParam = queryParams.get('unit_id');

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const response = await axios.get(`${apiUrl}/units/${unitQueryParam}/booking-methodology`);
        const data = response.data.data;

        setBooking(data.items);
        
        setSelectedMethod(data.method)
        console.log(dataOfBooking, 'booking data')
        setSelectedPolicy(data.selected_policy || null);
        setContacts(data.selected_contacts || []);
        let isApproved = data.status === 'approved' ? true : false;
        console.log(isApproved ,'isApproved')
        setSelectedContacts(data.selected_contacts.map(contact=>{return contact.id}))
        setIsApproved(isApproved);
        setContactsSelected(data.selected_contacts.length > 0 ? true : false);
        console.log(contactsSelected, 'content')
        console.log(data.selected_policy?true :false, 'selected_policy')
        setPolicySelected(data.selected_policy ? true:false);
        fetchContacts();

      } catch (error) {
        console.error('Error fetching booking methodology:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchContacts = async () => {
      try {
        const response = await axios.get(`${apiUrl}/contact`)
        const contactData = response.data.data
        console.log(contactData)

        setContacts(contactData)
        console.log(contacts)

      } catch (error) {
        console.error('Error fetching contacts:', error)
      }
    }

    const fetchPolicies = async () => {
      try {
        const response = await axios.get(`${apiUrl}/policy`)
        const policyData = response.data.data
        setPolicies(policyData)
      } catch (error) {
        console.error('Error fetching policies:', error)
      }
    }
    fetchBooking()
    fetchContacts()
    fetchPolicies()
}, [unitQueryParam]);

  const handleContactChange = (contactId: number, isChecked: boolean) => {
    setSelectedContacts((prev) =>
      isChecked ? [...prev, contactId] : prev.filter((id) => id !== contactId)
    );
  };

  const handleSave = async () => {
    try {
      await axios.post(`${apiUrl}/units/${unitQueryParam}/booking-methodology`, {
        method: selectedMethod,
      });
      toast.success('Unit added successfully!', {
        duration: 4000,
      });
    } catch (error) {
      console.error('Error saving:', error);
    }
  };

  const handleSaveContacts = async () => {
    try {
      await axios.post(`${apiUrl}/units/${unitQueryParam}/contacts`, {
        contacts: [...selectedContacts],
      });
      console.log('Contacts saved successfully');
      setContactsSelected(true);
    } catch (error) {
      console.error('Error saving contacts:', error);
    }
  };

  const handleSavePolicy = async () => {
    try {
      if (selectedPolicy) {
        await axios.post(`${apiUrl}/units/${unitQueryParam}/policy`, {
          policy_id: selectedPolicy.id,
        });
        setPolicySelected(true);
        console.log('Policy saved successfully');
      } else {
        console.log('No policy selected');
      }
    } catch (error) {
      console.error('Error saving policy:', error);
    }
  };

  // Note messages based on conditions
  const renderNoteMessage = () => {
    // is working fine
    if (!isApproved) {
      return <span className='text-muted'>You can choose this option only with active units.</span>;
    }
    if (selectedMethod === 'call') {
      
      if (contactsSelected) {
        return <span className='text-muted'>Your unit will be shown in player app with call option to the linked contact that you selected. You can also make walk-in bookings.</span>;

      }else{
        return <span className='text-muted'>Select at least one contact to choose this option.</span>;
      }
    }
    if (contactsSelected) {
      return <span className='text-muted'>Your unit will be shown in player app with call option to the linked contact that you selected. You can also make walk-in bookings.</span>;

    }
    return <span className='text-muted'>Select at least one contact to choose this option.</span>;


    // return (
    //   <span className='text-muted'>
    //     Your unit will not be shown in player app, but you can make walk-in bookings.
    //   </span>
    // );
  };
  const renderNoteMessagePolicies = () => {
    if (!isApproved) {
      return <span className='text-muted'>You can choose this option only with active units.</span>;
    }


    if (selectedMethod === 'online') {
      //false
      console.log(policySelected, 'here condigtion')
      
      //true

    }
    if (policySelected === true) {
      return <span className='text-muted'>Your unit will be shown in player app with online booking option you have to select policy to be applied. You can also make walk-in bookings and linked bookings </span>;
  }
    return <span className='text-muted'>Select a policy to choose this option.</span>;

    // return (
    //   <span className='text-muted'>
    //     Your unit will not be shown in player app, but you can make walk-in bookings.
    //   </span>
    // );
  };
 
  return (
    <>
      <div className='pt-5'>
        <h1>BOOKING METHODOLOGY</h1>
        {isLoading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ height: '75vh' }}
          >
            <div className='spinner'></div>
          </div>
        ) : (
          <>
            <Breadcrumb secondItem='Page' />
            <div className='mt-10 shadow-sm rounded p-10'>
              <h2 className=''>Booking methodology settings</h2>
              <div className=''>
                <div>Please select one to be applied on your unit:</div>

                {/* Walk in only */}
                <div className='mb-10 border-bottom py-3'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm align-items-start my-4'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='walk-in'
                      id='flexRadioWalkIn'
                      name='flexRadio'
                      checked={selectedMethod === 'walk-in'}
                      
                      onChange={(e) => setSelectedMethod(e.target.value)}
                    />
                    <label className='form-check-label' htmlFor='flexRadioWalkIn'>
                      Walk in only
                    </label>
              
                  </div>
                  <div className='d-flex align-items-center '>
                      {/* <p className='mb-0'> Walk in only</p> */}
                      <span className='text-muted'>
                        Your unit will not be shown in player app but you can make walk-in bookings.
                      </span>
                    </div>
                </div>
             

                {/* Call Method */}
                <div className='mb-10 border-bottom py-3'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm align-items-start my-4'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='call'
                      id='flexRadioCall'
                      name='flexRadio'
                      checked={selectedMethod === 'call'}
                      onChange={(e) => setSelectedMethod(e.target.value)}
                      disabled={!isApproved}
                    />
                    <label className='form-check-label' htmlFor='flexRadioCall'>
                    contacts
                    </label>
                 
                  </div>
                  <div className='d-flex align-items-center  gap-5'>
                  {renderNoteMessage()}
                      <button
  disabled={!isApproved} // Button will be disabled when isApproved is false
  className='btn p-0 '
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_1'
                      >
                        Edit contacts
                      </button>
                    </div>
                </div>

                {/* Online booking */}
                <div className='mb-10'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm align-items-start my-4'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='online'
                      id='flexRadioOnline'
                      name='flexRadio'
                      checked={selectedMethod === 'online'}
                      onChange={(e) => setSelectedMethod(e.target.value)}
                      disabled={!isApproved}
                    />
                    <label className='form-check-label' htmlFor='flexRadioOnline'>
                      Online booking
                    </label>
               
                  </div>
                  <div className='d-flex align-items-center gap-5'>
                      {/* <p className='mb-0'> Online booking</p> */}
                      {renderNoteMessagePolicies()}
                      <button
                      disabled={!isApproved}
                        className='btn p-0'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_2'
                      >
                        Edit  policy
                      </button>
                    </div>
                </div>
              </div>

              {/* Render note message based on conditions */}
              {/* <div className='mt-5'>{renderNoteMessage()}</div> */}

              {/* Save Button */}
              {/* <div className='col-md-12 mt-15 d-flex justify-content-end'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  onClick={handleSave}
                >
                  Save
                </button>
              </div> */}
              
              <div className='d-flex justify-content-between mt-10 pt-5 border-top mb-10'>
                <button className='btn btn-light'>Cancel</button>
                <button className='btn btn-dark' onClick={handleSave}>
                  Save
                </button>
              </div>

              {/* Contacts Modal */}
              <div className='modal fade' id='kt_modal_1'>
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title'>Select Contacts</h5>
                      <button
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        
                      >
                        X
                      </button>
                    </div>
                    <div className='modal-body'>
                      {contacts.length > 0 ? (
                        contacts.map((contact) => (
                          <div key={contact.id} className='form-check mb-3'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              id={`contact-${contact.id}`}
                              checked={selectedContacts.includes(contact.id)}
                              onChange={(e) => handleContactChange(contact.id, e.target.checked)}
                            />
                            <label className='form-check-label' htmlFor={`contact-${contact.id}`}>
                              {contact.name}
                            </label>
                          </div>
                        ))
                      ) : (
                        <p>No contacts available.</p>
                      )}
                    </div>
                    <div className='modal-footer'>
                      <button className='btn btn-secondary' data-bs-dismiss='modal'>
                        Close
                      </button>
                      <button className='btn btn-primary' onClick={handleSaveContacts}>
                        Save Contacts
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Policies Modal */}
              <div className='modal fade' id='kt_modal_2'>
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title'>Select Policy</h5>
                      <button
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                      >
                        X
                      </button>
                    </div>
                    <div className='modal-body'>
                      {policies.length > 0 ? (
                        policies.map((policy) => (
                          <div key={policy.id} className='form-check mb-3'>
                            <input
                              type='radio'
                              className='form-check-input'
                              id={`policy-${policy.id}`}
                              checked={selectedPolicy?.id === policy.id}
                              onChange={() => setSelectedPolicy(policy)}
                            />
                            <label className='form-check-label' htmlFor={`policy-${policy.id}`}>
                              {policy.name}
                            </label>
                          </div>
                        ))
                      ) : (
                        <p>No policies available.</p>
                      )}
                    </div>
                    <div className='modal-footer'>
                      <button className='btn btn-secondary' data-bs-dismiss='modal'>
                        Close
                      </button>
                      <button className='btn btn-primary' onClick={handleSavePolicy}>
                        Save Policy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BookingMethodology;