import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { AccordionField } from './AccordionField';
import { Link, useLocation } from 'react-router-dom';

const FieldsMangement: FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const [showEditOrder, setShowEditOrder] = useState(false);
  const [orderNumbers, setOrderNumbers] = useState<number[]>([1, 2, 3]);
  const [refreshData, setRefreshData] = useState(false);  // Add this state

  const queryParams = new URLSearchParams(location.search);
  const branch = queryParams.get('branch') || '';

  const handleEditOrderClick = () => {
    setShowEditOrder(prevShowEditOrder => {
      const newShowEditOrder = !prevShowEditOrder;
      if (!newShowEditOrder) {
        console.log('Saved order numbers:', orderNumbers);
        setRefreshData(true);  // Trigger data refresh when saving order
      }
      return newShowEditOrder;
    });
  };

  const saveOrder = (newOrderNumbers: number[]) => {
    setOrderNumbers(newOrderNumbers);
  };

  const handleTabChange = () => {
    setRefreshData(true);  // Trigger data refresh when switching tabs
  };

  return (
    <>
      <div className="pt-5">
        <h1>Units Management</h1>
        <div className="card p-3">
          <div className="d-flex gap-3 justify-content-end">
            <button className='btn btn-dark' onClick={handleEditOrderClick}>
              {showEditOrder ? 'Save Order' : 'Edit Order'}
            </button>
            <Link to={`/dashboard/fields/AddNewField?branch=${branch}`} className='btn btn-dark'>
              New
            </Link>
          </div>
          <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 justify-content-center">
            <li className="nav-item">
              <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_1" onClick={handleTabChange}>
                Available
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2" onClick={handleTabChange}>
                Maintains
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade active show" id="kt_tab_pane_1" role="tabpanel">
              <AccordionField
                showEditOrder={showEditOrder}
                saveOrder={saveOrder}
                status="Available"
                branch={branch}
                refreshData={refreshData}  // Pass refreshData as a prop
                onRefreshComplete={() => setRefreshData(false)}  // Reset the refresh state
              />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
              <AccordionField
                showEditOrder={showEditOrder}
                saveOrder={saveOrder}
                status="Maintains"
                branch={branch}
                refreshData={refreshData}  // Pass refreshData as a prop
                onRefreshComplete={() => setRefreshData(false)}  // Reset the refresh state
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { FieldsMangement };


