import axios from 'axios';
import { setupAxios } from '../app/modules/auth/core/AuthHelpers'; // Import the setupAxios function

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: 'https://develop.asasware.com/api/provider',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

// Setup the Axios instance with the auth configuration
setupAxios(axiosInstance);

export default axiosInstance;
