import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import axios from 'axios'; // Import axios for API calls
import { useFormik } from 'formik';

const initialValues = {
  otp: ['', '', '', ''],
};

const validationSchema = Yup.object({
  otp: Yup.array()
    .of(Yup.string().required('OTP is required'))
    .length(4, 'OTP must be 4 digits'),
});

function RegOtbForm({ phone, phone_country }) {
  const [activeInputIndex, setActiveInputIndex] = useState(0);
  const [countdown, setCountdown] = useState(60); // 60 seconds
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // State for popup visibility
  const API_URL = process.env.REACT_APP_API_URL;

  // Function to handle OTP verification
  const verifyCode = () => {
    console.log('not calling at');
    const otpCode = formik.values.otp.join(''); // Combine the OTP array into a single string
    axios.post(`${API_URL}/auth/verify-phone`, { phone, phone_country, code: otpCode })
      .then(response => {
        // Handle success response
        console.log('OTP Verified Successfully:', response.data);
        setShowSuccessPopup(false); // Show the success popup
        // You can navigate to another page or perform further actions here
      }).catch(error => {
        // Handle error response
        console.error('OTP Verification Failed:', error.response.data);
        formik.setErrors({ otp: 'OTP verification failed. Please try again.' });
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // No need to make API call here since it's handled by verifyCode function
    },
  });

  const handleOtpChange = (index, value) => {
    if (value.length > 1) {
      return;
    }

    formik.setFieldValue(`otp.${index}`, value);

    const allFieldsFilled = formik.values.otp.every((digit) => digit.trim() !== '');

    if (allFieldsFilled) {
      formik.setFieldError('otp', undefined);
    }

    if (value && index < 3) {
      setActiveInputIndex(index + 1);
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && formik.values.otp[index] === '') {
      if (index > 0) {
        formik.setFieldValue(`otp.${index}`, '');
        setActiveInputIndex(index - 1);
      } else {
        formik.setFieldValue('otp.0', '');
      }
    }
  };

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        onSubmit={(e) => {
          e.preventDefault(); // Prevent the default form submission
          // verifyCode(); // Call the verifyCode function
        }}
      >
        <div className="text-center mb-10">
          <div className="text-gray-500 fw-semibold fs-6">
            Please enter the verification code sent to your phone number
          </div>
        </div>

        <div className="fv-row mb-8">
          <div className="d-flex gap-4">
            {formik.values.otp.map((_, index) => (
              <input
                key={index}
                type="text"
                placeholder=""
                className="form-control h-50px text-center shadow-sm"
                value={formik.values.otp[index]}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                ref={(input) => {
                  if (index === activeInputIndex && input) {
                    input.focus();
                  }
                }}
              />
            ))}
          </div>
          {formik.errors.otp && formik.touched.otp && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.otp}</div>
            </div>
          )}
        </div>

        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button type="button" className="btn btn-dark w-100" onClick={verifyCode}>
            <span className="indicator-label">Verify</span>
          </button>
          {countdown > 0 && (
            <div className="text-center mt-4">Resend OTP in {countdown} seconds</div>
          )}
        </div>
      </form>

      {/* Success Popup */}
      {showSuccessPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Verification Successful</h2>
            <p>Your phone number has been successfully verified.</p>
            <button onClick={() => setShowSuccessPopup(false)}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}

export { RegOtbForm };
