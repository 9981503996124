import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom';
import { KTIcon, toAbsoluteUrl } from '../../../../_high-five/helpers'
import axios from 'axios'
import toast from 'react-hot-toast'

const MembersRequest: FC = () => {
  const intl = useIntl()
  const location = useLocation();

  const apiUrl = process.env.REACT_APP_API_URL

  // State to store membership data
  const [memberships, setMemberships] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedMembershipId, setSelectedMembershipId] = useState<number | null>(null) // Store the selected membership ID
  const queryParams = new URLSearchParams(location.search);

  // Fetch the data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/memberships/requested`)
        if (response.data.success) {
          setMemberships(response.data.data.memberships)
        } else {
          toast.error('Failed to load memberships')
        }
      } catch (error) {
        toast.error('Error loading memberships')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [apiUrl])

  // Handle the reject button click to open the modal and set the selected membership
  const handleRejectClick = (membershipId: number) => {
    setSelectedMembershipId(membershipId)
  }

  // Handle the confirm button click in the modal
  const handleConfirmDelete = async () => {
    if (selectedMembershipId === null) return

    try {
      const response = await axios.delete(`${apiUrl}/memberships/members/${selectedMembershipId}`)
      if (response.data.success) {
        toast.success('Membership rejected successfully')
        // Update state to remove the rejected membership
        setMemberships(memberships.filter(membership => membership.id !== selectedMembershipId))
      } else {
        toast.error('Failed to reject membership')
      }
    } catch (error) {
      toast.error('Error rejecting membership')
    } finally {
      // Close the modal
      setSelectedMembershipId(null)
    }
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div className='pt-5'>
      <h1>Members Request</h1>
      <div className='card'>
        <div className='card-body pb-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-dark'>
                  <th className='rounded-start'>#</th>
                  <th className='text-start'>User</th>
                  <th className='rounded-end'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {memberships.length > 0 ? (
                  memberships.map((membership, index) => (
                    <tr key={membership.id}>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {index + 1}
                        </span>
                      </td>
                      <td className='text-start'>
                        <div className='d-flex gap-3 align-items-center'>
                          <img
                            alt='Player Avatar'
                            src={
                              membership.player.image
                                ? membership.player.image
                                : toAbsoluteUrl('/media/logos/default-avatar.png')
                            }
                            className='h-50px w-50px rounded-circle border'
                          />
                          <div className=''>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {membership.player.name}
                            </span>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                              @{membership.player.vie_id}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='d-flex gap-4 justify-content-center'>
                        <Link
                         to={`/dashboard/members/CreatMembers?player_id=${membership.player.id}`} 

                        
                        className='d-flex align-items-center btn-dark btn'>
                          Create
                        </Link>
                        <button
                          className='d-flex align-items-center btn-light btn'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_2'
                          onClick={() => handleRejectClick(membership.id)}
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3} className='text-center'>
                      No membership requests found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Message</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                x
              </div>
            </div>
            <div className='modal-body'>
              <p>Are you sure that you want to reject this request?</p>
              <p>
                Rejection will remove the request and the player will be able to send another
                request again.
              </p>
            </div>
            <div className='modal-footer justify-content-between'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-dark'
                onClick={handleConfirmDelete}
                data-bs-dismiss='modal'
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { MembersRequest }
