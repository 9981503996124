import React from 'react';
import './ContextMenu.css';
import { Link } from 'react-router-dom';
interface ContextMenuProps {
  x: number;
  y: number;
  onEdit: () => void;
  onDelete: () => void;
  onClose: () => void;
  onCreateBooking: () => void;
  onCreatePublicEvent: () => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ x, y, onEdit, onDelete, onClose, onCreateBooking, onCreatePublicEvent }) => {
  return (
    <div className="context-menu" style={{ top: y, left: x }}>
      <button className='btn float-end w-100 ' onClick={onClose}><i className="fa-solid fa-xmark"></i></button>
      <button className='btn p-2' onClick={onCreateBooking}>Create Booking</button>
      <button className='btn p-2' onClick={onCreatePublicEvent}>Create Public Event</button>
      {/* <Link to="/dashboard/schedule/Booking" className='btn d-block '>Create Booking</Link>
   
      <Link to="/dashboard/schedule/PublicEvent"  className='btn d-block '>Create Public Event</Link> */}
      <button className='btn ' onClick={onEdit}>Edit</button>

      <button className='btn ' onClick={onDelete}>Delete</button>
    </div>
  );
};

export default ContextMenu;
