/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL

const HeaderUserMenu: FC = () => {
  const {logout} = useAuth()
  const [status, setStatus] = useState(null); // State to store the status

  const [profile, setProfile] = useState({
    name: '',
    image: '',
  })

  // // Fetch profile data from /profile/show
  // useEffect(() => {
  //   const fetchProfile = async () => {
  //     try {
  //       const response = await axios.get(`${apiUrl}/profile/show`).then((response) => 
  //         { 
  //           const profileData = response.data?.data
  //           setProfile({
  //             name: profileData?.name || '',
  //             image: profileData?.image || '',
  //           })
  //           console.log(response.status ,'profile')

  //         })
       
  //     } catch (error) {
  //       console.error('Error fetching profile:', error)
  //     }
  //   }

  //   fetchProfile()
  // }, [])

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${apiUrl}/profile/show`);
        const profileData = response.data?.data;

        setProfile({
          name: profileData?.name || '',
          image: profileData?.image || '',
        });

        // setStatus(response.status); // Set the status
        console.log(response, 'profile'); // Log the status
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, []);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Profile' src={profile.image || toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
        {profile.name || 'not logged in'}
            </div>
          </div>
        </div>
      </div>

      <div className='separator'></div>

      <div className='menu-item px-5 my-1'>
        <Link to='/dashboard/CompanyProfile' className='menu-link px-5'>
          Company Profile
        </Link>
      </div>

      <div className='separator'></div>

      <div className='menu-item px-5'>
        <Link to='/dashboard/Profile' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>

      <div className='separator '></div>

      <Languages />

      <div className='separator'></div>

      <div className='menu-item px-5'>
        <Link to='/dashboard/Contact' className='menu-link px-5'>
          Contact Us
        </Link>
      </div>
      <div className='separator'></div>

      <div className='menu-item px-5'>
        <Link to='/dashboard/Terms' className='menu-link px-5'>
         Terms
        </Link>
      </div>
      <div className='separator'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
