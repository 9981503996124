import React, { FC, useCallback, useRef, useState, useEffect } from 'react';
import { useLoadScript, GoogleMap, Marker, StandaloneSearchBox, LoadScriptProps } from '@react-google-maps/api';
import toast from 'react-hot-toast';

interface LocationPickerProps {
  selectedCountry: string | null;
  selectedState: string | null;
  address: string;
  latitude: number | null;
  longitude: number | null;
  onLocationChange: (lat: number, lng: number, address: string) => void;
}

const libraries: LoadScriptProps['libraries'] = ['places'];

const LocationPicker: FC<LocationPickerProps> = ({
  selectedCountry,
  selectedState,
  address,
  latitude,
  longitude,
  onLocationChange,
}) => {
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({
    lat: latitude || 40.712776,
    lng: longitude || -74.005974,
  });
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBtbpZ_-ETvYcJP5stMqWuSacGPj9MSTag', // Replace with your actual API key
    libraries,
  });

  useEffect(() => {
    if (latitude && longitude) {
      setMapCenter({ lat: latitude, lng: longitude });
    }
  }, [latitude, longitude]);

  const normalizeString = (str: string) => {
    return str.toLowerCase().replace(/\s+/g, ' ').trim();
  };

  const handlePlaceChanged = useCallback(async () => {
    const place = searchBoxRef.current?.getPlaces()?.[0];
    if (place && place.geometry?.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const formattedAddress = place.formatted_address || '';
      onLocationChange(lat, lng, formattedAddress);

      // Reverse geocode to get country and state
      const geocoder = new google.maps.Geocoder();
      const { results } = await geocoder.geocode({ location: { lat, lng } });

      let selectedPlaceCountry: string | null = null;
      let selectedPlaceState: string | null = null;

      if (results && results.length > 0) {
        results[0].address_components.forEach((component) => {
          if (component.types.includes('country')) {
            selectedPlaceCountry = component.short_name; // ISO country code
          }
          if (component.types.includes('administrative_area_level_1')) {
            selectedPlaceState = component.long_name;
          }
        });

        const normalizedSelectedCountry = normalizeString(selectedCountry || '');
        const normalizedPlaceCountry = normalizeString(selectedPlaceCountry || '');
        const normalizedSelectedState = normalizeString(selectedState || '');
        const normalizedPlaceState = normalizeString(selectedPlaceState || '');

        if (
          (normalizedSelectedCountry && normalizedSelectedCountry !== normalizedPlaceCountry) ||
          (normalizedSelectedState && normalizedSelectedState !== normalizedPlaceState)
        ) {
          toast.error('Your location does not belong to your selected country or state!');
        }
      }
    }
  }, [onLocationChange, selectedCountry, selectedState]);

  const onSearchBoxLoad = useCallback((searchBox: google.maps.places.SearchBox) => {
    searchBoxRef.current = searchBox;
  }, []);

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (
    <div>
      <label htmlFor="" className='form-label'>Search for address :</label>
      <StandaloneSearchBox onLoad={onSearchBoxLoad} onPlacesChanged={handlePlaceChanged}>
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Search location"
          value={address}
          onChange={(e) => onLocationChange(latitude!, longitude!, e.target.value)}
        />
      </StandaloneSearchBox>

      <GoogleMap
        mapContainerStyle={{ height: '400px', width: '100%' }}
        zoom={15}
        center={mapCenter}
      >
        {latitude && longitude && <Marker position={{ lat: latitude, lng: longitude }} />}
      </GoogleMap>
    </div>
  );
};

export default LocationPicker;
