import React, {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import {Modal} from 'bootstrap'
import axiosInstance from '../../../../api/axiosInstance' // Import the axios instance
import clsx from 'clsx'
import ImageUpload from './ImageUpload'
import {RegOtbForm} from './RegOtbForm'
import toast, {Toaster} from 'react-hot-toast'
import axios from 'axios'

import {Link} from 'react-router-dom'
interface termsContent {
  id: number
  title: string
  excerpt: string | null
  body: string
  image: string | null
}

interface FileUpload {
  name: string
  file: File | null
}
interface Country {
  iso: string
  name: string
}

interface State {
  id: string
  name: string
}

interface City {
  id: string
  name: string
  documents: []
}
interface SelectOption {
  value: string
  label: string
}

interface SelectOption {
  value: string
  label: string
}
interface Document {
  display_name: string
  // Add any other properties your document object may have
  required: number
}
const finalStepSchema = Yup.object().shape({
  files: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('File name is required'),
      file: Yup.mixed().required('File is required'),
    })
  ),
  message: Yup.string().required('Message is required'),
})
const termsSchema = Yup.object().shape({
  acceptTerms: Yup.bool().oneOf([true], 'You must accept the terms and conditions'),
})

const personalInfoSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Phone number must be digits only')
    .min(10, 'Minimum 10 digits')
    .max(15, 'Maximum 15 digits')
    .required('Phone number is required'),

  email: Yup.string()
    .email('Invalid email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

const companyInfoSchema = Yup.object().shape({
  // company_name: Yup.string().required('Company Name is required'),
  // country: Yup.string().required('Country is required'),
  // state: Yup.string().required('State is required'),
  // city: Yup.string().required('City is required'),
})
function decodeHtml(html: string): string {
  const parser = new DOMParser();
  const decoded = parser.parseFromString(html, 'text/html').documentElement?.textContent;
  return decoded || '';
}
const Registration = () => {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [termsContent, setTermsContent] = useState<termsContent | null>(null)
  const [countries, setCountries] = useState<Country[]>([])

  const [selectedCountry, setSelectedCountry] = useState<string>('')
  const [selectedState, setSelectedState] = useState<string>('')
  const [states, setStates] = useState<State[]>([])
  const [cities, setCities] = useState<City[]>([])
  const [documents, setDocuments] = useState<Document[]>([])
  const [personalInformations, setPersonalInformations] = useState<{}>({})
  const [companieslInformations, setCompaniesInformations] = useState<{}>({})
  const [errors, setErrors] = useState<Record<string, string[]>>({})
  const [isModalVisible, setIsModalVisible] = useState(false)
  const API_URL = process.env.REACT_APP_API_URL
  

  const navigate = useNavigate()

  useEffect(() => {
    // Fetch the terms from the API using the global axios instance
    const fetchTerms = async () => {
      try {
        const response = await axiosInstance.get('/general/pages/partner-terms-and-conditions')
        setTermsContent(response.data.data)
        console.log(response.data.data , 'terms content')
      } catch (error) {
        console.error('Error fetching terms:', error)
      }
    }
    // Fetch countries from the API
    const fetchCountries = async () => {
      try {
        const response = await axiosInstance.get<{data: Country[]}>('general/countries')
        setCountries(response.data.data)
        // console.log(response.data.data);
      } catch (error) {
        console.error('Error fetching countries:', error)
      }
    }

    fetchCountries()

    fetchTerms()
  }, [])
  function fetchStates(countryIso: string) {
    console.log('response', countryIso)

    try {
      const response = axiosInstance.get(`general/states/${countryIso}`).then((response) => {
        console.log(response.data.data)
        setStates(response.data.data)
      })
      setSelectedState('') // Clear state and city selection when country changes
      setCities([])
    } catch (error) {
      console.error('Error fetching states:', error)
    }
  }
  function fetchCities(statId: string) {
    console.log('response', statId)

    try {
      const response = axiosInstance.get(`general/cities/${statId}`).then((response) => {
        console.log(response.data.data)
        setCities(response.data.data)
      })
    } catch (error) {
      console.error('Error fetching states:', error)
    }
  }
  const termsFormik = useFormik({
    initialValues: {
      acceptTerms: false,
    },
    validationSchema: termsSchema,
    onSubmit: (values) => {
      console.log('Terms Accepted:', values) // Debugging log
      setStep(2) // This should update the state to move to the next step
    },
  })

  const personalInfoFormik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      phone_country: 'EG',
      phone: '',
    },
    validationSchema: personalInfoSchema,
    onSubmit: (values) => {
      setPersonalInformations(values)
      console.log('Personal Info Form Submitted:', values) // Debugging log
      setStep(3)
    },
  })

  const companyInfoFormik = useFormik({
    initialValues: {
      company_name: '',
      country: '',
      state: '',
      city: '',
    },
    validationSchema: companyInfoSchema,
    onSubmit: (values) => {
      setCompaniesInformations(values)
      console.log('Personal Info Form Submitted:', values) // Debugging log
      setStep(4)
    },
  })
  const finalStepFormik = useFormik({
    initialValues: {
      files: [],
      message: '',
    },
    validationSchema: finalStepSchema,
    onSubmit: async (values) => {
      setLoading(true)
      const combined = {
        ...values,
        ...personalInformations,
        ...companieslInformations,
      }
      axiosInstance
        .post('auth/register', combined)
        .then((response) => {
          console.log(response.data.data)
          // Uncomment if you want to navigate after successful submission
          // navigate('/success');
          // navigate('auth', { replace: true });
          // navigate('/auth', { replace: true });
          toast.success('Register')
        })
        .catch((error) => {
          // Assume error.response contains the error messages
          if (error.response && error.response.data) {
            console.log(error.response.data.message, 'dlkfjdslkj')
            const errorMessages = error.response.data.errors || {}
            toast.error(error.response.data.message)
            toast.success('Branch added successfully!')
            // console.log(errorMessages, 'all errors');
            // setErrors(errorMessages[0]);
            // console.log(errorMessages, 'collect errors')
            Object.keys(errorMessages).forEach((key) => {
              // console.log(key, errorMessages[key][0], 'key');
              toast.error(`Error in ${key}: ${errorMessages[key].join(', ')}`)
            })
          } else {
            toast.error('Unexpected error occurred. Please try again later.')
          }
        })
        .finally(() => {
          setLoading(false) // Ensure loading state is reset in both success and error cases
        })
    },
  })

  const handlePreviousStep = () => {
    setStep(step - 1)
  }

  const handleAccept = () => {
    termsFormik.setFieldValue('acceptTerms', true, false)
    const modalElement = document.getElementById('kt_modal_1')
    if (modalElement) {
      const modal = Modal.getInstance(modalElement) || new Modal(modalElement)
      modal.hide()
    }
  }
  const handleFileUpload = (files: File[]) => {
    // Handle the uploaded files
    console.log('Uploaded files:', files)
  }
  const handleCountryChange = (option: SelectOption | null) => {
    if (option) {
      setSelectedCountry(option.value)
      companyInfoFormik.setFieldValue('country', option.value)
    } else {
      setSelectedCountry('')
      companyInfoFormik.setFieldValue('country', '')
    }
  }

  const handleSendOtp = async () => {
    console.log()
    console.log(personalInfoFormik.getFieldProps('phone').value, 'this is a data')
    let phone = personalInfoFormik.getFieldProps('phone').value
    let phone_country = personalInfoFormik.getFieldProps('phone_country').value
    console.log(phone, phone_country, 'phone and country')
    axios
      .post(`${API_URL}/auth/send-verification-code`, {
        phone: phone,
        phone_country: phone_country,
      })
      .then((response) => {
        setIsModalVisible(true) // Show the modal after OTP is sent
        // Handle success response
      })
      .catch((error) => {
        // Handle error response
        console.error('Error sending verification code:', error)
      })
  }

  const handleCloseModal = () => {
    setIsModalVisible(false) // Hide the modal
  }
  return (
    <div>
      {step === 1 && (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={termsFormik.handleSubmit}
        >
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Sign up as a company owner</h1>
            <p className='text-dark fw-semibold fs-6'>Please agree to the terms</p>
          </div>
          <div className='mb-3'>
            <p className='text-dark fw-semibold fs-6'>About Owner Account</p>
            <span className='text-gray-500 fw-semibold fs-6'>
              {termsContent?.title}
            </span>
          </div>
          <div className='fv-row mb-8'>
            <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_login_toc_agree'
                {...termsFormik.getFieldProps('acceptTerms')}
              />
              <span>
                I Accept the{' '}
                <button
                  type='button'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_1'
                  className='ms-1 link-dark btn p-0'
                >
                  Terms & Conditions
                </button>
                .
              </span>
            </label>
            {termsFormik.touched.acceptTerms && termsFormik.errors.acceptTerms && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{termsFormik.errors.acceptTerms}</span>
                </div>
              </div>
            )}
          </div>
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-dark w-100 mb-5'
              disabled={!termsFormik.values.acceptTerms}
            >
              {!loading && <span className='indicator-label'>Next</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                </span>
              )}
            </button>
          </div>
        </form>
      )}

      {step === 2 && (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          onSubmit={personalInfoFormik.handleSubmit}
        >
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
            <div className='text-gray-500 fw-semibold fs-6'>Please Enter the following fields</div>
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Full Name</label>
            <input
              placeholder='Full Name'
              type='text'
              autoComplete='off'
              {...personalInfoFormik.getFieldProps('name')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': personalInfoFormik.touched.name && personalInfoFormik.errors.name,
                },
                {
                  'is-valid': personalInfoFormik.touched.name && !personalInfoFormik.errors.name,
                }
              )}
            />
            {personalInfoFormik.touched.name && personalInfoFormik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{personalInfoFormik.errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Phone Number</label>
            <div className='d-flex gap-4'>
              <input
                placeholder='Phone Number'
                type='text'
                autoComplete='off'
                {...personalInfoFormik.getFieldProps('phone')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      personalInfoFormik.touched.phone && personalInfoFormik.errors.phone,
                  },
                  {
                    'is-valid':
                      personalInfoFormik.touched.phone && !personalInfoFormik.errors.phone,
                  }
                )}
              />
              <div className='container mt-5'>
                <button className='btn btn-dark' type='button' onClick={handleSendOtp}>
                  Send Code
                </button>

                {/* Modal */}
                <div
                  className={`modal fade ${isModalVisible ? 'show' : ''}`}
                  style={{display: isModalVisible ? 'block' : 'none'}}
                  tabIndex={-1}
                  aria-hidden={!isModalVisible}
                  role='dialog'
                >
                  <div className='modal-dialog' role='document'>
                    <div className='modal-content'>
                      <div className='modal-header p-1'>
                        <h5 className='modal-title'>Verified</h5>
                        <button
                          type='button'
                          className='btn-close'
                          onClick={handleCloseModal}
                          aria-label='Close'
                        >
                          x
                        </button>
                      </div>
                      <div className='modal-body'>
                        <RegOtbForm
                          phone={personalInfoFormik.getFieldProps('phone').value}
                          phone_country={personalInfoFormik.getFieldProps('phone_country').value}
                        />
                      </div>
                      {/* <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-dark w-100'
                onClick={handleCloseModal}
              >
                Save changes
              </button>
            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {personalInfoFormik.touched.phone && personalInfoFormik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{personalInfoFormik.errors.phone}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Email</label>
            <input
              placeholder='Email'
              type='email'
              autoComplete='off'
              {...personalInfoFormik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': personalInfoFormik.touched.email && personalInfoFormik.errors.email,
                },
                {
                  'is-valid': personalInfoFormik.touched.email && !personalInfoFormik.errors.email,
                }
              )}
            />
            {personalInfoFormik.touched.email && personalInfoFormik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{personalInfoFormik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Password</label>
            <input
              placeholder='Password'
              type='password'
              autoComplete='off'
              {...personalInfoFormik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid':
                    personalInfoFormik.touched.password && personalInfoFormik.errors.password,
                },
                {
                  'is-valid':
                    personalInfoFormik.touched.password && !personalInfoFormik.errors.password,
                }
              )}
            />
            {personalInfoFormik.touched.password && personalInfoFormik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{personalInfoFormik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
            <input
              placeholder='Confirm Password'
              type='password'
              autoComplete='off'
              {...personalInfoFormik.getFieldProps('password_confirmation')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid':
                    personalInfoFormik.touched.password_confirmation &&
                    personalInfoFormik.errors.password_confirmation,
                },
                {
                  'is-valid':
                    personalInfoFormik.touched.password_confirmation &&
                    !personalInfoFormik.errors.password_confirmation,
                }
              )}
            />
            {personalInfoFormik.touched.password_confirmation &&
              personalInfoFormik.errors.password_confirmation && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{personalInfoFormik.errors.password_confirmation}</span>
                  </div>
                </div>
              )}
          </div>
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='button'
              className='btn btn-lg btn-light-dark w-100 mb-5'
              onClick={handlePreviousStep}
            >
              Previous
            </button>
            <button type='submit' className='btn btn-lg btn-dark w-100 mb-5'>
              Continue
            </button>
          </div>
        </form>
      )}

      {step === 3 && (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={companyInfoFormik.handleSubmit}
        >
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Company Information</h1>
            <p className='text-dark fw-semibold fs-6'>Enter your company details</p>
          </div>
          <div className='fv-row mb-8'>
            <label htmlFor='company_name'>Company Name</label>
            <input
              id='company_name'
              {...companyInfoFormik.getFieldProps('company_name')}
              type='text'
              className='form-control'
            />
            {companyInfoFormik.touched.company_name && companyInfoFormik.errors.company_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{companyInfoFormik.errors.company_name}</div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label htmlFor='country'>Country</label>
            <Select<SelectOption>
              id='country'
              options={countries.map((country) => ({value: country.iso, label: country.name}))}
              value={
                countries.find((country) => country.iso === selectedCountry)
                  ? {
                      value: countries.find((country) => country.iso === selectedCountry)!.iso,
                      label: countries.find((country) => country.iso === selectedCountry)!.name,
                    }
                  : null
              }
              onChange={(option) => {
                if (option) {
                  setSelectedCountry(option.value)
                  companyInfoFormik.setFieldValue('country', option.value)
                  fetchStates(option.value)
                }
              }}
            />

            {companyInfoFormik.touched.country && companyInfoFormik.errors.country && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{companyInfoFormik.errors.country}</div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label htmlFor='state'>State</label>
            <Select<SelectOption>
              id='state'
              options={states.map((state) => ({value: state.id, label: state.name}))}
              value={
                states.find((state) => state.id === selectedState)
                  ? {
                      value: states.find((state) => state.id === selectedState)!.id,
                      label: states.find((state) => state.id === selectedState)!.name,
                    }
                  : null
              }
              onChange={(option) => {
                if (option) {
                  setSelectedState(option.value)
                  fetchCities(option.value)
                  console.log(option, 'heres is the states selected')
                  companyInfoFormik.setFieldValue('state', option.value)
                }
              }}
            />

            {companyInfoFormik.touched.state && companyInfoFormik.errors.state && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{companyInfoFormik.errors.state}</div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label htmlFor='city'>City</label>
            <Select<SelectOption>
              id='city'
              options={cities.map((city) => ({
                value: city.id,
                label: city.name,
                documents: city.documents,
              }))}
              value={
                cities.find((city) => city.id === companyInfoFormik.values.city)
                  ? {
                      value: cities.find((city) => city.id === companyInfoFormik.values.city)!.id,
                      label: cities.find((city) => city.id === companyInfoFormik.values.city)!.name,
                    }
                  : null
              }
              onChange={(option) => {
                if (option) {
                  setDocuments(option['documents'])
                  companyInfoFormik.setFieldValue('city', option.value)
                  console.log(documents, 'should appear now ')
                }
              }}
            />

            {companyInfoFormik.touched.city && companyInfoFormik.errors.city && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{companyInfoFormik.errors.city}</div>
              </div>
            )}
          </div>
          <div className='text-center'>
            <button type='button' className='btn btn-secondary me-3' onClick={handlePreviousStep}>
              Back
            </button>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-primary'
              // disabled={companyInfoFormik.isSubmitting}
            >
              {/* {companyInfoFormik.isSubmitting ? 'Loading...' : 'Continue'} */}
              Continue
            </button>
          </div>
        </form>
      )}
      {step === 4 && (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          onSubmit={finalStepFormik.handleSubmit}
        >
          <div className='text-center mb-10'>
            <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
          </div>

          <div>
            {/* <div className='text-center mb-10'>
            <p className='text-gray-500 fw-semibold fs-6'>
              Please upload required documents and send a message
            </p>
            {documents.map((file, index) => (
  <div className='mb-3' key={index}>
    <label className='form-label fs-8 text-dark mb-2 text-start'>
      {file.display_name}
      {file.required === 1 && <span style={{ color: 'red' }}> *</span>}
    </label>
    <ImageUpload required={file.required === 1} />
  </div>
))}
          </div> */}
            <div className=' mb-10'>
              {documents.length > 0 && (
                <p className='text-gray-500 fw-semibold fs-6'>
                  Please upload required documents and send a message
                </p>
              )}

              {documents.map((file, index) => (
                <div className='mb-3' key={index}>
                  <label className='form-label fs-8 text-dark mb-2 text-start'>
                    {typeof file.display_name === 'string'
                      ? file.display_name
                      : 'Unknown File Name'}
                    {file.required === 1 && <span style={{color: 'red'}}> *</span>}
                  </label>

                  <input
                    type='file'
                    className='form-control'
                    onChange={(event) => {
                      const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
                      if (selectedFile) {
                        finalStepFormik.setFieldValue(`files[${index}].name`, file.display_name)
                        finalStepFormik.setFieldValue(`files[${index}].file`, selectedFile)
                      }
                    }}
                  />
                  {finalStepFormik.touched.files && finalStepFormik.errors.files && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{finalStepFormik.errors.files[index]}</span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>Owner message</label>
            <textarea
              className='form-control bg-transparent'
              rows={5}
              placeholder='You Can Write a Message to High Five Team'
              value={finalStepFormik.values.message}
              onChange={finalStepFormik.handleChange}
              name='message'
            ></textarea>
            {finalStepFormik.errors.message && finalStepFormik.touched.message && (
              <div className='text-danger'>{finalStepFormik.errors.message}</div>
            )}
          </div>

          <div className=' pb-lg-0'>
            <button
              type='button'
              className='btn btn-secondary w-100 d-grid'
              onClick={handlePreviousStep}
            >
              Back
            </button>
            <button
              type='submit'
              className={`btn btn-dark w-100 d-grid ${loading ? 'loading' : ''}`}
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      )}

      <div
        className='modal fade'
        tabIndex={-1}
        id='kt_modal_1'
        aria-hidden='true'
        style={{display: 'none'}}
      >
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content'>
            <div className='modal-header p-3'>
              <h5 className='modal-title'>Terms & Conditions</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                x
              </div>
            </div>
            <div className='modal-body p-3'>
            <img src={termsContent?.image ?? ''} className='term_image' alt="Terms and conditions visual representation" />

              <h3 className='terms-content mb-3'>  {termsContent?.title}</h3>
              <div
      className="terms-content"
      dangerouslySetInnerHTML={{ __html: decodeHtml(termsContent?.body || '') }}
    ></div>


              <div className='fv-row mt-5'>
                <button type='button' className='btn btn-dark w-100' onClick={handleAccept}>
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Registration
