import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const apiUrl = process.env.REACT_APP_API_URL;

const apiService = {
  getActivityCategories: async () => {
    try {
      const response = await axios.get(`${apiUrl}/general/activity-categories`);
      console.log(response.data.data, 'activity-categories');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching activity categories:', error);
      throw error;
    }
  },

  getAddNewBranch: async ($branch) => {
    try {
      const response = await axios.get(`${apiUrl}/general/activity-categories`);
      console.log(response.data.data, 'activity-categories');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching activity categories:', error);
      throw error;
    }
  },

  getActivitiesByCategory: async (categoryId) => {
    try {
      const response = await axios.get(`${apiUrl}/general/activities/${categoryId}`);
      console.log(response.data.data, 'activities');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching activities:', error);
      throw error;
    }
  },

  getActivityOptions: async (activityId) => {
    try {
      const response = await axios.get(`${apiUrl}/general/activity-options/${activityId}`);
      console.log(response.data.data, 'activity-options');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching activity options:', error);
      throw error;
    }
  },

  getStates: async (country) => {
    try {
      const response = await axios.get(`${apiUrl}/general/states/${country}`);
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching activity options:', error);
      throw error;
    }
  },
  getCities: async (state) => {
    try {
      const response = await axios.get(`${apiUrl}/general/cities/${state}`);
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching activity options:', error);
      throw error;
    }
  },

  getMainFields: async () => {
    try {
      const response = await axios.get(`${apiUrl}/general/units`);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching main fields:', error);
      throw error;
    }
  },

  getBranches: async () => {
    try {
      const response = await axios.get(`${apiUrl}/general/branches`);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching branches:', error);
      throw error;
    }
  },

  createField: async (fieldData) => {
    try {
      const response = await axios.post(`${apiUrl}/units`, fieldData).then(response => {
        toast.success('Unit added successfully!');

        return response.data.data
      });
      
      // return response.data.data;
      
    } catch (error) {
      console.error('Error creating field:', error);
      throw error;
    }
  },
};
// apiService.js
export const getBranchData = async (branchId) => {
  const response = await axios.get(`${apiUrl}/branches/${branchId}`)
  return response.data
}

export default apiService;
