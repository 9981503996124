import React, {FC, useEffect, useState} from 'react'
import axios from 'axios'
import {KTIcon} from '../../../../../_high-five/helpers'
import toast, {Toaster} from 'react-hot-toast'
import Select from 'react-select'

interface Employee {
  id: number
  name: string
  email: string
  phone: string
  phone_country: string // Add this line
}

const Employees: FC = () => {
  const apiUrl = process.env.REACT_APP_API_URL || ''
  const [employees, setEmployees] = useState<Employee[]>([])
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null) // For the selected employee to edit/delete
  const [editMode, setEditMode] = useState<boolean>(false) // To track edit mode
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false) // State for edit modal visibility
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false) // State for delete modal visibility
  const [phoneCountry, setPhoneCountry] = useState<{
    value: string
    label: string
    flag: string
  } | null>(null) // State for selected phone country
  const [countries, setCountries] = useState<{value: string; label: string; flag: string}[]>([])

  // Fetch employee data on component mount
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${apiUrl}/employee`)
        setEmployees(response.data.data) // Assuming data is under 'data'
      } catch (error) {
        toast.error('Failed to fetch employee data')
      }
    }

    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          'https://develop.asasware.com/api/provider/general/countries'
        )

        const countryOptions = response.data.data.map((country: any) => ({
          value: country.iso,
          label: country.name,
          flag: '/' + country.flag,
        }))

        setCountries(countryOptions)
      } catch (error) {
        let errorMessage = 'There was an error fetching the countries. Please try again.'

        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        toast.error(errorMessage)
        setLoading(false)
      }
    }

    fetchCountries()
    fetchEmployees()
  }, [apiUrl])

  const CustomSingleValue = ({data}: any) => (
    <div className='label'>
      {data.flag && <img src={data.flag} alt='flag' className='w-30px h-30px me-2' />}
    </div>
  )

  const CustomOption = (props: any) => {
    const {data, innerRef, innerProps} = props
    return (
      <div ref={innerRef} {...innerProps} className='react-select-option'>
        {data.flag && <img src={data.flag} alt='flag' className='w-30px h-30px me-2' />}
        <span>{data.label}</span>
      </div>
    )
  }

  // Handle form submission to create or edit an employee
  const handleCreateEmployee = async () => {
    if (!name || !email || !phone || !phoneCountry) {
      toast.error('Please fill out all fields')
      return
    }

    const employeeData = {
      name,
      email,
      phone,
      phone_country: phoneCountry.value, // Use selected phone country value
      phone_has_whatsapp: 1,
    }

    setLoading(true)
    try {
      if (editMode && selectedEmployee) {
        // Edit existing employee
        await axios.put(`${apiUrl}/employee/${selectedEmployee.id}`, employeeData)
        toast.success('Employee updated successfully')
      } else {
        // Create new employee
        await axios.post(`${apiUrl}/employee`, employeeData)
        toast.success('Employee created successfully')
      }

      // Refetch employees after creation or update
      const response = await axios.get(`${apiUrl}/employee`)
      setEmployees(response.data.data)

      // Clear form fields and reset edit mode
      setName('')
      setEmail('')
      setPhone('')
      setPhoneCountry(null) // Clear phone country selection
      setEditMode(false)
      setSelectedEmployee(null)
      setIsEditModalOpen(false) // Close the modal after action
    } catch (error) {
      let errorMessage = editMode ? 'Failed to update employee' : 'Failed to create employee'

      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as {response: {data: {message: string}}}
        errorMessage = err.response.data.message || errorMessage
      }

      toast.error(errorMessage)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

// Show the modal for editing an employee
const showEditModal = (employee: Employee) => {
  setName(employee.name);
  setEmail(employee.email);
  setPhone(employee.phone);

  // Find the country option based on employee's phone_country
  const countryOption = countries.find(country => country.value === employee.phone_country) || null;
  setPhoneCountry(countryOption); // Set the selected country based on employee data

  setEditMode(true);
  setSelectedEmployee(employee);
  setIsEditModalOpen(true); // Open the modal
};


  // Show confirmation modal for deleting an employee
  const showDeleteConfirmation = (employee: Employee) => {
    setSelectedEmployee(employee) // Set the selected employee for deletion
    setIsDeleteModalOpen(true) // Open delete confirmation modal
  }

  // Handle employee deletion
  const handleDeleteEmployee = async () => {
    if (!selectedEmployee) return

    try {
      await axios.delete(`${apiUrl}/employee/${selectedEmployee.id}`)
      toast.success('Employee deleted successfully')

      // Remove deleted employee from state
      setEmployees((prevEmployees) => prevEmployees.filter((emp) => emp.id !== selectedEmployee.id))

      // Close the modal
      setIsDeleteModalOpen(false)
      setSelectedEmployee(null)
    } catch (error) {
      toast.error('Failed to delete employee')
    }
  }

  return (
    <>
      <div className='pt-5'>
        <div className='bredcrumb_card mb-10'>
          <h1>EMPLOYEES MANAGEMENT</h1>
        </div>
        <div className='card'>
          <div className='card-header border-0 pt-5 justify-content-end gap-5'>
            <button
              className='btn btn-sm btn-dark h-40px'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
            >
              What Employees Can Do
            </button>
            <button
              className='btn btn-sm btn-dark h-40px'
              onClick={() => {
                setName('') // Clear the name input
                setEmail('') // Clear the email input
                setPhone('') // Clear the phone input
                setPhoneCountry(null) // Clear phone country selection
                setEditMode(false) // Ensure we are in create mode
                setIsEditModalOpen(true) // Open the modal
              }}
            >
              <KTIcon iconName='plus' className='fs-2' />
              New
            </button>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted bg-dark'>
                    <th className='rounded-start'>ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th className='rounded-end'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {employees.length > 0 ? (
                    employees.map((employee) => (
                      <tr key={employee.id}>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {employee.id}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {employee.name}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {employee.email}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {employee.phone}
                          </span>
                        </td>
                        <td className='d-flex gap-4 justify-content-center'>
                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={() => showEditModal(employee)} // Open modal for editing
                          >
                            <KTIcon iconName='eye' className='fs-3' />
                          </button>
                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                            onClick={() => showDeleteConfirmation(employee)} // Show delete confirmation
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} className='text-center'>
                        <span>No employees found</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Edit Modal */}
        {isEditModalOpen && (
          <div className='modal fade show' style={{display: 'block'}}>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>{editMode ? 'Edit Employee' : 'Add Employee'}</h5>
                  <button className='btn-close' onClick={() => setIsEditModalOpen(false)}></button>
                </div>
                <div className='modal-body'>
                  <div className='mb-4'>
                    <label className='form-label'>Name</label>
                    <input
                      type='text'
                      className='form-control'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className='mb-4'>
                    <label className='form-label'>Email</label>
                    <input
                      type='email'
                      className='form-control'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className='mb-4'>
                    <label className='form-label'>Phone</label>
                    <div className=''>
                      <div className='d-flex w-100 input-group border rounded mb-4'>
<Select
  options={countries}
  value={phoneCountry} // Set the selected value here
  onChange={(selected) => setPhoneCountry(selected)} // Update phone country on selection
  components={{ SingleValue: CustomSingleValue, Option: CustomOption }} // Custom components for rendering
  isSearchable={false} // Disable the search input

/>
                        <input
                          type='text'
                          className='form-control'
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button className='btn btn-light' onClick={() => setIsEditModalOpen(false)}>
                    Cancel
                  </button>
                  <button className='btn btn-primary' onClick={handleCreateEmployee}>
                    {loading ? 'Loading...' : editMode ? 'Update' : 'Create'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Delete Confirmation Modal */}
        {isDeleteModalOpen && (
          <div className='modal fade show' style={{display: 'block'}}>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Delete Employee</h5>
                  <button
                    className='btn-close'
                    onClick={() => setIsDeleteModalOpen(false)}
                  ></button>
                </div>
                <div className='modal-body'>
                  <p>Are you sure you want to delete this employee?</p>
                </div>
                <div className='modal-footer'>
                  <button className='btn btn-light' onClick={() => setIsDeleteModalOpen(false)}>
                    Cancel
                  </button>
                  <button className='btn btn-danger' onClick={handleDeleteEmployee}>
                    {loading ? 'Loading...' : 'Delete'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
                  <div className="modal fade" tabIndex={-1} id="kt_modal_1">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">What Employees Can do</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
       x
        </div>
      </div>
      <div className="modal-body">
      <ul>
        <li>All these future will be available for employee after he get assigned on a field</li>
        <li>Sessions — Manage dated schedule on assigned field</li>
        <li>Create single session</li>
        <li>Edit & delete single session</li>
        <li>Edit & delete sessions created by session management</li>
        <li>Create booking</li>
        <li>Create recurring booking</li>
        <li>link single booking</li>
        <li>link recurring booking</li>
        <li>cancel single booking</li>
        <li>cancel recurring booking</li>
        <li>Turn on-off receiveve online bookings</li>
        <li>will be added to all linked and received booking chats</li>
        <li>Accounting — Add expenses — Add revenues</li>
      </ul>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        {/* <button type="button" className="btn btn-primary">
          Save changes
        </button> */}
      </div>
    </div>
  </div>
</div>
      </div>

      <Toaster />
    </>
  )
}

export default Employees
