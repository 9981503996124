import {FC} from 'react'
import {useIntl} from 'react-intl'
import Breadcrumb from '../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'

const Contact: FC = () => {
  const intl = useIntl()

  return (
    <>
      <div className='pt-5 company_profile'>
        <div className='row'>
          <div className='col-12'>
            <div className='banner'>
              <img src='/media/auth/bg3-dark.jpg' alt='' className='w-100 img-fluid' />
              <div className='layout'>
                <h1>Contact Us </h1>
              </div>
            </div>
            <div className='my-5'>
              <Breadcrumb secondItem=' Page' />
            </div>
          </div>
          <div className='col-12'>
            <div className='card p-5'>
              <h3 className='mb-3 pb-3 border-bottom'>Contact  Information</h3>
              <div className='alert alert-danger  d-flex align-items-center p-5 mb-10'>

                <div className='d-flex flex-column'>
                  <span>No information yet</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Contact}
