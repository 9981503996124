import React from 'react';

interface CustomTimePickerProps {
  value: string;
  onChange: (value: string) => void;
}

const CustomTimePicker: React.FC<CustomTimePickerProps> = ({ value, onChange }) => {
  const incrementTime = (time: string, minutesToAdd: number) => {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes + minutesToAdd);

    const newHours = date.getHours().toString().padStart(2, '0');
    const newMinutes = Math.floor(date.getMinutes() / 30) * 30; // Round to the nearest 30 minutes
    const newTime = `${newHours}:${newMinutes.toString().padStart(2, '0')}`;

    onChange(newTime);
  };

  const handleIncrement = () => incrementTime(value, 30);
  const handleDecrement = () => incrementTime(value, -30);

  return (
    <div className="custom-time-picker">
      <div className="input-group form-control  p-0 px-3">
        <button
          className="btn btn-outline-secondary p-0"
          type="button"
          onClick={handleDecrement}
        >
          <i className="fa fa-chevron-down"></i>
        </button>
        <input
          type="text"
          className="form-control border-0"
          value={value}
          readOnly
        />
        <button
          className="btn btn-outline-secondary p-0"
          type="button"
          onClick={handleIncrement}
        >
          <i className="fa fa-chevron-up"></i>
        </button>
      </div>
    </div>
  );
};

export default CustomTimePicker;
