import {FC, useEffect, useState} from 'react'
import axios from 'axios'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Breadcrumb from '../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import LoadingSpinner from '../../../app/shared/LoadingSpinner'
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

// Validation schema using Yup
const validationSchema = Yup.object({
  oldPassword: Yup.string().required('Old password is required'),
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .matches(/[a-zA-Z]/, 'Password must contain at least one letter')
    .required('New password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), undefined], 'Password and Confirm Password must match')
    .required('Please confirm your password'),
})

const ChangePassword: FC = () => {
  const intl = useIntl()
  const apiUrl = process.env.REACT_APP_API_URL
  const [loading, setLoading] = useState<boolean>(true) // Loading state
  const navigate = useNavigate() // Using useNavigate for programmatic navigation

  const handleSave = async (values: { oldPassword: string; newPassword: string }) => {
    try {
      setLoading(true) // Start loading

      const 
      response = await axios.post(`${apiUrl}/profile/update-password`, {
        old_password: values.oldPassword,
        password: values.newPassword,
      })

      if (response.status === 200) {
        toast.success('Password updated successfully!')
        navigate(-1) // Navigate to the previous page after success
      } else {
        toast.error('Failed to update password!')
      }
    } catch (error: unknown) {
      let errorMessage = 'There was an error  Please try again.'

      // Check if the error is of the type we expect
      if (error instanceof Error) {
        // If the error is an instance of the generic Error class, we can log its message
      }

      // Use type assertion to narrow down the type of error
      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as {response: {data: {message: string}}}
        errorMessage = err.response.data.message || errorMessage
      }

      // Log the error and show an error toast notification
      toast.error(errorMessage)
      // Ensure loading stops and submission is reset
    } finally {
      setLoading(false) // Stop loading
    }
  }

  return (
    <>
      <div className='pt-5 company_profile'>
        <div className='row'>
          <div className='col-12'>
            <div className='banner'>
              <img src='/media/auth/bg3-dark.jpg' alt='' className='w-100 img-fluid' />
              <div className='layout'>
                <h1>Changing Password</h1>
              </div>
            </div>
            <div className='my-5'>
              <Breadcrumb secondItem=' Page' />
            </div>
          </div>
          <div className='col-12'>
            <div className='card p-5 profile_box'>
              <h3 className='pb-5 border-bottom'>Edit Password</h3>
              <Formik
                initialValues={{
                  oldPassword: '',
                  newPassword: '',
                  confirmPassword: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSave}
              >
                {({ isSubmitting, isValid, dirty }) => (
                  <Form>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <div className='mb-10'>
                          <label className='form-label'>Old Password :</label>
                          <Field
                            type='password'
                            name='oldPassword'
                            className='form-control'
                            placeholder='Old Password'
                          />
                          <ErrorMessage
                            name='oldPassword'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>New Password :</label>
                          <Field
                            type='password'
                            name='newPassword'
                            className='form-control'
                            placeholder='New Password'
                          />
                          <ErrorMessage
                            name='newPassword'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>Confirm Password :</label>
                          <Field
                            type='password'
                            name='confirmPassword'
                            className='form-control'
                            placeholder='Confirm Password '
                          />
                          <ErrorMessage
                            name='confirmPassword'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                        <p className='text-muted'>- Password must contain at least one letter.</p>
                        <p className='text-muted'>- Password must be between 8 and 16 characters.</p>
                        <p className='text-muted'>- Passwords must match.</p>
                        <div className='d-flex gap-10 mt-5 border-top pt-3'>
                          <button className='btn btn-light' type='reset'>
                            Cancel
                          </button>
                          <button
                            className='btn btn-dark'
                            type='submit'
                            disabled={isSubmitting || !isValid || !dirty} // Disable if submitting or invalid
                          >
                            {isSubmitting ? 'Saving...' : 'Save'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { ChangePassword }
