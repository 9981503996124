import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import { BookingHistoryTable } from '../../../../_high-five/partials/widgets/custom_table/BookingHistoryTable'
import axios from 'axios'
import Session from '../schedule/Session'
import Booking from '../schedule/Booking'
import toast, { Toaster } from 'react-hot-toast'

const BookingDetails: FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const [bookingDetails, setBookingDetails] = useState<any | null>(null)
  const [unitDetails, setUnitDetails] = useState<any | null>(null)
  const [sessionDetails, setSessionDetails] = useState<any | null>(null)
  const [paymentDetails, setPaymentDetails] = useState<any | null>(null)
  const [locked, setLocked] = useState<boolean>(false)
  const [selectedTransactionId, setSelectedTransactionId] = useState<string | null>(null) // State to hold transaction id

  const apiUrl = process.env.REACT_APP_API_URL
  const queryParams = new URLSearchParams(location.search)
  const bookingIdQueryParam = queryParams.get('bookingId')
  const [amount, setAmount] = useState<string>('') // Add state for amount
  const navigate = useNavigate()

  useEffect(() => {
    const fetchPublicEventDetails = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/booking/${bookingIdQueryParam}`)
        setSessionDetails(data.data.session)
        setBookingDetails(data.data.booking)
        setPaymentDetails(data.data.payment)
        setUnitDetails(data.data.unit)
      } catch (error) {
        console.error('Error fetching session data:', error)
      }
    }

    fetchPublicEventDetails()
  }, [bookingIdQueryParam])
  const fetchPublicEventDetails = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/booking/${bookingIdQueryParam}`)
      setSessionDetails(data.data.session)
      setBookingDetails(data.data.booking)
      setPaymentDetails(data.data.payment)
      setUnitDetails(data.data.unit)
    } catch (error) {
      console.error('Error fetching session data:', error)
    }
  }
  const handleCancelBooking = async () => {
    try {
      const response = await axios.post(`${apiUrl}/booking/${bookingIdQueryParam}/cancel`)
      toast.success('Booking canceled successfully!')
      navigate(-1)
    } catch (error) {
      toast.error('Failed to cancel the booking. Please try again.')
      console.error('Error cancelling the booking:', error)
    }
  }

  const handleCollectBooking = async () => {
    try {
      const response = await axios.post(`${apiUrl}/booking/${bookingIdQueryParam}/collect`, { amount })
      toast.success('Booking collected successfully!')
      fetchPublicEventDetails()
    } catch (error) {
      toast.error('Failed to collect the booking. Please try again.')
      console.error('Error collecting the booking:', error)
    }
  }

  const handleDeletePayment = async () => {
    if (selectedTransactionId) {
      try {
        await axios.delete(`${apiUrl}/booking/${bookingIdQueryParam}/payment/${selectedTransactionId}`)
        toast.success('Payment deleted successfully!')
        fetchPublicEventDetails() // Refresh data after deletion
        setSelectedTransactionId(null) // Reset the transaction ID after success
      } catch (error) {
        toast.error('Failed to delete payment. Please try again.')
        console.error('Error deleting payment:', error)
      }
    }
  }
  return (
    <>
      <div className='pt-5'>
        <h1>VIEW BOOKING</h1>
        <Breadcrumb secondItem=' Page' />
        <div className='row'>
          <div className='col-lg-6'>
            <div className='card p-4 min-h-card'>
              <h4 className=' border-bottom pb-4  pt-2 mb-3 text-lime'>
                Session Information (#{sessionDetails?.id}){' '}
              </h4>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Type :</span> <span>{sessionDetails?.type}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Date :</span> <span>{sessionDetails?.date}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Start :</span> <span>{sessionDetails?.start_time}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>End :</span> <span>{sessionDetails?.end_time}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Gender :</span> <span>{sessionDetails?.gender}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Price :</span> <span>{sessionDetails?.price}</span>{' '}
                <span>
                  <button className='btn p-0' data-bs-toggle='modal' data-bs-target='#kt_modal_1'>
                    <i className='fa-solid fa-circle-info'></i>
                  </button>
                </span>
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='card p-4 min-h-card'>
              <h4 className=' border-bottom pb-4  pt-2 mb-3 text-lime'>
                Booking (#{bookingDetails?.id}){' '}
              </h4>

              {bookingDetails?.booking_status_trans === 'Coming' && (
                <div className='d-flex justify-content-end  align-items-center mb-4'>
                  {/* <div className='form-check form-check-custom form-check-solid form-switch'>
                    <span>Unlocked</span>

                    <input
                      className='form-check-input mx-4 px-8'
                      type='checkbox'
                      id='locked'
                      checked={locked}
                      onChange={() => setLocked(!locked)}
                    />
                    <span>locked</span>
                  </div> */}
                  <button className='btn btn-danger p-3'  data-bs-toggle='modal' data-bs-target='#kt_modal_2'>cancel booking</button>
                </div>
              )}
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Type :</span> <span>{bookingDetails?.type}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Booking Status :</span>{' '}
                <span>{bookingDetails?.booking_status_trans}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Booked for :</span>{' '}
                <span>
                  Contact ( {bookingDetails?.booked_for?.type} / {bookingDetails?.booked_for?.name}{' '}
                  )
                </span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Booked By :</span> <span>{bookingDetails?.booked_by}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Booked At :</span> <span>{bookingDetails?.booked_at}</span>
              </p>
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='card p-4 min-h-card mt-5'>
              <h4 className=' border-bottom pb-4  pt-2 mb-3 text-lime d-md-flex justify-content-between'>
                <span>Payment</span>{' '}
                <span>
                  Remanning: {paymentDetails?.remaining}
                  {bookingDetails?.booking_status_trans === 'Coming' && (
                    <button className='btn btn-danger p-3 mx-2' data-bs-toggle='modal' data-bs-target='#kt_modal_3'>collect</button>
                  )}
                </span>
              </h4>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Payment Method :</span>{' '}
                <span>{paymentDetails?.payment_method_trans}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Payment Status :</span>{' '}
                <span>{paymentDetails?.status}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Cancelation Fee :</span>{' '}
                <span>{paymentDetails?.cancellation_fee}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>collection History</span>
              </p>
              <div className='card-body p-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-dark'>
                <th className='rounded-start'>Amount</th>
                <th className=''>Date</th>
                <th className='rounded-end'>Action</th>
              </tr>
            </thead>
            <tbody>
              {paymentDetails?.histories?.map((transaction: any) => (
                <tr key={transaction.id}>
                  <td>
                    <span className='text-dark fw-bold d-block mb-1 fs-6'>{transaction.amount}</span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold d-block mb-1 fs-6'>{transaction.created_at}</span>
                  </td>
                  <td>
                    <button
                      className='btn btn-sm btn-danger'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_4'
                      onClick={() => setSelectedTransactionId(transaction.id)} // Set transaction id to be deleted
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Delete Payment Modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_4'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Message</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                x
              </div>
            </div>
            <div className='modal-body'>
              Are you sure you want to delete this payment? You cannot undo this action.
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-dark'
                data-bs-dismiss='modal'
                onClick={handleDeletePayment} // Trigger delete payment
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
              </div>
          </div>
        </div>
        {/* modal */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Break Down</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center'>
                    <span className='min_w'>Base Price :</span> <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center'>
                    <span className='min_w'>Discount (%) :</span> <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center'>
                    <span className='min_w'>Total Session Price :</span> <span>20.00 EGP</span>
                  </p>
                </div>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center'>
                    <span className='min_w'>Tax (12%) :</span> <span>2.40 EGP</span>
                  </p>
                </div>
                <div className=' '>
                  <p className='d-flex gap-3 align-items-center'>
                    <span className='min_w'>Total :</span> <span>22.40 EGP</span>
                  </p>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
     
              </div>
            </div>
          </div>
        </div>
  {/* Cancel booking modal */}
  <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Message</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <p>Please confirm that you want to remove this booking (#{bookingDetails?.id})</p>
                <h4>Refund Note</h4>
                <ul>
                  <li>For online booking: Full refund will be applied.</li>
                  <li>
                    For cash booking: Payment history will be deleted and refund is subject to agreement.
                  </li>
                </ul>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-dark'
                  data-bs-dismiss='modal'
                  onClick={handleCancelBooking}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='modal fade' tabIndex={-1} id='kt_modal_3'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Collecet</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <label htmlFor="">Amount :</label>
                <input
                    type='text'
                    className='form-control'
                    value={amount} // Bind amount state
                    onChange={(e) => setAmount(e.target.value)} // Update state on input change
                  />              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-dark'
                  data-bs-dismiss='modal'
                  onClick={handleCollectBooking}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {BookingDetails}
