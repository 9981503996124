import {FC, useEffect, useState} from 'react'
import axios from 'axios'
import {useIntl} from 'react-intl'
import Breadcrumb from '../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import {Link} from 'react-router-dom'
import LoadingSpinner from '../../../app/shared/LoadingSpinner'
import toast, {Toaster} from 'react-hot-toast'

interface ProfileData {
  id: number
  name: string
  phone: string
  email: string
  country: string
  email_verified: boolean
  phone_verified: boolean
  image: string
}

const Profile: FC = () => {
  const apiUrl = process.env.REACT_APP_API_URL
  const intl = useIntl()

  const [profileData, setProfileData] = useState<ProfileData | null>(null)
  const [loading, setLoading] = useState<boolean>(true) // Loading state

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setLoading(true) // Start loading
        const response = await axios.get(`${apiUrl}/profile/show`)
        if (response.data.success) {
          setProfileData(response.data.data)
        }
      } catch (error: unknown) {
        let errorMessage = 'There was an error  Please try again.'

        // Check if the error is of the type we expect
        if (error instanceof Error) {
          // If the error is an instance of the generic Error class, we can log its message
        }

        // Use type assertion to narrow down the type of error
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        // Log the error and show an error toast notification
        toast.error(errorMessage)
        // Ensure loading stops and submission is reset
        setLoading(false)
      } finally {
        setLoading(false) // Stop loading
      }
    }

    fetchProfileData()
  }, [apiUrl])

  return (
    <>
      {loading && <LoadingSpinner />}
      <div className='pt-5 company_profile'>
        <div className='row'>
          <div className='col-12'>
            <div className='banner'>
              <img src='/media/auth/bg3-dark.jpg' alt='' className='w-100 img-fluid' />
              <div className='layout'>
                <h1>Profile</h1>
              </div>
            </div>
            <div className='my-5'>
              <Breadcrumb secondItem='Page' />
            </div>
          </div>
          <div className='col-12'>
            <div className='card p-5 profile_box'>
              <h3 className='pb-5 border-bottom'>Account Information</h3>
              <div className='min_profile mt-5'>
                <img
                  src={profileData?.image ? profileData.image : '/media/avatars/blank.png'}
                  alt='Profile Avatar'
                  className='img-fluid mb-7'
                />

                <p className='mb-10 d-flex gap-5'>
                  <span className='span_width'>Full Name:</span>
                  <span className='text-muted'>{profileData?.name || 'N/A'}</span>
                </p>
                <div className='d-flex gap-30'>
                  <p className='mb-10 d-flex gap-5'>
                    <span className='span_width'>Email Address:</span>
                    <span>
                      <h4 className='text-muted'>{profileData?.email || 'N/A'}</h4>
                      <small
                        className={`text-muted ${
                          profileData?.email_verified ? 'text-success' : ''
                        }`}
                      >
                        {profileData?.email_verified ? 'Verified' : 'Not Verified'}
                      </small>
                    </span>
                  </p>

                  <button
                    className='btn btn-dark h-50px mb-10'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_20'
                  >
                    Update Email
                  </button>
                </div>
                <div className='d-flex gap-30'>
                  <p className='mb-10 d-flex gap-5'>
                    <span className='span_width'>Phone:</span>
                    <span>
                      <h4 className='text-muted'>{profileData?.phone || 'N/A'}</h4>
                      <small
                        className={`text-muted ${
                          profileData?.phone_verified ? 'text-success' : ''
                        }`}
                      >
                        {profileData?.phone_verified ? 'Verified' : 'Not Verified'}
                      </small>
                    </span>
                  </p>

                  <button
                    className='btn btn-dark h-50px mb-10'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_22'
                  >
                    Update Phone
                  </button>
                </div>
                <p className='mb-10 d-flex gap-5'>
                  <span className='span_width'>Password:</span>{' '}
                  <Link to='/dashboard/ChangePassword'>
                    <span className='text-muted text-hover-dark'>Change Password</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* modal */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_20'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Update Email</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <div className=''>
                  <label className='form-label'>Please Enter New Email Address</label>
                  <input type='email' className='form-control' value={'Hadeer@gmail.com'} />
                </div>
              </div>
              <div className='modal-footer justify-content-between'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                <button type='button' className='btn btn-dark'>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* modal */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_22'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Update Phone</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <div className=''>
                  <label className='form-label'>Please Enter New Phone Number</label>
                  <input type='text' className='form-control' placeholder='0512345678' />
                  <small className='text-muted'>
                    {' '}
                    <i className='fa-solid fa-circle-info mx-2'></i> We will ask for verification
                  </small>
                </div>
              </div>
              <div className='modal-footer justify-content-between'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                <button type='button' className='btn btn-dark'>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* modal */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_201'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'> Enter Verfication Code </h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <div className=''>
                  <label className='form-label'>please enter verfication code .</label>
                  <div className='d-flex gap-5'>
                    <input type='text' className='form-control' />
                    <input type='text' className='form-control' />
                    <input type='text' className='form-control' />
                    <input type='text' className='form-control' />
                  </div>
                </div>
              </div>
              <div className='modal-footer justify-content-between'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                <button type='button' className='btn btn-dark'>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Profile}
