import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { UserData2 } from './UserData2';
import { KTIcon } from '../../../helpers';

type Props = {
  className: string;
  branches: any[];
  paginate: any;
  onPageChange: (page: number) => void;
};

const BranchesTable: React.FC<Props> = ({ branches, className, paginate, onPageChange }) => {
  const [openAccordion, setOpenAccordion] = useState<string | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<any | null>(null);

  const handleAccordionClick = (branch: any) => {
    if (openAccordion === branch.id.toString()) {
      setOpenAccordion(null);
      setSelectedBranch(null);
    } else {
      setOpenAccordion(branch.id.toString());
      setSelectedBranch(branch);
    }
  };

  return (
    <div className={`card ${className} BranchesTable`}>
      <div className='card-header border-0 pt-5 justify-content-end'>
        <Link
          to='/dashboard/fields/AddNewBranch'
          className='btn btn-sm btn-dark h-40px d-flex align-items-center'
        >
          <KTIcon iconName='plus' className='fs-2' />
          New
        </Link>
      </div>
      <div className='card-body p-3'>
        <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-dark'>
                  <th className='rounded-start'>#</th>
                  <th style={{ width: '15%' }}>ID</th>
                  <th>Name</th>
                  <th>State, City</th>
                  <th>Approved</th>
                  <th>Not Approved</th>
                  <th>Pending</th>
                  <th className='rounded-end'>Actions</th>
                </tr>
              </thead>
              {branches.length > 0 ? (

              <tbody>
                {branches.map((branch, index) => (
                  <React.Fragment key={branch.id}>
                    <tr onClick={() => handleAccordionClick(branch)}>
                      <td className='accordion-toggle pointer'>
                        <span className='text-dark fw-bold d-block mb-1 fs-6'>{index + 1}</span>
                      </td>
                      <td className='accordion-toggle pointer'>
                        <span className='text-dark fw-bold d-block mb-1 fs-6'>{branch.id}</span>
                      </td>
                      <td className='accordion-toggle pointer'>
                        <span className='text-dark fw-bold d-block mb-1 fs-6'>
                          {branch.name}
                          <span className='fw-bold fs-6 text-primary text-center'>Tag</span>
                        </span>
                      </td>
                      <td className='accordion-toggle pointer'>
                        <span className='text-dark fw-bold d-block mb-1 fs-6'>
                          {branch.state}, {branch.city}
                        </span>
                      </td>
                      <td className='accordion-toggle pointer'>
                        <span className='text-dark fw-bold d-block mb-1 fs-6'>{branch.approved}</span>
                      </td>
                      <td className='accordion-toggle pointer'>
                        <span className='text-dark fw-bold d-block mb-1 fs-6'>
                          {branch.not_approved}
                        </span>
                      </td>
                      <td className='accordion-toggle pointer'>
                        <span className='text-dark fw-bold d-block mb-1 fs-6'>{branch.pending}</span>
                      </td>
                      <td className='d-flex gap-4 justify-content-center'>
                        <Link
                          to={`/dashboard/fields/FieldsMangement?branch=${branch.id}`}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i className='fas fa-eye'></i>
                        </Link>
                      </td>
                    </tr>
                    {openAccordion === branch.id.toString() && (
                      <tr>
                        <td colSpan={12}>
                          <div className='collapse show' id={branch.id.toString()}>
                            {/* Google Map displaying the branch location */}
                            <UserData2 branch={branch} className='' />
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
                   ) : (
                    <tr className='text-center'>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className='text-muted fs-6'>No branches added.</td>
                    </tr>
                  )}
        
            </table>
     
          {/* Pagination Controls */}
          <hr />
          {paginate && (
            <div className="pagination d-flex justify-content-center align-items-center mt-4">
              <button
                className={`btn btn-sm btn-light mx-1 ${!paginate.prev_page_url ? 'disabled' : ''}`}
                onClick={() => onPageChange(paginate.current_page - 1)}
                disabled={!paginate.prev_page_url}
              >
                Previous
              </button>
              <span className="mx-2">
                Page {paginate.current_page} of {paginate.total_pages}
              </span>
              <button
                className={`btn btn-sm btn-light mx-1 ${!paginate.next_page_url ? 'disabled' : ''}`}
                onClick={() => onPageChange(paginate.current_page + 1)}
                disabled={!paginate.next_page_url}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { BranchesTable };
