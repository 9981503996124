import { FC, useEffect, useState } from 'react'
import axios from 'axios'
import { KTIcon } from '../../../../../_high-five/helpers'
import toast ,{Toaster}  from 'react-hot-toast'
import Select from 'react-select'

interface Contact {
  id: number
  name: string
  phone: string,
  phone_code: string
}
interface PhoneCountry {
  value: string; // This could be the country's full name or ISO code
  label: string; // Country name
  flag: string; // Path to the flag image
  phone_code: string; // Two-letter country code (e.g., "EG" for Egypt)
}


const Contacts: FC = () => {
  const [contacts, setContacts] = useState<Contact[]>([])
  const [name, setName] = useState<string>('') // State for the name input
  const [phone, setPhone] = useState<string>('') // State for the phone input
  const apiUrl = process.env.REACT_APP_API_URL
  const [contactIdToDelete, setContactIdToDelete] = useState<number | null>(null)
  const [contactIdToUpdate, setContactIdToUpdate] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [phoneCountry, setPhoneCountry] = useState<PhoneCountry | null>(null); // Update type

  const [countries, setCountries] = useState<PhoneCountry[]>([]); // Ensure countries is an array of PhoneCountry
  // Function to fetch contacts
  const fetchContacts = async () => {
    try {
      const response = await axios.get(`${apiUrl}/contact`)
      if (response.data.success) {
        setContacts(response.data.data)
 
      }
    } catch (error) {
      console.error('Error fetching contacts:', error)
    }
  }
  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        'https://develop.asasware.com/api/provider/general/countries'
      );
  
      const countryOptions: PhoneCountry[] = response.data.data.map((country: any) => ({
        value: country.iso,         // This could be the full name or ISO code
        label: country.name,        // Country name
        flag: '/' + country.flag,   // Path to the flag image
        phone_code: country.code,   // Change to fetch the correct two-letter code (e.g., "EG")
      }));
  
      setCountries(countryOptions); // Update the state with the correct type
    } catch (error) {
      let errorMessage = 'There was an error fetching the countries. Please try again.';
  
      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as { response: { data: { message: string } } };
        errorMessage = err.response.data.message || errorMessage;
      }
  
      toast.error(errorMessage);
      setLoading(false);
    }
  };
  
  
  
  
  
  // Fetch contacts on component mount
  useEffect(() => {
    fetchContacts()
    fetchCountries()
  }, [apiUrl])
  const CustomSingleValue = ({data}: any) => (
    <div className='label'>
      {data.flag && <img src={data.flag} alt='flag' className='w-30px h-30px me-2' />}
    </div>
  )

  const CustomOption = (props: any) => {
    const {data, innerRef, innerProps} = props
    return (
      <div ref={innerRef} {...innerProps} className='react-select-option'>
        {data.flag && <img src={data.flag} alt='flag' className='w-30px h-30px me-2' />}
        <span>{data.label}</span>
      </div>
    )
  }
  // Function to handle the creation of a new contact
  const handleCreateContact = async () => {
    if (!name || !phone || !phoneCountry) {
      toast.error('Please provide both name and phone number and select a phone country.');
      return;
    }
  
    // Log the values to check
    console.log('Name:', name);
    console.log('Phone:', phone);
    console.log('Phone Country:', phoneCountry); // Check this object
  
    try {
      const response = await axios.post(`${apiUrl}/contact`, {
        name,                     // User's input for name
        phone,                    // User's input for phone
        phone_code: phoneCountry.value // Ensure this is sending the correct code
      });
  
      console.log('Response from backend:', response.data); // Check response from backend
  
      if (response.data.success) {
        // Close the modal and reset fields as before...
      }
    } catch (error: unknown) {
      // Handle error as before...
    }
  };
  
  
  
  

  // Function to handle delete logic
  const handleDelete = async () => {
    if (contactIdToDelete !== null) {
      try {
        await axios.delete(`${apiUrl}/contact/${contactIdToDelete}`)
        await fetchContacts() // Fetch updated contacts after deletion
        toast.success('Contact deleted successfully.')
      } catch (error: unknown) {
        let errorMessage = 'There was an error deleting the contact. Please try again.'

        if (error instanceof Error) {
          console.error('Error deleting contact:', error.message)
        }

        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as { response: { data: { message: string } } }
          errorMessage = err.response.data.message || errorMessage
        }

        console.error('Error deleting contact:', errorMessage)
        toast.error(errorMessage)
      }
    }
  }
   // Function to handle the update of a contact
   const handleUpdateContact = async () => {
    if (contactIdToUpdate === null || !name || !phone || !phoneCountry) {
      toast.error('Please provide both name and phone number.')
      return
    }

    try {
      const response = await axios.put(`${apiUrl}/contact/${contactIdToUpdate}`, {
        name,
        phone,
        phone_code: phoneCountry.value // Ensure this is sending the correct code
        // _method: 'put', // Indicate the HTTP method
      })

      if (response.data.success) {
        const modal = document.getElementById('kt_modal_4')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          document.body.classList.remove('modal-open')
          document.body.style.removeProperty('padding-right')
          const backdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (backdrop) backdrop.remove()
        }

        await fetchContacts()
        setName('')
        setPhone('')
        setContactIdToUpdate(null)
        toast.success('Contact updated successfully!')
      }
    } catch (error: unknown) {
      let errorMessage = 'There was an error updating the contact. Please try again.'

      if (error instanceof Error) {
        console.error('Error updating contact:', error.message)
      }

      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as { response: { data: { message: string } } }
        errorMessage = err.response.data.message || errorMessage
      }

      console.error('Error updating contact:', errorMessage)
      toast.error(errorMessage)
    }
  }
  const openUpdateModal = (contact: Contact) => {
    setContactIdToUpdate(contact.id)  // Set the contact id for updating
    setName(contact.name)  // Set the current contact's name
    setPhone(contact.phone)  // Set the current contact's phone
  
    // const modal = new bootstrap.Modal(document.getElementById('kt_modal_4')!)
    // modal.show()  // Show the modal programmatically
  }
  return (
    <>
      <div className='pt-5'>
        <Toaster />
        <div className='bredcrumb_card mb-10'>
          <h1>Contacts</h1>
        </div>
        <div className='card'>
          <div className='card-header border-0 pt-5 justify-content-end gap-5'>
            <button
              className='btn btn-sm btn-dark h-40px'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
            >
              What is contact
            </button>
   
            <button
              className='btn btn-sm btn-dark h-40px'
               data-bs-toggle='modal'
              data-bs-target='#kt_modal_2'
              onClick={() => {
                setName('') // Clear the name input
                setPhone('') // Clear the phone input
                setPhoneCountry(null) // Clear phone country selection
              }}
            >
              <KTIcon iconName='plus' className='fs-2' />
              New
            </button>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted bg-dark'>
                    <th className='rounded-start'>ID</th>
                    <th className=''>Name</th>
                    <th className=''>Phone </th>
                    <th className='rounded-end'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.length > 0 ? (
                    contacts.map(
                      (contact) =>
                        contact && contact.id ? ( // Safety check for undefined/null contacts
                          <tr key={contact.id}>
                            <td>
                              <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                {contact.id}
                              </span>
                            </td>
                            <td>
                              <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                {contact.name}
                              </span>
                            </td>
                            <td>
                              <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                {contact.phone}
                              </span>
                            </td>

                            <td className='d-flex gap-4 justify-content-center'>
                              <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                 data-bs-toggle='modal'
                                data-bs-target='#kt_modal_4'
                                onClick={() => openUpdateModal(contact)} // Pass the contact to openUpdateModal


                              >
                                <KTIcon iconName='pencil' className='fs-3' />
                              </button>
                              <button
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_3'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                // Set the contactIdToDelete when clicking the delete button
                                onClick={() => setContactIdToDelete(contact.id)}
                              >
                                <KTIcon iconName='trash' className='fs-3' />
                              </button>
                            </td>
                          </tr>
                        ) : null // Skip rendering if the contact object is not valid
                    )
                  ) : (
                    <tr>
                      <td colSpan={4} className='text-center'>
                        No contacts found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Modal: Add new contact */}
          <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Add new contact</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    x
                  </div>
                </div>
                <div className='modal-body'>
                  <div className='mb-10'>
                    <label className='form-label'>Name:</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter Name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  {/* <div className='mb-10'>
                    <label className='form-label'>Phone:</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter Phone'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div> */}
                  <div className='mb-4'>
                    <label className='form-label'>Phone</label>
                    <div className=''>
                      <div className='d-flex w-100 input-group border rounded mb-4'>
<Select
  options={countries}
  value={phoneCountry} // Set the selected value here
  onChange={(selected) => setPhoneCountry(selected)} // Update phone country on selection
  components={{ SingleValue: CustomSingleValue, Option: CustomOption }} // Custom components for rendering
  isSearchable={false} // Disable the search input

/>
<input
                      type='text'
                      className='form-control'
                      placeholder='Enter Phone'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer justify-content-between'>
                  <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                    Close
                  </button>
                  <button type='button' className='btn btn-dark' onClick={handleCreateContact}>
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
     {/* Modal: Update new contact */}
     <div className='modal fade' tabIndex={-1} id='kt_modal_4'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Update new contact</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    x
                  </div>
                </div>
                <div className='modal-body'>
                  <div className='mb-10'>
                    <label className='form-label'>Name:</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter Name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
             
                  <div className='mb-4'>
                    <label className='form-label'>Phone</label>
                    <div className=''>
                      <div className='d-flex w-100 input-group border rounded mb-4'>
<Select
  options={countries}
  value={phoneCountry} // Set the selected value here
  onChange={(selected) => setPhoneCountry(selected)} // Update phone country on selection
  components={{ SingleValue: CustomSingleValue, Option: CustomOption }} // Custom components for rendering
  isSearchable={false} // Disable the search input

/>
<input
                      type='text'
                      className='form-control'
                      placeholder='Enter Phone'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer justify-content-between'>
                  <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                    Close
                  </button>
                  <button type='button' className='btn btn-dark' onClick={handleUpdateContact}>
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Modal: Delete Confirmation */}
          <div className='modal fade' tabIndex={-1} id='kt_modal_3'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Message</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    x
                  </div>
                </div>
                <div className='modal-body'>Are you sure that you want to delete this contact?</div>
                <div className='modal-footer justify-content-between'>
                  <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                    Close
                  </button>
                  <button
                    type='button'
                    className='btn btn-dark'
                    onClick={handleDelete}
                    data-bs-dismiss='modal'
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Contacts}
