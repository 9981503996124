import React, { FC, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const PoiciesDetailsEdit: FC = () => {
  const [bookingType, setBookingType] = useState<string>('open');
  const [whoCanBook, setWhoCanBook] = useState<string>('public');
  const [name, setName] = useState<string>('');
  const [bookingFrequencyTime, setBookingFrequencyTime] = useState<number | undefined>();
  const [freeCancellationTime, setFreeCancellationTime] = useState<number>();
  const [feesForCancellation, setFeesForCancellation] = useState<number>();
  const [lockerTimeFrame, setLockerTimeFrame] = useState<number>();
  const [receivingBookingTimeFrame, setReceivingBookingTimeFrame] = useState<number>();
  const [bookingFrequencyPer, setBookingFrequencyPer] = useState<string>('');
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const policyQueryParam = queryParams.get('id');

  useEffect(() => {
    const fetchPolicyDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/policy/${policyQueryParam}`);
        const policyData = response.data.data;

        setName(policyData.name || '');
        setWhoCanBook(policyData.who_can_book || 'public');
        setBookingType(policyData.booking_frequency || 'open');
        setBookingFrequencyTime(policyData.booking_frequency_time || undefined);
        setBookingFrequencyPer(policyData.booking_frequency_per || '');
        setFreeCancellationTime(policyData.free_cancellation_time_frame || undefined);
        setFeesForCancellation(policyData.fees_for_cancellation || undefined);
        setLockerTimeFrame(policyData.locker_time_frame || undefined);
        setReceivingBookingTimeFrame(policyData.receiving_booking_time_frame || undefined);
      }catch (error: unknown) {
        let errorMessage = 'There was an error deleting the event. Please try again.'

        // Check if the error is of the type we expect
        if (error instanceof Error) {
          // If the error is an instance of the generic Error class, we can log its message
          console.error('Error deleting event:', error.message)
        }

        // Use type assertion to narrow down the type of error
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        // Log the error and show an error toast notification
        console.error('Error deleting event:', errorMessage)
        toast.error(errorMessage)
      }
    };
    fetchPolicyDetails();
  }, [policyQueryParam]);

  const handleUpdatePolicy = async () => {
    try {
      await axios.post(`${apiUrl}/policy/${policyQueryParam}`, {
        name: name,
        who_can_book: whoCanBook,
        booking_frequency: bookingType,
        booking_frequency_time: bookingFrequencyTime,
        booking_frequency_per: bookingFrequencyPer,
        free_cancellation_time_frame: freeCancellationTime,
        fees_for_cancellation: feesForCancellation,
        locker_time_frame: lockerTimeFrame,
        receiving_booking_time_frame: receivingBookingTimeFrame,
        _method: 'put' // Method override for PUT request
      });
      toast.success('Policy updated successfully');
      navigate(-1); // Navigate to the policy list page or wherever needed
    } catch (error: unknown) {
      let errorMessage = 'There was an error deleting the event. Please try again.'

      // Check if the error is of the type we expect
      if (error instanceof Error) {
        // If the error is an instance of the generic Error class, we can log its message
        console.error('Error deleting event:', error.message)
      }

      // Use type assertion to narrow down the type of error
      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as {response: {data: {message: string}}}
        errorMessage = err.response.data.message || errorMessage
      }

      // Log the error and show an error toast notification
      console.error('Error deleting event:', errorMessage)
      toast.error(errorMessage)
    }
  };

  return (
    <div className='pt-5'>
    <div className='mt-5 shadow-sm rounded p-5'>
      <h2 className='mb-0'>General Information</h2>
      <div className='mt-5 pt-5 border-top'>
        <label className='form-label'>Name</label>
        <input
          type='text'
          className='form-control'
          placeholder='name@example.com'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className='my-5'>
        <div className='mb-3'>
          Who can book? <span className='text-danger'> * </span>
        </div>
        <div className=''>
          {/* Who can book options */}
          <div className='form-check mb-5'>
            <input
              className='form-check-input'
              type='radio'
              name='whoCanBook'
              value='public'
              checked={whoCanBook === 'public'}
              onChange={() => setWhoCanBook('public')}
            />
            <label>Public</label>
          </div>
          <small className='text-muted my-5 d-block'>
            Members & Public can view and book sessions in your field <br />
            Public will not be able to send membership requests
          </small>
          <div className='form-check mb-5'>
            <input
              className='form-check-input'
              type='radio'
              name='whoCanBook'
              value='send-request'
              checked={whoCanBook === 'send-request'}
              onChange={() => setWhoCanBook('send-request')}
            />
            <label>Member only - Public View</label>
          </div>
          <small className='text-muted my-5 d-block'>
            Members will be able to view your fields and book sessions in your fields <br />
            Public will be able to view your field and send membership requests
          </small>
          <div className='form-check mb-5'>
            <input
              className='form-check-input'
              type='radio'
              name='whoCanBook'
              value='members-only'
              checked={whoCanBook === 'members-only'}
              onChange={() => setWhoCanBook('members-only')}
            />
            <label>Member only - Private View</label>
          </div>
          <small className='text-muted my-5 d-block'>
            Members will be able to view your fields and book sessions in your fields <br />
            Public will not be able to view your field or send membership requests
          </small>
        </div>
      </div>

      {/* Booking Frequency */}
      <div className='mt-5 pt-5 border-top'>
        <h2 className='mb-0'>Booking Frequency</h2>
      </div>
      <div className='my-3'>How many sessions can be booked by high five user? :</div>
      <div className='mt-5 mb-5'>
        <div className='form-check mb-3'>
          <input
            className='form-check-input'
            type='radio'
            name='bookingType'
            value='open'
            checked={bookingType === 'open'}
            onChange={() => setBookingType('open')}
          />
          <label>Open</label>
        </div>
        <div className='form-check'>
          <input
            className='form-check-input'
            type='radio'
            name='bookingType'
            value='limited'
            checked={bookingType === 'limited'}
            onChange={() => setBookingType('limited')}
          />
          <label>Customized</label>
        </div>
        {bookingType === 'limited' && (
          <div className='mt-5'>
            <label>Number of sessions</label>
            <input
              type='number'
              className='form-control mb-3'
              value={bookingFrequencyTime}
              onChange={(e) => setBookingFrequencyTime(Number(e.target.value))}
            />
            <span>Per:</span>
            <div className='form-check  mb-3'>
              <input
                className='form-check-input'
                type='radio'
                name='bookingFrequencyPer'
                value='day'
                checked={bookingFrequencyPer === 'day'}
                onChange={() => setBookingFrequencyPer('day')}
              />
              <label className='form-check-label'>Days</label>
            </div>
            <div className='form-check  mb-3'>
              <input
                className='form-check-input'
                type='radio'
                name='bookingFrequencyPer'
                value='week'
                checked={bookingFrequencyPer === 'week'}
                onChange={() => setBookingFrequencyPer('week')}
              />
              <label className='form-check-label'>Weeks</label>
            </div>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                name='bookingFrequencyPer'
                value='month'
                checked={bookingFrequencyPer === 'month'}
                onChange={() => setBookingFrequencyPer('month')}
              />
              <label className='form-check-label'>Months</label>
            </div>
          </div>
        )}
      </div>

      {/* Other policy fields */}
      <div className='my-4 py-4 border-top border-bottom'>
        <h2 className='mb-0'>Cancellation policy</h2>
      </div>
      <h4 className='mb-3'>Online payment</h4>

      <div className='mb-5'>
        <label className='text-primary'>Free cancellation</label>
        <div className='d-flex gap-5 align-items-center mb-4'>
          <p className='text-muted mb-0'>
            High Five user will get full refund for cancellation if cancelled within
          </p>
          <input
            type='number'
            className='form-control w-70px'
            value={freeCancellationTime}
            onChange={(e) => setFreeCancellationTime(Number(e.target.value))}
          />
          <p className='text-muted  mb-0'>hours before session start time</p>
        </div>

        <label className='text-primary'>Fees for cancellation</label>
        <div className='d-flex gap-5 align-items-center mb-4'>
          <p className='text-muted mb-0'>Charge High Five user</p>
          <input
            type='number'
            className='form-control w-70px'
            value={feesForCancellation}
            onChange={(e) => setFeesForCancellation(Number(e.target.value))}
          />
          <p className='text-muted  mb-0'>
            % of paid amount if cancelled after free cancellation period.
          </p>
        </div>
      </div>
      <div className='my-4 py-4 border-top border-bottom'>
        <h2 className='mb-3'>Cash payment</h2>
        <p>
        {' '} <i className="fa-solid fa-circle-info text-primary mx-2"></i> 

          Cancelation charge is subject to agreement with high five user and will not be
          considered financially in your accounting page.
        </p>
      </div>
      {/* Locker */}
      <div className=''>
        <h2 className='mb-3'>Locker</h2>
      </div>
      <div className='mb-5'>
        <label>Locker time frame</label>

        <div className='d-flex gap-5 align-items-center mb-4'>
          <p className='text-muted mb-0'>All unlocked bookings will be auto locked in</p>
          <input
            type='number'
            className='form-control w-70px'
            value={lockerTimeFrame}
            onChange={(e) => setLockerTimeFrame(Number(e.target.value))}
          />
          <p className='text-muted  mb-0'>Hours before start time. 3 hours minimum.</p>
        </div>
      </div>

      {/* Receiving booking time control */}
      <div className='mt-5 pt-5 border-top'>
        <h2 className='mb-0'>Receive bookings time control</h2>
      </div>
      <div className='mb-5'>
        <label>Receiving booking time frame</label>
        <div className='d-flex gap-5 align-items-center mb-4'>
          <p className='text-muted mb-0'>Do not show any available session to High Five users within</p>
          <input
          type='number'
          className='form-control w-70px'
          value={receivingBookingTimeFrame}
          onChange={(e) => setReceivingBookingTimeFrame(Number(e.target.value))}
        />
          <p className='text-muted  mb-0'>Hours before start time.</p>
        </div>
        <p><i className="fa-solid fa-circle-info text-primary"></i> (zero) is the best option if you are in field during operations hour</p>
        <p><i className="fa-solid fa-circle-info text-primary"></i> Note: You have full control to link or create manual booking in any available session.</p>
 
      </div>

      <div className='d-flex justify-content-between'>
        <button className='btn btn-light-dark'>Cancel</button>
        <button className='btn btn-dark' onClick={handleUpdatePolicy}>
          update
        </button>
      </div>
    </div>
  </div>
  );
};

export { PoiciesDetailsEdit };
