import { useIntl } from 'react-intl';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import { SidebarMenuItem } from './SidebarMenuItem';
import React, { FC, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import toast, {Toaster} from 'react-hot-toast'
import { useNavigate } from 'react-router-dom';
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain: FC = () => {
  const intl = useIntl();
  const [branches, setBranches] = useState<any[]>([]);
  const [units, setUnits] = useState<{ [key: string]: { id: string, name: string }[] }>({});
  const [isLoading, setIsLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { branch_id } = useParams<{ branch_id: string }>();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const branchId = queryParams.get('branch_id');
  const { unit_id } = useParams<{ unit_id: string }>();
  const unitQueryParam = queryParams.get('unit_id');
  const { status } = useParams<{ status: string }>();
  const statusQueryParam = queryParams.get('status');
  const  navigate = useNavigate();
  const {logout} = useAuth()


  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axios.get(`${apiUrl}/general/branches`);
        const fetchedBranches = response.data.data;
        setBranches(fetchedBranches);
  
        const unitsByBranch: { [key: string]: { id: string, name: string }[] } = {};
  
        // Fetch units for each branch using Promise.all
        const unitPromises = fetchedBranches.map(async (branch) => {
          try {
            const unitResponse = await axios.get(`${apiUrl}/general/units?branch_id=${branch.id}`);
            unitsByBranch[branch.id] = unitResponse.data.data.map((unit: any) => ({
              id: unit.id,
              name: unit.name,
            }));
          } catch (error) {
            console.error(`Error fetching units for branch ${branch.id}:`, error);
            unitsByBranch[branch.id] = []; // If error, set empty units
          }
        });
  
        // Wait for all unit fetches to complete
        await Promise.all(unitPromises);
  
        setUnits(unitsByBranch);
      } catch (error: unknown) {
        logout();
        navigate('auth/login');

        let errorMessage = 'There was an error deleting the event. Please try again.';
    
        // Check if the error is of the type we expect
        if (error instanceof Error) {
            // If the error is an instance of the generic Error class, we can log its message
            console.error('Error deleting event:', error.message);
        }
    
        // Use type assertion to narrow down the type of error
        if (typeof error === 'object' && error !== null && 'response' in error) {
            const err = error as { response: { data: { message: string } } };
            errorMessage = err.response.data.message || errorMessage;
        }
    
        // Log the error and show an error toast notification
        console.error('Error deleting event:', errorMessage);
        
        toast.error(errorMessage);
    }  finally {
        setIsLoading(false);
      }
    };
  
    fetchBranches();
  }, [branchId, apiUrl]);
  
  return (
    <>
    <Toaster/>
      {/* <SidebarMenuItem
        to='/dashboard'
        icon='abstract-26
'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      /> */}
            <SidebarMenuItemWithSub
        to='/'
        title='Dated Schedule'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        {branches.map((branch, branchIndex) => (
          <SidebarMenuItemWithSub
            key={branchIndex}
            to='/'
            title={branch.name}
            fontIcon='bi-archive'
            icon='element-plus'
          >
            {(units[branch.id] || []).map((unit, unitIndex) => (
              <SidebarMenuItem
                key={unitIndex}
                // to={`/dashboard/schedule/Schedule?unit_id=${unit.id}&status=${statusQueryParam}`}
                to={`/dashboard/schedule/Schedule?unit_id=${unit.id}`}

                title={unit.name}
                hasBullet={true}
              />
            ))}
          </SidebarMenuItemWithSub>
        ))}
 
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/dashboard/reservation/Reservation'
        icon='element-11'
        title='Bookings'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItemWithSub
        to='/dashboard/customers/Customers'
        title='Customers'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem
          to='/dashboard/customers/CustomersWalk'
          title='walk-in customers'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/dashboard/customers/Customers'
          title='High Five Customers'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/dashboard/members/Members'
        title='Memberships'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/dashboard/members/Members' title='Members' hasBullet={true} />
        <SidebarMenuItem
          to='/dashboard/members/MembersRequest'
          title='Memberships Requests '
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/dashboard/members/MembersExpired'
          title='Expired Memberships'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/dashboard/account/Account'
        icon='switch'
        title='Financial'
        fontIcon='bi-layers'
      />

      <SidebarMenuItemWithSub
        to='/dashboard/management/policies/Policies'
        title='Management'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/dashboard/fields/Branches' title='Branches' hasBullet={true} />
        <SidebarMenuItem
          to='/dashboard/members/MembersCategory'
          title='Category'
          hasBullet={true}
        />

        <SidebarMenuItem
          to='/dashboard/management/policies/Policies'
          title='Policies'
          hasBullet={true}
        />

        <SidebarMenuItem
          to='/dashboard/management/contacts/Contacts'
          title='Contacts'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/dashboard/management/employees/Employees'
          title='Employees'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/dashboard/management/payment/PaymentSettings'
          title='Payment Settings'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/dashboard/management/tax/TaxSettings'
          title='Tax Settings'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
