import {Route, Routes} from 'react-router-dom'
import Registration from './components/Registration'
import {RegistrationForm} from './components/RegistrationForm'

import {ForgotPassword} from './components/ForgotPassword'
import {ForgotPasswordMail} from './components/ForgotPasswordMail'
import {ForgotPasswordOtp} from './components/ForgotPasswordOtp'
import {ForgotPasswordPhone} from './components/ForgotPasswordPhone'
import {ResetPassword} from './components/ResetPassword'

import {RegOtbForm} from './components/RegOtbForm'


// import {ComInfo} from './components/ComInfo'
// import SendMsg from './components/SendMsg'
// import {TermsLogin} from './components/TermsLogin'


import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      {/* <Route path='registration-form' element={<RegistrationForm />} /> */}

      
      <Route path='forgot-password' element={<ForgotPassword />} />

      <Route path='forgot-password-mail' element={<ForgotPasswordMail />} />
      <Route path='forgot-password-otp' element={<ForgotPasswordOtp />} />
      <Route path='forgot-password-phone' element={<ForgotPasswordPhone />} />
      <Route path='forgot-password-reset' element={<ResetPassword />} />
      {/* <Route path='registration-otp' element={<RegOtbForm />} /> */}
      {/* <Route path='company-Info' element={<ComInfo />} /> */}
      {/* <Route path='send-msg' element={<SendMsg />} /> */}
      {/* <Route path='terms-not-login' element={<TermsLogin />} /> */}



      
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
