import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../../_high-five/helpers'
import axios from 'axios'
import toast, {Toaster} from 'react-hot-toast'
import LoadingSpinner from '../../../../app/shared/LoadingSpinner'

interface Member {
  id: number
  player: {
    id: number
    name: string
    vie_id: string
    image: string
  }
  status: string
  category_id: number
  category_name: string
  length: string
  expired_at: string | null
  reference_number: string
  created_at: string
}

const Members: FC = () => {
  const intl = useIntl()
  const apiUrl = process.env.REACT_APP_API_URL
  const [memberships, setMemberships] = useState<Member[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [membershipToEnd, setMembershipToEnd] = useState<number | null>(null) // State to hold the membership ID for ending

  useEffect(() => {
    // Fetch memberships data
    const fetchMemberships = async () => {
      try {
        const response = await axios.get(`${apiUrl}/memberships/members`)
        if (response.data.success) {
          setMemberships(response.data.data.memberships)
          toast.success('Data loaded successfully')
        } else {
          toast.error('Failed to load data')
        }
      } catch (error) {
        toast.error('Error fetching data')
      } finally {
        setLoading(false)
      }
    }

    fetchMemberships()
  }, [apiUrl])

  // Function to end a membership
  const endMembership = async (membershipId: number) => {
    try {
      const response = await axios.post(`${apiUrl}/memberships/end/${membershipId}`)
      if (response.data.success) {
        setMemberships(memberships.filter(membership => membership.id !== membershipId)) // Remove the ended membership from the state
        toast.success('Membership ended successfully')
      } else {
        toast.error('Failed to end membership')
      }
    } catch (error) {
      toast.error('Error ending membership')
    }
  }

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className='pt-5'>
          <h1>MEMBERSHIPS</h1>
          <Breadcrumb secondItem=' Page' />
          <div className=''>
            <div className='card'>
              <div className='card-header border-0 justify-content-end gap-5 pt-5'>
                <Link className='btn btn-dark h-45px' to='/dashboard/members/NewMember'>
                  <KTIcon iconName='plus' className='fs-2' />
                  New
                </Link>
              </div>
              <div className='text-center'></div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bold text-muted bg-dark'>
                        <th className='rounded-start'>#</th>
                        <th className='text-start'>User</th>
                        <th className=''>Reference Number </th>
                        <th className=''>Category</th>
                        <th className=''>Created</th>
                        <th className=''>Ending</th>
                        <th className='rounded-end'>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {memberships.map((membership, index) => (
                        <tr key={membership.id}>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {index + 1}
                            </span>
                          </td>
                          <td className='text-start'>
                            <div className='d-flex gap-3 align-items-center'>
                              <img
                                alt={membership.player.name}
                                src={
                                  membership.player.image || toAbsoluteUrl('/media/logos/logo.png')
                                }
                                className='h-50px w-50px rounded-circle border'
                              />
                              <div>
                                <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                  {membership.player.name}
                                </span>
                                <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                                  @{membership.player.vie_id}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {membership.reference_number}
                            </span>
                          </td>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {membership.category_name}
                            </span>
                          </td>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {membership.created_at}
                            </span>
                          </td>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {membership.length === 'open' ? 'Open' : membership.expired_at}
                            </span>
                          </td>
                          <td className='d-flex gap-4 justify-content-center'>
                            <Link
                              to={'/dashboard/members/EditMembers?memberId=' + membership.id}
                              className='d-flex align-items-center btn-dark btn'
                            >
                              Edit
                            </Link>
                            <button
                              onClick={() => setMembershipToEnd(membership.id)} // Store membership ID
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_2'
                              className='btn btn-bg-light me-1'
                            >
                              End
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* modal */}
              <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title'>Message</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        x
                      </div>
                    </div>
                    <div className='modal-body'>
                      <p>Are you sure that you want to end this membership?</p>
                      <p>Player will not be able to book from your private fields.</p>
                    </div>
                    <div className='modal-footer justify-content-between'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        Close
                      </button>
                      <button
                        type='button'
                        className='btn btn-dark'
                        onClick={() => {
                          if (membershipToEnd) {
                            endMembership(membershipToEnd) // Call endMembership function
                            setMembershipToEnd(null) // Clear the membership ID
                          }
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Toaster />
    </>
  )
}

export {Members}