import {FC, useState, useEffect} from 'react'
import React from 'react'
import {useIntl} from 'react-intl'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import {toAbsoluteUrl} from '../../../../_high-five/helpers'
import axios from 'axios'
import {useParams, useLocation, useNavigate} from 'react-router-dom'
import Select from 'react-select'
import toast, {Toaster} from 'react-hot-toast'

const apiUrl = process.env.REACT_APP_API_URL

const PublicEvent: FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const publicEventIdQueryParam = queryParams.get('publicEventId')
  const unitQueryParam = queryParams.get('unitId')
  const navigate = useNavigate()

  const [publicEventDetails, setPublicEventDetails] = useState<any>(null)
  const [playerNumbers, setPlayerNumbers] = useState<number>(0)
  const [ageGroup, setAgeGroup] = useState<any>(null)
  const [paymentMethod, setPaymentMethod] = useState<string>('') // Default to cash
  const [paymentMethodPublicEvent, setPaymentMethodPublicEvent ] = useState<string>('') // Default to cash

  const [amountPerPlayer, setAmountPerPlayer] = useState<number>(0)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [guaranteedRefundTime, setGuaranteedRefundTime] = useState<{value?: string} | null>(null)
  const [guaranteedRefundPercentage, setGuaranteedRefundPercentage] = useState<{
    value?: string
  } | null>(null)

  const [ageOptions, setAgeOptions] = useState<any>([])
  const [errors, setErrors] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [booking_methodology, setBookingMethodology] = useState<string>('') // If it's a state variable

  const guaranteedOptions = [
    {value: '0', label: '0 Hours'},
    {value: '1', label: '1 Hour'},
    {value: '3', label: '3 Hours'},
    {value: '6', label: '6 Hours'},
    {value: '12', label: '12 Hours'},
    {value: '24', label: '24 Hours'},
  ]

  const percentageOptions = [
    {value: '0', label: '0%'},
    {value: '25', label: '25%'},
    {value: '50', label: '50%'},
    {value: '75', label: '75%'},
    {value: '100', label: '100%'},
  ]
  const [minValue, setMinValue] = useState<number | null>(null)
  const [maxValue, setMaxValue] = useState<number | null>(null)

  useEffect(() => {
    const fetchPublicEventDetails = async () => {
      try {
        const {data} = await axios.get(
          `${apiUrl}/units/${unitQueryParam}/date-schedule/session_schedule/${publicEventIdQueryParam}`
        )
        const ageOptionsResponse = await axios.get(`${apiUrl}/general/age-groups/${unitQueryParam}`)
        setPaymentMethodPublicEvent(data.data.public_event_payment_method)
        setPublicEventDetails(data.data)
        setAgeOptions(
          ageOptionsResponse.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        )
        console.log(ageOptions, 'ageOptions')
        // Set minValue and maxValue based on number_users_range_for_public_event
        const userRange = data.data.number_users_range_for_public_event
        if (userRange && userRange.length === 2) {
          setMinValue(userRange[0])
          setMaxValue(userRange[1])
        }
      } catch (error) {
        console.error('Error fetching session data:', error)
      }
    }

    fetchPublicEventDetails()
  }, [publicEventIdQueryParam, unitQueryParam])

  const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(event.target.value)
  }

  // const calculateTotalPrice = (players: number, price: number) => {
  //   return players * price
  // }
  const calculateTotalPrice = (
    players: number,
    price: number,
    taxPercent: number,
    taxStatus: string
  ) => {
    let total = players * price
    if (taxStatus === 'excluded') {
      total += (total * taxPercent) / 100
    }
    return total
  }
  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10)
    setPlayerNumbers(value)
    setTotalAmount(
      calculateTotalPrice(
        value,
        amountPerPlayer,
        publicEventDetails?.price.tax_percent,
        publicEventDetails?.tax_status
      )
    )
  }

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const price = parseInt(event.target.value, 10)
    setAmountPerPlayer(price)
    setTotalAmount(
      calculateTotalPrice(
        playerNumbers,
        price,
        publicEventDetails?.price.tax_percent,
        publicEventDetails?.tax_status
      )
    )
  }

  // const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = parseInt(event.target.value, 10)
  //   setPlayerNumbers(value)
  //   setTotalAmount(calculateTotalPrice(value, amountPerPlayer))
  // }

  // const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const price = parseInt(event.target.value, 10)
  //   setAmountPerPlayer(price)
  //   setTotalAmount(calculateTotalPrice(playerNumbers, price))
  // }

  const handleSubmit = async () => {
    let guaranteedRefundTimeValue = parseInt(guaranteedRefundTime?.value || '0', 10)
    let guaranteedRefundPercentageValue = parseInt(guaranteedRefundPercentage?.value || '0', 10)

    const requestData: any = {
      player_numbers: playerNumbers,
      age_group: ageGroup?.value,
      payment: paymentMethod,
      amount_per_player: amountPerPlayer,
    }

    if (paymentMethod === 'online') {
      requestData.guaranteed_refund_time = guaranteedRefundTimeValue
      requestData.guaranteed_refund_percentage = guaranteedRefundPercentageValue
    }

    try {
      await axios.post(
        `${apiUrl}/units/${unitQueryParam}/date-schedule/sessions/${publicEventIdQueryParam}/public-event`,
        requestData
      )
      navigate(-1) // Redirect after successful submission
    } catch (error: unknown) {
      let errorMessage = 'There was an error deleting the event. Please try again.'

      // Check if the error is of the type we expect
      if (error instanceof Error) {
        // If the error is an instance of the generic Error class, we can log its message
        console.error('Error deleting event:', error.message)
      }

      // Use type assertion to narrow down the type of error
      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as {response: {data: {message: string}}}
        errorMessage = err.response.data.message || errorMessage
      }

      // Log the error and show an error toast notification
      console.error('Error deleting event:', errorMessage)
      toast.error(errorMessage)
    } finally {
      setLoading(false) // Stop loading
    }
  }

  return (
    <>
      <div className='pt-5'>
        <Toaster />
        <h1>Create Public Event</h1>
        <Breadcrumb secondItem='Page' />
        <div className='row'>
          <div className='col-lg-6'>
            <div className='card p-4 min-h-card'>
              <h4 className='border-bottom pb-4 pt-2 mb-3 text-lime'>
                Session Information (# {publicEventDetails?.id}){' '}
              </h4>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Type :</span> <span>{publicEventDetails?.type}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Date :</span> <span> {publicEventDetails?.date}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Start :</span> <span>{publicEventDetails?.start_time}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>End :</span> <span>{publicEventDetails?.end_time}</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Gender :</span> <span>{publicEventDetails?.gender}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Price :</span>{' '}
                <span>{publicEventDetails?.price.total_price} </span>{' '}
                <span>
                  <button className='btn p-0' data-bs-toggle='modal' data-bs-target='#kt_modal_1'>
                    <i className='fa-solid fa-circle-info'></i>
                  </button>
                </span>
              </p>

              {/* modal */}
              <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header py-2'>
                      <h5 className='modal-title'>Break Down</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        x
                      </div>
                    </div>
                    <div className='modal-body mb_no'>
                      <div className=' border-bottom pb-3 mb-3'>
                        <div className='mb-3'>
                          <p className='d-flex gap-3 align-items-center'>
                            <span className='min_w'>Base Price :</span>{' '}
                            <span>{publicEventDetails?.price.base_price}</span>
                          </p>
                        </div>
                        <div className='mb-3'>
                          <p className='d-flex gap-3 align-items-center'>
                            <span className='min_w'>
                              Discount ({publicEventDetails?.price.discount_percent}%) :
                            </span>{' '}
                            <span>{publicEventDetails?.price.discount}</span>
                          </p>
                        </div>
                        <div className='mb-'>
                          <p className='d-flex gap-3 align-items-center'>
                            <span className='min_w'>Total Session Price :</span>{' '}
                            <span>{publicEventDetails?.price.price_after_discount}</span>
                          </p>
                        </div>
                      </div>
                      <div className=' border-bottom pb-3 mb-3'>
                        <p className='d-flex gap-3 align-items-center'>
                          <span className='min_w'>
                            Tax ({publicEventDetails?.price.tax_percent}%) :
                          </span>{' '}
                          <span>{publicEventDetails?.price.tax}</span>
                        </p>
                      </div>
                      <div className=' '>
                        <p className='d-flex gap-3 align-items-center'>
                          <span className='min_w'>Total :</span>{' '}
                          <span>{publicEventDetails?.price.total_price}</span>
                        </p>
                      </div>
                    </div>
                    <div className='modal-footer py-2'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='card p-4 min-h-card'>
              <h4 className='border-bottom pb-4 pt-2 mb-3 text-lime'>
                Session Information (# {publicEventDetails?.id}){' '}
              </h4>
              {/* Range input for player numbers */}
              <p className='d-flex gap-3 align-items-center'>
                <label className='min_w'>Number of reservations :</label>
                <span>
                  <input
                    type='number'
                    className={`form-control ${errors.player_numbers ? 'is-invalid' : ''}`}
                    value={playerNumbers}
                    readOnly
                  />
                  {errors.player_numbers && (
                    <div className='invalid-feedback d-block'>{errors.player_numbers[0]}</div>
                  )}
                </span>
                {minValue !== null && maxValue !== null ? (
                  <input
                    type='range'
                    min={minValue}
                    max={maxValue}
                    value={playerNumbers}
                    onChange={handleRangeChange}
                    className='form-range'
                  />
                ) : (
                  <p>Loading range values...</p> // Provide feedback or a placeholder when values are not available
                )}
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Age group :</span>{' '}
                <span className='w-100'>
                  <Select
                    options={ageOptions}
                    placeholder='Select an option'
                    onChange={setAgeGroup}
                    className='react-select-styled border w-100'
                    classNamePrefix='react-select'
                  />
                  {errors.age_group && (
                    <div className='invalid-feedback d-block'>{errors.age_group[0]}</div>
                  )}
                </span>
              </p>
              <div className=''>
                <p> Payment:</p>

                <div className='mt-3'>
  {(paymentMethodPublicEvent === 'cash' || paymentMethodPublicEvent === 'both') && (
    <div className='form-check form-check-custom form-check-solid me-5 mb-3'>
      <input
        className={`form-check-input ${errors.payment ? 'is-invalid' : ''}`}
        type='radio'
        value='cash'
        checked={paymentMethod === 'cash'}
        onChange={handlePaymentChange}
      />
      <label className='form-check-label'>Cash</label>
    </div>
  )}

  {(paymentMethodPublicEvent === 'online' || paymentMethodPublicEvent === 'both') && (
    <>
      <div className='form-check form-check-custom form-check-solid'>
        <input
          className={`form-check-input ${errors.payment ? 'is-invalid' : ''}`}
          type='radio'
          value='online'
          checked={paymentMethod === 'online'}
          onChange={handlePaymentChange}
        />
        <label className='form-check-label'>Online</label>
      </div>

      {paymentMethod === 'online' && (
        <div className='mt-4'>
          <div className='mb-3 w-100'>
            <label className='form-label'>Guaranteed refund time:</label>
            <Select
              options={guaranteedOptions}
              placeholder='Select an option'
              onChange={setGuaranteedRefundTime}
              value={guaranteedRefundTime}
              className='react-select-styled border w-100'
              classNamePrefix='react-select'
            />
          </div>
          <div className='mb-3 w-100'>
            <label className='form-label'>Guaranteed refund percentage:</label>
            <Select
              options={percentageOptions}
              placeholder='Select an option'
              onChange={setGuaranteedRefundPercentage}
              value={guaranteedRefundPercentage}
              className='react-select-styled border w-100'
              classNamePrefix='react-select'
            />
          </div>
        </div>
      )}
    </>
  )}

  {errors.payment && (
    <div className='invalid-feedback d-block'>{errors.payment[0]}</div>
  )}
</div>

              </div>

              <div className=''>
                <div className=''>
                  <div className=' mb-3'>
                    <label className='mb-1'>Enter public event price :</label>
                    <input
                      type='number'
                      className={`form-control ${errors.amount_per_player ? 'is-invalid' : ''}`}
                      value={amountPerPlayer}
                      onChange={handlePriceChange}
                    />
                    {errors.amount_per_player && (
                      <div className='invalid-feedback'>{errors.amount_per_player[0]}</div>
                    )}
                  </div>

                  <div className='mb-3'>
                    <label className='mb-1'>Total public event price per player:</label>
                    <input
                      type='number'
                      className='form-control'
                      value={amountPerPlayer}
                      disabled
                    />
                  </div>
                  <div className=' mb-3'>
                    <label className='mb-1'>Total public event price:</label>
                    <input type='number' className='form-control' value={totalAmount} disabled />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className='d-flex justify-content-between mt-4'>
          <button className='btn btn-secondary bg-white text-dark'>Cancel</button>
          <button className='btn btn-secondary bg-dark text-white' onClick={handleSubmit}>
            Create
          </button>
        </div>
      </div>
    </>
  )
}

export default PublicEvent
